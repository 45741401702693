import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
//Datatable Modules
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Loader from "./Loader";

import {
  PostCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../api/ApiServices";
import { BASE_URL } from "../api/ApiConfig";
import { ToastMsg } from "./ToastMsg";
import DefaultEditor from "react-simple-wysiwyg";

const AboutUs = () => {
  const { sidebar } = useContext(AppContext);
  const [aboutData, setAboutData] = useState([]);
  const [add, setAdd] = useState(false);
  const handleAddClose = () => setAdd(false);
  const handleAddShow = () => setAdd(true);
  const [loading, setLoading] = useState(false);
  const [contentBody, setContentBody] = useState();
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    getAboutUsApi();
  }, []);

  const AddUpdateArticleApi = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      PostCallWithErrorResponse(
        BASE_URL + "/content/about",
        JSON.stringify({ body: contentBody })
      )
        .then((res) => {
          if (res.error) {
            ToastMsg("error", res.json.message);
          } else if (res.json.error == false) {
            ToastMsg("success", res.json.message);
            getAboutUsApi();
            handleAddClose();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setValidated(true);
  };

  useEffect(() => {
    getAboutUsApi();
  }, []);

  const getAboutUsApi = () => {
    setLoading(true);
    simpleGetCallWithErrorResponse(BASE_URL + "/content/about")
      .then((response) => {
        if (response.json.data) {
          setAboutData(response.json.data);
          setContentBody(response.json.data.content);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">About Us</label>
        </div>
        <div className="add-btn">
          <button
            onClick={(e) => {
              handleAddShow();
            }}
          >
            Update About Us
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path fill="white" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
            </svg>
          </button>
        </div>
      </div>

      <Modal
        show={add}
        onHide={handleAddClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update About Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={AddUpdateArticleApi}>
            <div className="row">
              <div className="col-md-12 mb-3">
                <Form.Label>About Us</Form.Label>
                {/* <Form.Control
                  as="textarea"
                  rows={10}
                  required
                  value={about.contentDesc}
                  onChange={(e) => {
                    setContentBody(e.target.value);
                    setAbout({
                      ...about,
                      contentDesc: e.target.value,
                    });
                  }}
                /> */}
                <DefaultEditor
                  value={contentBody}
                  onChange={(e) => {
                    setContentBody(e.target.value);
                  }}
                  placeholder="Enter about"
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter About us
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="buttons-modal border-top pt-3 text-end ">
              <Link to="#" className=" bg-danger" onClick={handleAddClose}>
                Cancel
              </Link>
              <button
                className="ms-2"
                type="submit"
                onClick={AddUpdateArticleApi}
              >
                Save
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {loading ? (
        <div style={{ marginTop: "150px" }}>
          <Loader />
        </div>
      ) : (
        <div className="documents-wrapper user-details-main-card">
          <div className="  user-detail-card">
            <div className="document-cards">
              <div className="t-c-wrapper">
                {/* <p className="word_weap">{aboutData.content}</p> */}
                <p dangerouslySetInnerHTML={{ __html: aboutData?.content }}></p>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default AboutUs;
