import React, { useContext } from "react";
import { AppContext } from "./../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Form from "react-bootstrap/Form";
const PushNotification = () => {
  const { sidebar } = useContext(AppContext);

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="wrapper-push-notification">
        <div className="row main-section">
          <div className="col-md-6 left-section">
            <h4>Step by step working</h4>
            <div class="container">
              {/* <!-- completed --> */}
              <div class="step active">
                <div class="v-stepper">
                  <div class="circle"></div>
                  <div class="line"></div>
                </div>

                <div class="content">
                  <h5>Access Firebase Console</h5>
                  <p>
                    Go to the Firebase website and sign up for an account if you
                    don’t already have one. You can log in with a Google account
                    for easy access
                  </p>
                </div>
              </div>

              {/* <!-- active --> */}
              <div class="step active">
                <div class="v-stepper">
                  <div class="circle"></div>
                  <div class="line"></div>
                </div>

                <div class="content">
                  <h5>CREATE NEW PROJECT</h5>
                  <p>
                    When you log in, you should be directed to the Firebase
                    console. You can manage all of your projects here. Go ahead
                    and create a new one by clicking the blue CREATE NEW PROJECT
                    button. Give your new project a name.
                  </p>
                </div>
              </div>

              <div class="step active">
                <div class="v-stepper">
                  <div class="circle"></div>
                  <div class="line"></div>
                </div>

                <div class="content">
                  <h5>Next</h5>
                  <p>
                    Once you create your project, you’ll be redirected to your
                    project’s console overview. If you check the URL at the top
                    of your browser, you’ll see something like
                    https://console.firebase.google.com/project/talks-you-should-watch/overview,
                    where the part of the URL after /project/ matches your
                    project’s name.
                  </p>
                </div>
              </div>

              <div class="step active">
                <div class="v-stepper">
                  <div class="circle"></div>
                  <div class="line"></div>
                </div>

                <div class="content">
                  <h5>Add Application</h5>
                  <p>
                    Now yoy can add application from Add app option. After this
                    go into the app settings and into the cloud messaging
                    section. You can find server key for android application and
                    APNS Topic, .pem file for ios application. Add those
                    credentials here.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 right-section">
            <h4>Push Notification Settings</h4>
            <div className="mt-5">
              <div className="mb-4">
                <Form.Label>Server Key</Form.Label>
                <Form.Control required type="text" />
                <Form.Control.Feedback
                  type="invalid"
                  placeholder="Email Address"
                >
                  Please Enter Your Email.
                </Form.Control.Feedback>
              </div>
              <div className="mb-4">
                <Form.Label>APNS Topic</Form.Label>
                <Form.Control required type="text" />
                <Form.Control.Feedback type="invalid">
                  Please Enter your password.
                </Form.Control.Feedback>
              </div>
              <div className="mb-5">
                <Form.Label>APNS Certificate</Form.Label>
                <Form.Control required type="file" />
                <Form.Control.Feedback type="invalid">
                  Please Enter your password.
                </Form.Control.Feedback>
              </div>
              <div className="text-end">
                <button>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PushNotification;
