import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import "./assets/styles/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./sharedComponents/Header";
import Sidebar from "./sharedComponents/Sidebar";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import PushNotification from "./pages/PushNotification";
import GoogleAnalytics from "./pages/GoogleAnalytics";
import Users from "./pages/Users";
import DocumentsVarification from "./pages/DocumentsVarification";
import ProfileSuggestion from "./pages/ProfileSuggestion";
import TermsConditions from "./pages/TermsConditions";
import UserVarification from "./pages/UserVarification";
import ViewDocuments from "./pages/ViewDocuments";
import ViewProfileSuggestion from "./pages/ViewProfileSuggestion";
import Articles from "./pages/Articles";
import AboutUs from "./pages/AboutUs";
import PravicyPolicy from "./pages/PravicyPolicy";
import Subscription from "./pages/Subscription";
import QueryManagement from "./pages/QueryManagement";
import Chaperone from "./pages/Chaperone";
import ViewUsers from "./pages/ViewUsers";
import ReportManagement from "./pages/ReportManagement";
import { ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import Support from "./pages/Support";
import Subject from "./pages/Subject";
import PushNotifications from "./pages/PushNotifications";
import DashboardAnalytics from "./pages/DashboardAnalytics";
import MarketingCampaigns from "./pages/MarketingCampaigns";
import AdCampaigns from "./pages/AdCampaign";

function App() {
  // const location = useLocation();
  const currentRoute = useLocation().pathname;
  const navigate = useNavigate();

  const [logedIn, setLoggedIn] = useState(
    localStorage.getItem("logedIn") ? localStorage.getItem("logedIn") : false
  );

  console.log("logedIn", logedIn);
  ///Dashboard

  useEffect(() => {
    if (!logedIn) {
      if (currentRoute.includes("Support")) {
        navigate(currentRoute);
      } else navigate("/");
    } else {
      navigate("Dashboard");
    }
  }, [logedIn]);
  useEffect(() => {
    if (!logedIn) {
      if (currentRoute.includes("Support")) {
        navigate(currentRoute);
      } else navigate("/");
    } else {
      navigate(currentRoute);
    }
  }, [currentRoute]);

  return (
    <div className="App">
      <ToastContainer />

      {currentRoute !== "/" &&
        currentRoute !== "/WebsiteUnderConstruction" &&
        currentRoute !== "/Support" && (
          <>
            <Header setLoggedIn={setLoggedIn} />
            <Sidebar />
          </>
        )}
      <Routes>
        <Route path="/" element={<Login setLoggedIn={setLoggedIn} />} />
        {/* <Route path="Dashboard" element={<Dashboard />} /> */}
        <Route path="Dashboard" element={<DashboardAnalytics />} />
        <Route path="Admin" element={<Admin />} />
        <Route path="Subject" element={<Subject />} />
        <Route path="Support" element={<Support />} />
        <Route path="Users" element={<Users />} />
        <Route
          path="DocumentsVarification"
          element={<DocumentsVarification />}
        />
        <Route path="ProfileSuggestion" element={<ProfileSuggestion />} />
        <Route path="UserVarification" element={<UserVarification />} />
        <Route path="TermsConditions" element={<TermsConditions />} />
        <Route path="PushNotification" element={<PushNotification />} />
        <Route path="PushNotifications" element={<PushNotifications/>}/>
        <Route path="/ViewDocuments/" element={<ViewDocuments />}>
          <Route path=":id" element={<ViewDocuments />} />
        </Route>
        <Route
          path="ViewProfileSuggestion"
          element={<ViewProfileSuggestion />}
        />
        <Route path="GoogleAnalytics" element={<GoogleAnalytics />} />
        <Route path="GoogleAnalytics" element={<GoogleAnalytics />} />
        <Route path="Articles" element={<Articles />} />
        <Route path="AboutUs" element={<AboutUs />} />
        <Route path="PravicyPolicy" element={<PravicyPolicy />} />
        <Route path="Subscription" element={<Subscription />} />
        <Route path="QueryManagement" element={<QueryManagement />} />
        <Route path="Chaperone" element={<Chaperone />} />
        <Route path="/ViewUsers/" element={<ViewUsers />}>
          <Route path=":id" element={<ViewUsers />} />
        </Route>
        <Route path="ReportManagement" element={<ReportManagement />} />
        <Route path="MarketingCampaigns" element={<MarketingCampaigns />} />
        <Route path="AdCampaigns" element={<AdCampaigns />} />
      </Routes>
    </div>
  );
}

export default App;
