import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ic_logout from "../assets/images/ic_logout.svg";
// import wellbeinglogo from "../assets/images/wellbeinglogo.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { AppContext } from "../context/AppContext";

const Header = ({ setLoggedIn }) => {
  const { sidebar, setUserData } = useContext(AppContext);
  const currentRoute = useLocation().pathname;
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Logout
    </Tooltip>
  );

  const logout = (e) => {
    localStorage.clear();
    setUserData({});
    setLoggedIn(false);
  };
  return (
    <main className="cx-header">
      <div
        className={
          sidebar ? "cx-header-wrapper" : "cx-active cx-header-wrapper"
        }
      >
        <header>
          <div></div>
          <div className="right">
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 100, hide: 100 }}
              overlay={renderTooltip}
            >
              <Link to="#" className="bell me-2 ">
                <img
                  src={ic_logout}
                  className="ic-logout"
                  alt=""
                  onClick={logout}
                />
              </Link>
            </OverlayTrigger>
          </div>
        </header>
      </div>
    </main>
  );
};

export default Header;
