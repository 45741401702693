import { AppContext } from "./AppContext";
import React, { useEffect, useState } from "react";
const AppState = (props) => {
  const [userData, setUserData] = useState({
    Token: localStorage.getItem("Token") ? localStorage.getItem("Token") : null,
    FullName: localStorage.getItem("FullName")
      ? localStorage.getItem("FullName")
      : null,
    AdminID: localStorage.getItem("AdminID")
      ? localStorage.getItem("AdminID")
      : null,
    USERNAME: localStorage.getItem("user_name")
      ? localStorage.getItem("user_name")
      : null,

    IsActive: localStorage.getItem("IsActive")
      ? localStorage.getItem("IsActive")
      : null,
    IssuperAdmin: localStorage.getItem("IssuperAdmin")
      ? localStorage.getItem("IssuperAdmin")
      : null,
      IsUserActivation: localStorage.getItem("IsUserActivation")
      ? localStorage.getItem("IsUserActivation")
      : null,
      IsUserUnblock: localStorage.getItem("IsUserUnblock")
      ? localStorage.getItem("IsUserUnblock")
      : null,
      IsUserDocVerify: localStorage.getItem("IsUserDocVerify")
      ? localStorage.getItem("IsUserDocVerify")
      : null,
      IsUserProSugg: localStorage.getItem("IsUserProSugg")
      ? localStorage.getItem("IsUserProSugg")
      : null,
      IsUserCreateArt: localStorage.getItem("IsUserCreateArt")
      ? localStorage.getItem("IsUserCreateArt")
      : null,
  });


  // useEffect(() => {}, [userData]);

  const [sidebar, setSidebar] = useState(true);
  const [document, setDocument] = useState();

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay); // Cancel the timeout if value changes (also on delay change or unmount) // This is how we prevent debounced value from updating if value is changed ... // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
  }

  return (
    <div>
      <AppContext.Provider
        value={{
          sidebar,
          setSidebar,
          useDebounce,
          document,
          setDocument,
          userData,
          setUserData,
        }}
      >
        {props.children}
      </AppContext.Provider>
    </div>
  );
};

export default AppState;
