import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
//Datatable Modules
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import ic_edit from "../assets/images/ic_edit.svg";
import ic_eyes from "../assets/images/ic_eyes.svg";
import Modal from "react-bootstrap/Modal";
import Pagination from "./Pagination";
import { PostCallWithErrorResponse, simpleGetCall } from "../api/ApiServices";
import { BASE_URL } from "../api/ApiConfig";
import { ToastMsg } from "./ToastMsg";
import Loader from "./Loader";
const QueryManagement = () => {
  const { sidebar, useDebounce } = useContext(AppContext);

  const [add, setAdd] = useState(false);
  const [query, setQuery] = useState({});
  const handleAddClose = () => setAdd(false);
  const handleAddShow = (query) => {
    setQuery({
      queryId: query._id,
      reportedUser: query.reportedUser?.fullName,
      message: query.message,
      status: query.status,
      queryresponse: query.queryresponse,
    });
    setAdd(true);
  };
  const [lastPage, setlastPage] = useState();
  const [queryList, setQueryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currrentpage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState();
  const [search, setSearch] = useState("");
  const [viewModal, setView] = useState(false);
  const handleViewClose = () => setView(false);
  const handleViewShow = (query) => {
    setQuery({
      reportedUser: query.reportedUser?.fullName,
      message: query.message,
      status: query.status,
      queryresponse: query.queryresponse,
    });
    setView(true);
  };

  const [validated, setValidated] = useState(false);

  const AddUpdatPricyApi = async (e) => {
    e.preventDefault();
    console.log("queryapi", query);
    const response = await PostCallWithErrorResponse(
      BASE_URL + `/admin/query/manage`,
      JSON.stringify(query)
    );
    console.log("response edit", query);
    if (!response.error) {
      ToastMsg("success", response.json.message);
      handleAddClose();
      getQueruListApi(search, currrentpage);
    } else {
      console.log("error", response.error);
      ToastMsg("error", response.json.message);
    }
  };

  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    getQueruListApi(debouncedSearchTerm, currrentpage);
  }, [debouncedSearchTerm]);

  const getQueruListApi = (search, currrentpage) => {
    setLoading(true);
    simpleGetCall(
      BASE_URL + `/admin/query?page=${currrentpage}&search=${search}`
    ).then((res) => {
      console.log("all queries >>", res.data);
      if (!res.error) {
        setLoading(false);
        setQueryList(res.data);
        if (res.data.length) {
          const totalPage = Number(res.total_pages);
          setTotalPages(totalPage);
          setTotalPages(1);
          setCurrentPage(1);
          setTotalCount(res.data.length);
          setlastPage(res.last_page);
        }
      } else {
        setQueryList([]);
      }
    });
  };

  //Pagination

  const searchedDataPageCounts =
    queryList &&
    queryList.length &&
    queryList.filter((item) =>
      item.ProjectName?.toLowerCase().includes(search)
    );

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected + 1);
    getQueruListApi("", selected + 1);
  };

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Query Management</label>
        </div>
      </div>
      {/* ======================= START ADD ========================== */}
      <Modal
        show={add}
        onHide={handleAddClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Query Management</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={AddUpdatPricyApi}>
            <div className="row">
              <div className="mb-3">
                <Form.Label>User</Form.Label>
                <Form.Control type="text" value={query.reportedUser} disabled />
                <Form.Control.Feedback type="invalid">
                  Please Enter User
                </Form.Control.Feedback>
              </div>
              <div className="mb-3">
                <Form.Label>Query</Form.Label>
                <Form.Control type="text" value={query.message} disabled />
                <Form.Control.Feedback type="invalid">
                  Please Enter Query
                </Form.Control.Feedback>
              </div>
              <div className="col-md-12 mb-3">
                <Form.Label>Enter Query Response</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  required
                  type="text"
                  value={query.queryresponse}
                  onChange={(e) => {
                    setQuery({
                      ...query,
                      queryresponse: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Response
                </Form.Control.Feedback>
              </div>
              <div className="col-md-6 mb-3">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  aria-label="Select Status"
                  className="mb-3"
                  value={query.status}
                  onChange={(e) =>
                    setQuery({
                      ...query,
                      status: e.target.value,
                    })
                  }
                >
                  <option value="OPENED">OPENED</option>
                  <option value="PENDING">PENDING</option>
                  <option value="RESOLVED">RESOLVED</option>
                  <option value="CLOSED">CLOSED</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please Select Status
                </Form.Control.Feedback>
              </div>
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <Link to="#" className=" bg-danger" onClick={handleAddClose}>
                Cancel
              </Link>
              <button className="ms-2" type="submit">
                Save
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END ADD ========================== */}

      {/* ======================= VIEW ========================== */}
      <Modal
        show={viewModal}
        onHide={handleViewClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Query Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    User
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {query.reportedUser}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Status
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {query.status}
                  </label>
                </div>
              </div>
              <div className=" mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Query
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {query.message}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Query Response
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {query.queryresponse
                      ? query.queryresponse
                      : "No Response Yet"}
                  </label>
                </div>
              </div>
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <Link to="#" className="bg-danger" onClick={handleViewClose}>
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END VIEW ========================== */}

      <div className="table-wrapper">
        <div className="search-wrapper row">
          <Form.Group className="mb-3 col-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              placeholder="Search"
              onChange={(e) => {
                setSearch(e.target.value);
                setCurrentPage(1);
              }}
            />
          </Form.Group>
        </div>

        {loading ? (
          <div style={{ marginTop: "150px" }}>
            <Loader />
          </div>
        ) : (
          <div className="main-data-wrapper">
            <Table class="display" striped bordered hover>
              <thead>
                <tr>
                  <th> </th>
                  <th>Name</th>
                  <th>Query</th>
                  <th>Response Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {queryList && queryList.length > 0 ? (
                  queryList
                    .slice()
                    .reverse()
                    .map((query, index) => {
                      return (
                        <tr key={"query" + index}>
                          <td>{(currrentpage - 1) * 10 + index + 1}</td>
                          {/* <td>{query.full_name}</td> */}
                          <td>{query.reportedUser?.fullName}</td>
                          <td>{query.message}</td>
                          <td>{query.status}</td>
                          <td className="action">
                            <span>
                              <Link
                                to="#"
                                className="icon edit"
                                onClick={() => {
                                  handleAddShow(query);
                                }}
                              >
                                <img src={ic_edit} alt="" />
                              </Link>
                            </span>
                            <Link
                              to="#"
                              className="icon view"
                              onClick={() => {
                                handleViewShow(query);
                              }}
                            >
                              <img src={ic_eyes} alt="" />
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                ) : (
                  <tr>
                    <td colSpan={6}>
                      <div className=" justify-content-center align-items-center text-danger text-center mt-3">
                        Data Not Found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        )}
        {queryList.length > 0 ? (
          <div className="pagination-wrapper">
            <div className="entry-number">
              <label htmlFor="">
                Showing {(currrentpage - 1) * 10 + 1} -{" "}
                {lastPage == true ? totalCount : currrentpage * 10} of{" "}
                {totalCount}
              </label>
            </div>
            <Pagination
              data={queryList}
              pageChangeHandler={pageChangeHandler}
              currentPage={currrentpage}
              searchValue={search}
              totalPages={totalPages}
              searchedDataPageCounts={searchedDataPageCounts}
            />
          </div>
        ) : null}
      </div>
    </main>
  );
};

export default QueryManagement;
