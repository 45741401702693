import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
//Datatable Modules
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import passport from "../assets/images/passport.jpg";
import sara from "../assets/images/sara.svg";
import Modal from "react-bootstrap/Modal";
const ViewProfileSuggestion = () => {
  const { sidebar } = useContext(AppContext);

  const [add, setAdd] = useState(false);
  const handleAddClose = () => setAdd(false);
  const handleAddShow = () => setAdd(true);

  const [deleteModal, setDelete] = useState(false);
  const handleDeleteClose = () => setDelete(false);
  const handleDeleteShow = () => setDelete(true);

  const [viewModal, setView] = useState(false);
  const handleViewClose = () => setView(false);
  const handleViewShow = () => setView(true);

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <Link to="/ProfileSuggestion" className="back-btns">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
            >
              <path
                fill="#E40288"
                d="M20 11v2H8l5.5 5.5l-1.42 1.42L4.16 12l7.92-7.92L13.5 5.5L8 11h12Z"
              />
            </svg>
          </Link>
          <label htmlFor="">Suggestion Users</label>
        </div>
      </div>

      <Modal
        show={deleteModal}
        onHide={handleDeleteClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">
              Are you sure you want to Reject this Document?
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button className="me-2 bg-danger" type="submit">
              Yes
            </button>
            <Link to="#" className="" onClick={handleDeleteClose}>
              No
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
      {/* ======================= END DELETE ========================== */}

      {/* ======================= VIEW ========================== */}
      <Modal
        show={viewModal}
        onHide={handleViewClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Passport</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="document-preview-wrapper">
              <img src={passport} alt="" />
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <Link to="#" className="bg-danger" onClick={handleViewClose}>
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END VIEW ========================== */}

      <div className="documents-wrapper">
        <div className="search-wrapper row">
          <Form.Group className=" col-3" controlId="formBasicEmail">
            <Form.Control type="text" placeholder="Search" />
          </Form.Group>
        </div>
        <div className="row g-3 inner-wrapper">
          <div className="col-md-3">
            <div className="document-cards">
              <div className="img">
                <img src={sara} alt="" />
              </div>
              <div className="profile-suggest-card-wrapper">
                <label htmlFor="" className="name">
                  Sara,
                </label>
                <label htmlFor="" className="age">
                  27 Years
                </label>
                <div>
                  <div className="heights">5'5"</div>
                  <div className="heights">Software Engineer</div>
                  <div className="heights">Banglore</div>
                </div>
              </div>
              <div className="buttons-wrapper mt-3">
                <button className="btn bg-success text-white me-2">
                  Suggest
                </button>
                <button className="btn bg-danger text-white me-2">
                  Remove
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="document-cards">
              <div className="img">
                <img src={sara} alt="" />
              </div>
              <div className="profile-suggest-card-wrapper">
                <label htmlFor="" className="name">
                  Sara,
                </label>
                <label htmlFor="" className="age">
                  27 Years
                </label>
                <div>
                  <div className="heights">5'5"</div>
                  <div className="heights">Software Engineer</div>
                  <div className="heights">Banglore</div>
                </div>
              </div>
              <div className="buttons-wrapper mt-3">
                <button className="btn bg-success text-white me-2">
                  Suggest
                </button>
                <button className="btn bg-danger text-white me-2">
                  Remove
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="document-cards">
              <div className="img">
                <img src={sara} alt="" />
              </div>
              <div className="profile-suggest-card-wrapper">
                <label htmlFor="" className="name">
                  Sara,
                </label>
                <label htmlFor="" className="age">
                  27 Years
                </label>
                <div>
                  <div className="heights">5'5"</div>
                  <div className="heights">Software Engineer</div>
                  <div className="heights">Banglore</div>
                </div>
              </div>
              <div className="buttons-wrapper mt-3">
                <button className="btn bg-success text-white me-2">
                  Suggest
                </button>
                <button className="btn bg-danger text-white me-2">
                  Remove
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="document-cards">
              <div className="img">
                <img src={sara} alt="" />
              </div>
              <div className="profile-suggest-card-wrapper">
                <label htmlFor="" className="name">
                  Sara,
                </label>
                <label htmlFor="" className="age">
                  27 Years
                </label>
                <div>
                  <div className="heights">5'5"</div>
                  <div className="heights">Software Engineer</div>
                  <div className="heights">Banglore</div>
                </div>
              </div>
              <div className="buttons-wrapper mt-3">
                <button className="btn bg-success text-white me-2">
                  Suggest
                </button>
                <button className="btn bg-danger text-white me-2">
                  Remove
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="document-cards">
              <div className="img">
                <img src={sara} alt="" />
              </div>
              <div className="profile-suggest-card-wrapper">
                <label htmlFor="" className="name">
                  Sara,
                </label>
                <label htmlFor="" className="age">
                  27 Years
                </label>
                <div>
                  <div className="heights">5'5"</div>
                  <div className="heights">Software Engineer</div>
                  <div className="heights">Banglore</div>
                </div>
              </div>
              <div className="buttons-wrapper mt-3">
                <button className="btn bg-success text-white me-2">
                  Suggest
                </button>
                <button className="btn bg-danger text-white me-2">
                  Remove
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="document-cards">
              <div className="img">
                <img src={sara} alt="" />
              </div>
              <div className="profile-suggest-card-wrapper">
                <label htmlFor="" className="name">
                  Sara,
                </label>
                <label htmlFor="" className="age">
                  27 Years
                </label>
                <div>
                  <div className="heights">5'5"</div>
                  <div className="heights">Software Engineer</div>
                  <div className="heights">Banglore</div>
                </div>
              </div>
              <div className="buttons-wrapper mt-3">
                <button className="btn bg-success text-white me-2">
                  Suggest
                </button>
                <button className="btn bg-danger text-white me-2">
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ViewProfileSuggestion;
