import React from "react";
import ReactPaginate from "react-paginate";
import prev from "../assets/images/ic_prev.svg";
import next from "../assets/images/ic_next_active.svg";
const Pagination = ({ pageChangeHandler, totalPages }) => {
  return (
    <div className="row mt-4">
      <div className="col-lg-12">
        <ReactPaginate
          previousLabel={
            <img src={prev} width="15px" height="15px" alt="Prev0" />
          }
          nextLabel={<img src={next} width="15px" height="15px" alt="Prev0" />}
          pageCount={totalPages}
          onPageChange={pageChangeHandler}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>
    </div>
  );
};

export default Pagination;
