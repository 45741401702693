import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
//Datatable Modules
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Article_Edit from "../assets/images/Article_Edit.svg";
import redDelete from "../assets/images/redDelete.svg";
import ic_edit from "../assets/images/ic_edit.svg";
import Modal from "react-bootstrap/Modal";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import {
  DeleteCallWithErrorResponse,
  PostCallWithErrorResponse,
  multipartPostCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../api/ApiServices";
import ApiConfig, { BASE_URL } from "../api/ApiConfig";
import { ToastMsg } from "./ToastMsg";
import Loader from "./Loader";
import { DefaultEditor } from "react-simple-wysiwyg";
import apiConfig from "../api/ApiConfig";

// Helper function to extract plain text from HTML
const extractPlainText = (html) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
};

const truncateText = (text, length) => {
  if (text.length <= length) {
    return text;
  }
  return text.substring(0, length) + "...";
};

const Articles = () => {
  const { sidebar, userData } = useContext(AppContext);
  localStorage.setItem("IsUserCreateArt", "true");
  const userDataLoc = localStorage.getItem("IsUserCreateArt");
  const AdminID = localStorage.getItem("AdminID");
  const [validated, setValidated] = useState(false);
  const [articledata, setArticleData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [add, setAdd] = useState(false);
  const [artId, setArticleId] = useState();
  const [isEdit, setIsEdit] = useState(false);
  console.log(isEdit);
  const [view, setView] = useState(false);
  const handleViewShow = () => setView(true);
  const handleViewClose = () => setView(false);
  const [imageWidth, setImageWidth] = useState("100%");
  const [article, setArticle] = useState({
    articleId: "",
    adminId: AdminID,
    title: "",
    description: "",
    image: "",
    id: "",
  });
  console.log(article);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const [loading, setLoading] = useState(false);
  const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
  const value = blocks
    .map((block) => (!block.text.trim() && "") || block.text)
    .join("");

  const handleAddClose = () => {
    setArticle({
      articleId: "",
      adminId: "",
      title: "",
      description: "",
      image: "",
      article_id: "",
    });
    setAdd(false);
  };
  const handleAddShow = (article) => {
    console.log("Arti>> ", article);
    let id = article._id;
    if (id) {
      setArticle({
        articleId: article._id,
        title: article.title,
        description: article.content,
        image: article.imageurl,
      });
    }
    setIsEdit(true);
    setAdd(true);
  };

  const filterId = articledata.filter((art) => art._id == article.id);

  const UpdateArticleApi = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      let fromdata = new FormData();

      fromdata.append("image", article.image);
      fromdata.append("title", article.title);
      fromdata.append("articleId", article.articleId);
      fromdata.append("description", article.description);
      fromdata.append("adminId", AdminID);
      multipartPostCallWithErrorResponse(
        BASE_URL + "/articles/addUpdate-articles",
        fromdata
      )
        .then((res) => {
          if (res.error) {
            ToastMsg("error", res.message);
          } else if (res.success === true) {
            ToastMsg("success", res.message);
          }
          getArticalApi();
          handleAddClose();
        })
        .catch((err) => {});
    }
    setValidated(true);
  };

  useEffect(() => {
    getArticalApi();
  }, []);

  // ADD articleDelte API
  const adminDeleteApi = () => {
    DeleteCallWithErrorResponse(
      BASE_URL + apiConfig.ARTICLE_DELTE,
      JSON.stringify({ articleId: artId })
    ).then((response) => {
      console.log(response);
      if (response.error) {
        ToastMsg("error", response.json.message);
      } else if (response.response.ok == true) {
        ToastMsg("success", response.json.message);
        handleAddClose();
        getArticalApi();
      }
    });
  };

  const getArticalApi = () => {
    // setLoading(true);
    simpleGetCallWithErrorResponse(`${BASE_URL}/articles/all-articles`)
      .then((response) => {
        console.log(response);
        if (response.json.data) setArticleData(response.json?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addArticalImageApi = (event) => {
    event.preventDefault();
    console.log("file", article.image);

    if (article.image instanceof File) {
      // If an image is selected, upload it using multipartPostCallWithErrorResponse
      const formData = new FormData();
      formData.append("file", article.image);

      multipartPostCallWithErrorResponse(
        `${BASE_URL}/utils/image-upload`,
        formData
      )
        .then((res) => {
          console.log("addArticalImageApi response", res);
          if (res.error) {
            ToastMsg("error", res.message);
          } else if (res.error === false) {
            ToastMsg("success", res.message);
            console.log("image url", res.json.data.file_url);
            const imageurl = res.json.data.file_url;
            if (article.articleId) {
              setArticle({
                ...article,
                image: imageurl,
              });
              getupdateArticleApi(imageurl);
            } else {
              getEditArticaleApi(imageurl);
            }
          }
        })
        .catch((error) => console.error("Error:", error));
    } else {
      // If no image is selected, directly call PostCallWithErrorResponse
      // getEditArticaleApi("");
      if (article.articleId) {
        getupdateArticleApi(article.image);
      } else {
        getEditArticaleApi("");
      }
    }
  };
  const getEditArticaleApi = (imageurl) => {
    // event.preventDefault();
    PostCallWithErrorResponse(
      `${BASE_URL}/articles/create`,
      JSON.stringify({
        title: article.title,
        content: article.description,
        imageurl: imageurl,
      })
    )
      .then((res) => {
        if (res.error) {
          ToastMsg("error", res.json.message);
        } else if (res.success === true) {
          ToastMsg("success", res.json.message);
        }
        getArticalApi();

        handleAddClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getupdateArticleApi = async (imageurl) => {
    console.log("updated article", article);
    const response = await PostCallWithErrorResponse(
      ApiConfig.UPDATE_ARTICLES,
      JSON.stringify({
        articleId: article.articleId,
        title: article.title,
        description: article.description,
        image: imageurl,
      })
    );
    console.log("article edit res", response);
    if (response.error) {
      ToastMsg("error", response.json.message);
    } else if (response.response.ok == true) {
      ToastMsg("success", response.json.message);
      getArticalApi();
      handleAddClose();
    }
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    setArticle({
      ...article,
      image: file,
    });
  };

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Articles</label>
        </div>

        <div className="add-btn">
          <button onClick={handleAddShow}>
            Add Articles
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path fill="white" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
            </svg>
          </button>
        </div>
      </div>

      {/* ======================= Add Articles ========================== */}
      <Modal
        show={add}
        onHide={handleAddClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {article.articleId ? "Update Article" : "Add Article"}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={addArticalImageApi}>
            <div className="row">
              <div className="col-md-12 mb-3">
                {article.articleId ? (
                  <>
                    <>
                    <Form.Label>Article Image</Form.Label>
                    <div className="edit-modal-img-box mb-3">
                      <img
                        className="article_img"
                        src={!article.image ? Article_Edit : article.image}
                        alt="article_image" />
                    </div>
                    </>
                    <>
                    <Form.Label>Change Article Image</Form.Label>
                    <Form.Control type="file" onChange={handleImage} />
                    <Form.Control.Feedback type="invalid">
                      Please Upload Article Image
                    </Form.Control.Feedback>
                    </>

                  
                  </>
                ) : (
                  <>
                    <Form.Label>Upload Article Image</Form.Label>
                    <Form.Control type="file" onChange={handleImage} />
                    <Form.Control.Feedback type="invalid">
                      Please Upload Article Image
                    </Form.Control.Feedback>
                  </>
                )}
                {/* <Form.Label>Upload Article Image</Form.Label>
                    <Form.Control type="file" onChange={handleImage} />
                    <Form.Control.Feedback type="invalid">
                      Please Upload Article Image
                    </Form.Control.Feedback> */}
              </div>
              <div className="col-md-12 mb-3">
                <Form.Label>Article Title</Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={article.title}
                  onChange={(e) => {
                    setArticle({
                      ...article,
                      title: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Article Title
                </Form.Control.Feedback>
              </div>
              <div className="col-md-12 mb-3">
                <Form.Label>Articles Description</Form.Label>

                <DefaultEditor
                  value={article.description}
                  onChange={(e) => {
                    setArticle({
                      ...article,
                      description: e.target.value,
                    });
                  }}
                  placeholder="Type description"
                />
              </div>
            </div>

            <div className="buttons-modal border-top pt-3 text-end ">
              <Link to="#" className=" bg-danger" onClick={handleAddClose}>
                Cancel
              </Link>
              {article.articleId ? (
                <button
                  className="ms-2"
                  type="submit"
                  onClick={addArticalImageApi}
                >
                  Edit
                </button>
              ) : (
                <button
                  className="ms-2"
                  type="submit"
                  onClick={addArticalImageApi}
                >
                  Save
                </button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= VIEW ========================== */}
      <Modal
        show={view}
        onHide={handleViewClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {filterId &&
          filterId.map((arti) => {
            return (
              <>
                <Modal.Header closeButton>
                  <Modal.Title> {arti.article_title} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <figure>
                      <div className="modal-img-box">
                        <img
                          className="article_img"
                          src={!arti.imageurl ? Article_Edit : arti.imageurl}
                          alt="article_image"
                        />
                      </div>
                      <figcaption>
                        <label htmlFor="" className="Description_text">
                          {arti.title}
                        </label>
                        <div style={{display:'flex', justifyContent:'flex-end', marginRight:'30px'}}>
                        <label htmlFor="" className="name">
                          {new Date(arti.updated).toLocaleDateString('en-GB')}
                        </label>
                        </div>
                        
                        {/* <p className="word_weap">{arti.content}</p> */}
                        <div dangerouslySetInnerHTML={{ __html: arti.content }} />
                      </figcaption>
                    </figure>
                  </div>
                </Modal.Body>
              </>
            );
          })}
      </Modal>
      {/* ======================= END VIEW ========================== */}

      {/* ======================= DELETE ========================== */}

      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              type="submit"
              onClick={() => {
                adminDeleteApi();
                setDeleteModal(false);
              }}
            >
              Delete
            </button>
            <Link
              to="#"
              className=""
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
      {/* ======================= END DELETE ========================== */}

      <div className="documents-wrapper">
        {loading ? (
          <div style={{ marginTop: "150px" }}>
            <Loader />
          </div>
        ) : (
          <div className="row g-3 inner-wrapper article-wrapper">
            {articledata &&
              articledata.map((arti) => {

                const plainTextContent = extractPlainText(arti.content);
                const truncatedContent = truncateText(plainTextContent, 40);

                return (
                  <div className="col-lg-3" key={arti._id}>
                    <div
                      style={{ cursor: "pointer" }}
                      className="document-cards article-card "
                    >
                      <div className="row ">
                        <div className="col text-end justify-content-end mb-2 gap-5">
                          {userDataLoc === "true" && (
                            <>
                              <Link
                                to="#"
                                className="icon edit"
                                onClick={() => {
                                  handleAddShow(arti);
                                }}
                              >
                                <img src={Article_Edit} alt="" />
                              </Link>
                              <Link
                                to="#"
                                className="icon edit text-end ms-3"
                                onClick={() => {
                                  setDeleteModal(true);
                                  setArticleId(arti._id);
                                }}
                              >
                                <img
                                  src={redDelete}
                                  className="text-end"
                                  alt=""
                                />
                              </Link>
                            </>
                          )}
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          handleViewShow();

                          setArticle({
                            ...article,
                            id: arti._id,
                          });
                        }}
                      >
                        <div className="img">
                          <img
                            src={!arti.imageurl ? Article_Edit : arti.imageurl}
                            alt="article_image"
                          />
                        </div>
                        <div className="profile-suggest-card-wrapper">
                          <label htmlFor="" className="name">
                            Title : {arti.title}
                          </label>

                          <div>
                            <label htmlFor="" className="name">
                              Created At : {new Date(arti.updated).toLocaleDateString('en-GB')}
                            </label>
                          </div>
                          
                          <div>
                            <label htmlFor="" className="name">
                              Description :
                            </label>
                            {/* <p className="single-line-elipsis">
                              {arti.content.slice(0, 40)}
                            </p> */}
                            <p className="single-line-elipsis" dangerouslySetInnerHTML={{ __html: truncatedContent }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </main>
  );
};

export default Articles;
