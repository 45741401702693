import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
//Datatable Modules
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import ic_sub from "../assets/images/ic_sub.svg";
import Modal from "react-bootstrap/Modal";
import { Row } from "react-bootstrap";

import {
  DeleteCallWithErrorResponse,
  postWithAuthCall,
  putWithAuthCall,
  simpleGetCallWithErrorResponse,
} from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import redDelete from "../assets/images/redDelete.svg";
import IconEdit from "../assets/images/Article_Edit.svg";

const currencyList = [
  {
    symbol: "$",
    name: "United States Dollar",
  },
  {
    symbol: "€",
    name: "Euro",
  },
  {
    symbol: "¥",
    name: "Japanese Yen",
  },
  {
    symbol: "£",
    name: "British Pound Sterling",
  },
  {
    symbol: "₽",
    name: "Russian Ruble",
  },
  {
    symbol: "₹",
    name: "Indian Rupee",
  },
  {
    symbol: "C$",
    name: "Canadian Dollar",
  },
  {
    symbol: "A$",
    name: "Australian Dollar",
  },
];
const Subscription = () => {
  const { sidebar } = useContext(AppContext);

  const [deleteModal, setDelete] = useState(false);
  const [editSubPackage, setEditSubPackage] = useState(false);
  const [deleteSubPackage, setDeleteSubPackage] = useState(null);
  const handleDeleteClose = () => setDelete(false);

  const [packageDetails, setPackageDetails] = useState({
    title: "",
    gradientStart: "",
    gradientStop: "",
    description: "",
    features: [],
    packages: [],
  });
  const [subPackages, setSubPackages] = useState([]);
  const [viewModal, setView] = useState({
    show: false,
    update: false,
    pack: null,
  });
  const handleViewClose = () => setView(false);
  const handleViewUpdateModal = (pack) => {
    setView({ show: true, update: true, pack: pack });
    setPackageDetails({
      title: pack.title,
      description: pack.description,
      features: pack.features,
      packages: pack.packages,
    });
    setSubPackages(pack.packages);
  };
  const handleViewAddModal = () => setView({ show: true, update: false });

  const [packages, setPackages] = useState([]);

  const [currentSubPackage, setCurrentSubpackage] = useState({
    name: "",
    product_id: "",
    duration: "",
    price: 0,
    currency: currencyList.find((e) => e.symbol === "€"),
  });

  const handleAddSubPackage = (event) => {
    event.preventDefault();
    if (
      currentSubPackage.name &&
      currentSubPackage.product_id &&
      currentSubPackage.price &&
      currentSubPackage.duration
    ) {
      setSubPackages([...subPackages, currentSubPackage]);
      setCurrentSubpackage({
        duration: "",
        price: 0,
        name: "",
        product_id: "",
        currency: currencyList.find((e) => e.symbol === "€"),
      });
    }
  };

  const handleRemoveSubPackage = () => {
    console.log({ deleteSubPackage });
    const updatedSubPackages = subPackages.filter((pack) => {
      if (pack._id) {
        if (pack._id !== deleteSubPackage._id) {
          return true;
        }
      } else {
        if (pack.name !== deleteSubPackage._id) {
          return true;
        }
      }
    });
    setSubPackages(updatedSubPackages);
    setDelete(false);
  };

  const addPackage = (event) => {
    event.preventDefault();
    const _features = packageDetails.features
      .toString()
      .split(",")
      .map((e) => e.trim());
    const pkg = {
      ...packageDetails,
      features: _features,
      packages: subPackages,
    };
    setPackageDetails(packageDetails);

    console.log(pkg);
    if (viewModal.update && viewModal.pack._id) {
      updateMembershipPlan(viewModal.pack._id, pkg);
    } else if (viewModal.update == false) {
      addMembershipPlan(pkg);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPackageDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const updateMembershipPlan = (id, body) => {
    putWithAuthCall(
      `${ApiConfig.ADD_SUBSCRIPTION_PLAN}/${id}`,
      JSON.stringify(body)
    )
      .then((response) => {
        if (response) {
          handleViewClose();
          getAllMembershipPlans();
        }
      })
      .catch((error) => {
        console.log(error);
        handleViewClose();
      });
  };

  const addMembershipPlan = (body) => {
    postWithAuthCall(`${ApiConfig.ADD_SUBSCRIPTION_PLAN}`, JSON.stringify(body))
      .then((response) => {
        if (response) {
          handleViewClose();
          getAllMembershipPlans();
        }
      })
      .catch((error) => {
        console.log(error);
        handleViewClose();
      });
  };

  const getAllMembershipPlans = () => {
    simpleGetCallWithErrorResponse(`${ApiConfig.GET_ALL_SUBSCRIPTION_PLANS}`)
      .then((response) => {
        if (response.json.data) {
          setPackages(response.json.data["plans"]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteMembershipPlan = (id) => {
    DeleteCallWithErrorResponse(`${ApiConfig.ADD_SUBSCRIPTION_PLAN}/${id}`)
      .then((response) => {
        if (response) {
          handleViewClose();
          getAllMembershipPlans();
        }
      })
      .catch((error) => {
        console.log(error);
        handleViewClose();
      });
  };

  const deletePackage = (event, id) => {
    event.preventDefault();
    deleteMembershipPlan(id);
  };

  const handleEditSubPackage = () => {
    console.log({ currentSubPackage, subPackages });
    const updatedSubPackages = subPackages.map((pack) => {
      if (pack._id) {
        if (pack._id === currentSubPackage._id) {
          return currentSubPackage;
        }
        return pack;
      } else {
        if (pack.name === currentSubPackage._id) {
          return currentSubPackage;
        }
        return pack;
      }
    });

    setSubPackages(updatedSubPackages);
  };

  useEffect(() => {
    getAllMembershipPlans();
  }, []);

  console.log("render", { subPackages });
  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Subscription</label>
        </div>
        <div className="add-btn">
          <button onClick={handleViewAddModal}>
            Add Subscription
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path fill="white" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
            </svg>
          </button>
        </div>
      </div>

      <Modal
        show={deleteModal}
        onHide={handleDeleteClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center" style={{ fontSize: "12px" }}>
            <label htmlFor="">
              Are you sure you want to delete this sub-package?
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              onClick={handleRemoveSubPackage}
              className="me-2 bg-danger"
              type="submit"
            >
              Yes
            </button>
            <Link to="#" className="" onClick={handleDeleteClose}>
              No
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
      {/* ======================= END DELETE ========================== */}

      {/* ======================= VIEW ========================== */}
      <Modal
        show={viewModal.show}
        onHide={handleViewClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {viewModal.update ? "Update" : "Add"} Membership Plan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <div className="col-md-12 mb-3">
                <Form.Label>Package Name</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={packageDetails.title}
                  placeholder="Package Name"
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Name
                </Form.Control.Feedback>
              </div>

              <div className="col-md-12 mb-3">
                <Form.Label>Package Theme</Form.Label>

                <Form.Control
                  type="text"
                  name="gradientStart"
                  placeholder="Color Start"
                  className=""
                  onChange={handleChange}
                />
                <Form.Control
                  type="text"
                  name="gradientStop"
                  placeholder="Color Stop"
                  className="mb-3"
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Name
                </Form.Control.Feedback>
              </div>

              <div className="col-md-12 mb-3">
                <Form.Label>Package Description</Form.Label>

                <Form.Control
                  as="textarea"
                  rows={2} // You can adjust the number of rows as needed
                  name="description"
                  placeholder="Package Description"
                  value={packageDetails.description}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Description
                </Form.Control.Feedback>
              </div>

              <div className="col-md-12 mb-3">
                <Form.Label>Package Features</Form.Label>

                <Form.Control
                  as="textarea"
                  rows={4} // You can adjust the number of rows as needed
                  name="features"
                  placeholder="feature1, feature2 ..."
                  value={packageDetails.features}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Features
                </Form.Control.Feedback>
              </div>

              <div className="col-md-12 mb-3">
                <Form.Label>Sub Packages</Form.Label>
                {subPackages.map((subPackage, index) => (
                  <Row key={index}>
                    <div className="d-flex justify-content-start align-items-center">
                      <b>
                        {subPackage.name}({subPackage.duration})
                      </b>{" "}
                      - {subPackage.currency ? subPackage.currency.symbol : "$"}
                      {subPackage.price}
                      <Link to="#" className="icon edit text-end ms-2">
                        <img
                          src={redDelete}
                          className="text-end"
                          alt=""
                          onClick={() => {
                            setDelete(true);
                            const subPackData = {
                              ...subPackage,
                              _id: subPackage._id ?? subPackage.name,
                            };
                            setDeleteSubPackage(subPackData);
                          }}
                        />
                      </Link>
                      <Link to="#" className="icon edit text-end ms-2">
                        <img
                          src={IconEdit}
                          className="text-end"
                          alt=""
                          onClick={() => {
                            const subPackData = {
                              ...subPackage,
                              _id: subPackage._id ?? subPackage.name,
                            };
                            setCurrentSubpackage(subPackData);
                            setEditSubPackage(true);
                          }}
                        />
                      </Link>
                    </div>
                    <div className="mb-3 d-flex justify-content-start align-items-center">
                      {subPackage.product_id}
                    </div>
                    <div className="mb-3"></div>
                  </Row>
                ))}
                <div className="col ">
                  <Form.Control
                    type="text"
                    placeholder="Package Name"
                    className="mb-3"
                    value={currentSubPackage.name}
                    onChange={(e) =>
                      setCurrentSubpackage({
                        ...currentSubPackage,
                        name: e.target.value,
                      })
                    }
                  />
                  <Form.Control
                    type="text"
                    placeholder="Product ID"
                    className="mb-3"
                    value={currentSubPackage.product_id}
                    onChange={(e) =>
                      setCurrentSubpackage({
                        ...currentSubPackage,
                        product_id: e.target.value,
                      })
                    }
                  />
                  <Form.Control
                    type="text"
                    placeholder="Package Duration"
                    className="mb-3"
                    value={currentSubPackage.duration}
                    onChange={(e) =>
                      setCurrentSubpackage({
                        ...currentSubPackage,
                        duration: e.target.value,
                      })
                    }
                  />
                  <div></div>
                  <Form.Select
                    aria-label="Select Duration"
                    className="mb-3"
                    value={currentSubPackage.currency.name}
                    onChange={(event) =>
                      setCurrentSubpackage({
                        ...currentSubPackage,
                        currency: currencyList.find(
                          (e) => e.name === event.target.value
                        ),
                      })
                    }
                  >
                    {currencyList.map((currency) => (
                      <option value={currency.name} key={currency.name}>
                        {currency.name + " - " + currency.symbol}
                      </option>
                    ))}
                  </Form.Select>

                  <Form.Control
                    type="text"
                    placeholder="Package Price"
                    value={currentSubPackage.price}
                    className="mb-3"
                    onChange={(e) =>
                      setCurrentSubpackage({
                        ...currentSubPackage,
                        price: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="buttons-modal text-start mb-3">
                  <button
                    className="bg-secondary"
                    type="button"
                    onClick={
                      editSubPackage
                        ? handleEditSubPackage
                        : handleAddSubPackage
                    }
                  >
                    {editSubPackage ? "Update" : "Add"}
                  </button>
                  {viewModal.update && editSubPackage ? (
                    <button
                      className="bg-secondary ms-3"
                      type="button"
                      onClick={() => {
                        setCurrentSubpackage({
                          duration: "",
                          price: 0,
                          name: "",
                          currency: currencyList.find((e) => e.symbol === "€"),
                        });
                        setEditSubPackage(false);
                      }}
                    >
                      Cancel
                    </button>
                  ) : (
                    <></>
                  )}
                </div>

                <Form.Control.Feedback type="invalid">
                  Please Enter Name
                </Form.Control.Feedback>
              </div>
            </div>
            <div
              className="buttons-modal border-top pt-3 text-end "
              style={{ display: "flex", gap: "10px", justifyContent: "end" }}
            >
              {viewModal.update ? (
                <button
                  className="bg-danger"
                  onClick={(event) => deletePackage(event, viewModal.pack._id)}
                >
                  Delete
                </button>
              ) : (
                <></>
              )}

              <button className="bg-danger" onClick={addPackage}>
                Submit
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END VIEW ========================== */}

      <div className="documents-wrapper">
        <div className="row g-3">
          {packages.map((pack) => (
            <div className="col-md-4" key={pack.title}>
              <div className="document-cards">
                <div className="paln-card b">
                  <div>
                    <img src={ic_sub} alt="" />
                  </div>
                  <div className="name-c">{pack.title}</div>
                </div>
                <div className="buttons-wrapper mt-3">
                  <button
                    style={{ backgroundColor: "#055D9D" }}
                    className="btn  text-white w-100"
                    onClick={() => handleViewUpdateModal(pack)}
                  >
                    Upgrade Plan
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </main>
  );
};

export default Subscription;
