import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { useNavigate } from "react-router-dom";

const Support = () => {
  const navigate = useNavigate();

  return (
    <main id="cx-main" className="support_main" style={{ marginLeft: "0px" }}>
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Marry'm Support</label>
        </div>
        <button
          className="cx-btn-1"
          type="button"
          onClick={() => {
            navigate(-1);
          }}
        >
          Back to Login
        </button>
      </div>

      <div className="documents-wrapper user-details-main-card">
        <div className="  user-detail-card">
          <div className="document-cards">
            <div className="t-c-wrapper">
              <p className="word_weap">How Can We Help ? </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Support;
