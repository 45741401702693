import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
//Datatable Modules
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Loader from "./Loader";

import {
  PostCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../api/ApiServices";
import { BASE_URL } from "../api/ApiConfig";
import { ToastMsg } from "./ToastMsg";
import DefaultEditor from "react-simple-wysiwyg";

const TermsConditions = () => {
  const { sidebar } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [add, setAdd] = useState(false);
  const handleAddClose = () => setAdd(false);
  const handleAddShow = () => setAdd(true);

  const [contentBody, setContentBody] = useState();
  const [validated, setValidated] = useState(false);
  const [terms, setTerms] = useState();

  const AppUpdateTermApi = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      PostCallWithErrorResponse(
        BASE_URL + "/content/terms",
        JSON.stringify({ body: contentBody })
      )
        .then((res) => {
          if (res.error) {
            ToastMsg("error", res.json.message);
          } else if (res.json.error == false) {
            ToastMsg("success", res.json.message);
            getPrivacyDataApi();
            handleAddClose();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setValidated(true);
  };

  useEffect(() => {
    getPrivacyDataApi();
  }, []);

  const getPrivacyDataApi = () => {
    setLoading(true);
    simpleGetCallWithErrorResponse(BASE_URL + "/content/terms")
      .then((response) => {
        // console.log(response);
        if (response.json.data) {
          setTerms(response.json.data);
          setContentBody(response.json.data.content);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Terms & Conditions</label>
        </div>
        <div className="add-btn">
          <button
            onClick={(e) => {
              handleAddShow();
            }}
          >
            Update
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path fill="white" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
            </svg>
          </button>
        </div>
      </div>

      <Modal
        show={add}
        onHide={handleAddClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={AppUpdateTermApi}>
            <div className="row">
              <div className="col-md-12 mb-3">
                <Form.Label>Terms & Conditions</Form.Label>
                {/* <Form.Control
                  as="textarea"
                  rows={10}
                  required
                  value={contentBody}
                  onChange={(e) => {
                    setContentBody(e.target.value);
                  }}
                /> */}
                <DefaultEditor
                  value={contentBody}
                  onChange={(e) => {
                    setContentBody(e.target.value);
                  }}
                  placeholder="Enter terms & conditions"
                />
                <Form.Control.Feedback type="invalid">
                  Please Terms & Conditions
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="buttons-modal border-top pt-3 text-end ">
              <Link to="#" className=" bg-danger" onClick={handleAddClose}>
                Cancel
              </Link>
              <button className="ms-2" type="submit" onClick={AppUpdateTermApi}>
                Save
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {loading ? (
        <div style={{ marginTop: "150px" }}>
          <Loader />
        </div>
      ) : (
        <div className="documents-wrapper user-details-main-card">
          <div className="  user-detail-card">
            <div className="document-cards">
              <div className="t-c-wrapper">
                <p className="word_weap">
                  {/* {terms?.content} */}
                  <p dangerouslySetInnerHTML={{ __html: terms?.content }}></p>
                  {/* n most civilisations, marriage and family are essential institutions. However, while the two organisations have a long history of cultural ties across the world, their relationship is getting increasingly complicated. Whether with your friends or your parents, the link between marriage and family is an intriguing issue to explore. As a human being, you must have certain wants that are frequently met through social, personal, and emotional connections. When faced with the question “what is marriage?” different people might have different opinions. Even sociologists can’t agree on a single definition. However, the legal definition of marriage is recognised as a social contract between two people, historically based on a sexual relationship and suggesting the union’s permanency. Because historically, weddings are what establish a family, and families */}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default TermsConditions;
