import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { Modal } from "react-bootstrap";
import {
  DeleteCallWithErrorResponse,
  multipartPostCallWithErrorResponse,
  PostCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../api/ApiServices";
import ApiConfig, { BASE_URL } from "../api/ApiConfig";
import { ToastMsg } from "./ToastMsg";
import Form from "react-bootstrap/Form";
import Article_Edit from "../assets/images/Article_Edit.svg";
import redDelete from "../assets/images/redDelete.svg";
import Loader from "./Loader";
import { Link } from "react-router-dom";

const AdCampaigns = () => {
  const { sidebar, userData } = useContext(AppContext);
  const [adCampaign, setAdCampaign] = useState({
    adCampaignId: "",
    campaignName: "",
    startDate: "",
    budget: null,
    startTime: "",
    endTime: "",
    keyMetrics: "",
    adCreatives: "",
    adCreativeSize: "",
    targetAudienceGender: "",
    targetAudienceAgeFrom: "",
    targetAudienceAgeTo: "",
    campaignDescription: "",
    totalConversions: null,
    additionalNotes: "",
    id: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [adCamaignData, setAdCampaignData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [adId, setAdId] = useState();
  const [view, setView] = useState(false);
  const handleViewShow = () => setView(true);
  const handleViewClose = () => setView(false);

  useEffect(() => {
    getAdCampaignApi();
  }, []);

  const filterId = adCamaignData.filter((ad) => ad._id == adCampaign.id);

  const handleAddClose = () => {
    setAdCampaign({
      adCampaignId: "",
      campaignName: "",
      startDate: "",
      budget: null,
      startTime: "",
      endTime: "",
      keyMetrics: "",
      adCreatives: "",
      adCreativeSize: "",
      targetAudienceGender: "",
      targetAudienceAgeFrom: "",
      targetAudienceAgeTo: "",
      campaignDescription: "",
      totalConversions: null,
      additionalNotes: "",
      id: "",
    });
    setAdd(false);
  };

  const handleAddShow = (ad) => {
    let id = ad._id;
    if (id) {
      setAdCampaign({
        adCampaignId: ad._id,
        campaignName: ad.campaignName,
        startDate: ad.startDate ? ad.startDate.split("T")[0] : "",
        budget: ad.budget,
        startTime: ad.startTime,
        endTime: ad.endTime,
        keyMetrics: ad.keyMetrics,
        adCreatives: ad.adCreatives,
        adCreativeSize: ad.adCreativeSize,
        targetAudienceGender: ad.targetAudienceGender,
        targetAudienceAgeFrom: ad.targetAudienceAgeFrom,
        targetAudienceAgeTo: ad.targetAudienceAgeTo,
        campaignDescription: ad.campaignDescription,
        totalConversions: ad.totalConversions,
        additionalNotes: ad.additionalNotes,
      });
    }
    setIsEdit(true);
    setAdd(true);
  };

  const getAdCampaignApi = () => {
    simpleGetCallWithErrorResponse(`${BASE_URL}/ad-campaign/all`)
      .then((response) => {
        // console.log(response);
        if (response.json.data) setAdCampaignData(response.json?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const addCampaignImageApi = (event) => {
    event.preventDefault();
    if (adCampaign.adCreatives instanceof File) {
      const formData = new FormData();
      formData.append("file", adCampaign.adCreatives);

      multipartPostCallWithErrorResponse(
        `${BASE_URL}/utils/image-upload`,
        formData
      )
        .then((res) => {
          if (res.error) {
            ToastMsg("error", res.message);
          } else if (res.error === false) {
            ToastMsg("success", res.message);
            const imageurl = res.json.data.file_url;
            if (adCampaign.adCampaignId) {
              setAdCampaign({
                ...adCampaign,
                adCreatives: imageurl,
              });
              getUpdateAdCampaignApi(imageurl);
            } else {
              getEditAdCampaignApi(imageurl);
            }
          }
        })
        .catch((error) => console.error("Error:", error));
    } else {
      if (adCampaign.adCampaignId) {
        getUpdateAdCampaignApi(adCampaign.adCreatives);
      } else {
        getEditAdCampaignApi("");
      }
    }
  };

  const getUpdateAdCampaignApi = async (imageurl) => {
    const response = await PostCallWithErrorResponse(
      ApiConfig.UPDATE_AD_CAMPAIGN,
      JSON.stringify({
        ...adCampaign,
        adCreatives: imageurl,
      })
    );
    if (response.error) {
      ToastMsg("error", response.json.message);
    } else if (response.response.ok == true) {
      ToastMsg("success", response.json.message);
      getAdCampaignApi();
      handleAddClose();
    }
  };

  const getEditAdCampaignApi = (imageurl) => {
    PostCallWithErrorResponse(
      `${BASE_URL}/ad-campaign/create`,
      JSON.stringify({
        ...adCampaign,
        adCreatives: imageurl,
      })
    )
      .then((res) => {
        if (res.error) {
          ToastMsg("error", res.json.message);
        } else if (res.success === true) {
          ToastMsg("success", res.json.message);
        }

        getAdCampaignApi();
        handleAddClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const adCampaignDeleteApi = () => {
    DeleteCallWithErrorResponse(
      ApiConfig.DELETE_AD_CAMPAIGN,
      JSON.stringify({ adCampaignId: adId })
    ).then((response) => {
      if (response.error) {
        ToastMsg("error", response.json.message);
      } else if (response.response.ok == true) {
        ToastMsg("success", response.json.message);
        handleAddClose();
        getAdCampaignApi();
      }
    });
  };
  const handleImage = (e) => {
    const file = e.target.files[0];
    setAdCampaign({
      ...adCampaign,
      adCreatives: file,
    });
  };

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Ad Campaigns</label>
        </div>

        <div className="add-btn">
          <button onClick={handleAddShow}>
            New Ad Campaign
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path fill="white" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
            </svg>
          </button>
        </div>
      </div>

      {/* ======================= Create Ad Campaign ========================== */}
      <Modal
        show={add}
        onHide={handleAddClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {adCampaign.adCampaignId
              ? "Update Ad Campaign"
              : "New Ad Campaign"}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={addCampaignImageApi}>
            <div className="row">
              <div className="col-md-12 mb-3">
                {adCampaign.adCampaignId ? (
                  <>
                    <>
                      <Form.Label>Ad Creative</Form.Label>
                      <div className="edit-modal-img-box mb-3">
                        <img
                          className="article_img"
                          src={
                            !adCampaign.adCreatives
                              ? Article_Edit
                              : adCampaign?.adCreatives
                          }
                          alt="ad_creative"
                        />
                      </div>
                    </>
                    <>
                      <Form.Label>Change Ad Creative</Form.Label>
                      <Form.Control type="file" onChange={handleImage} />
                      <Form.Control.Feedback type="invalid">
                        Please Upload Ad Creative
                      </Form.Control.Feedback>
                    </>
                  </>
                ) : (
                  <>
                    <Form.Label>Upload Ad Creative</Form.Label>
                    <Form.Control type="file" onChange={handleImage} />
                    <Form.Control.Feedback type="invalid">
                      Please Upload Ad Creative
                    </Form.Control.Feedback>
                  </>
                )}
              </div>
              <div className="col-md-12 mb-3">
                <Form.Label>Ad Campaign Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={adCampaign?.campaignName}
                  onChange={(e) => {
                    setAdCampaign({
                      ...adCampaign,
                      campaignName: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Ad Campaign Name
                </Form.Control.Feedback>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  required
                  value={adCampaign?.startDate}
                  onChange={(e) => {
                    setAdCampaign({
                      ...adCampaign,
                      startDate: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Ad Campaign Start Date
                </Form.Control.Feedback>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Label>Start Time</Form.Label>
                <Form.Control
                  type="time"
                  required
                  value={adCampaign?.startTime}
                  onChange={(e) => {
                    setAdCampaign({
                      ...adCampaign,
                      startTime: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Ad Campaign Start Time
                </Form.Control.Feedback>
              </div>

              <div className="col-md-12 mb-3">
                <Form.Label>Duration</Form.Label>
                {/* <Form.Control
                  type="number"
                  required
                  value={adCampaign?.budget}
                  onChange={(e) => {
                    setAdCampaign({
                      ...adCampaign,
                      budget: e.target.value,
                    });
                  }}
                /> */}
                <Form.Select
                  aria-label="Select Plan"
                  className="mb-3"
                  value={adCampaign?.budget}
                  onChange={(e) =>
                    setAdCampaign({
                      ...adCampaign,
                      budget: e.target.value,
                    })
                  }
                >
                  <option value="" disabled selected>
                    Select Plan
                  </option>
                  <option value="1W">
                    <span className="highlight">1 Week: £99.00</span>
                  </option>
                  <option value="2W">
                    <span className="highlight">2 Weeks: £189.00</span>
                    <span className="small-text"> [You save £9.00]</span>
                  </option>
                  <option value="1M">
                    <span className="highlight">1 Month: £359.00</span>
                    <span className="small-text"> [You save £37.00]</span>
                  </option>
                  <option value="2M">
                    <span className="highlight">2 Months: £679.00</span>
                    <span className="small-text"> [You save £113.00]</span>
                  </option>
                  <option value="3M">
                    <span className="highlight">3 Months: £959.00</span>
                    <span className="small-text"> [You Save £229.00]</span>
                  </option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please Select Duration
                </Form.Control.Feedback>
              </div>

              <div className="col-md-12 mb-3">
                <Form.Label>Target Audience</Form.Label>
                <div style={{ display: "flex", gap: 5 }}>
                  <div className="col-md-6">
                    <Form.Select
                      aria-label="Select Gender"
                      className="mb-3"
                      value={adCampaign?.targetAudienceGender}
                      onChange={(e) =>
                        setAdCampaign({
                          ...adCampaign,
                          targetAudienceGender: e.target.value,
                        })
                      }
                    >
                      <option value="" disabled selected>
                        Select Gender
                      </option>
                      <option value="MALE">Male</option>
                      <option value="FEMALE">Female</option>
                      <option value="ALL">ALL</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Select Gender
                    </Form.Control.Feedback>
                  </div>

                  {/* Age From */}
                  <div className="col-md-3">
                    <Form.Select
                      aria-label="Target Age From"
                      className="mb-3"
                      value={adCampaign?.targetAudienceAgeFrom}
                      onChange={(e) =>
                        setAdCampaign({
                          ...adCampaign,
                          targetAudienceAgeFrom: e.target.value,
                        })
                      }
                    >
                      <option value="" disabled selected>
                        Target Age From
                      </option>
                      {[...Array(53)].map((_, index) => {
                        const age = 18 + index;
                        return (
                          <option key={age} value={age}>
                            {age}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Select Target Age
                    </Form.Control.Feedback>
                  </div>

                  {/* Age To */}
                  <div className="col-md-3">
                    <Form.Select
                      aria-label="Target Age TO"
                      className="mb-3"
                      value={adCampaign?.targetAudienceAgeTo}
                      onChange={(e) =>
                        setAdCampaign({
                          ...adCampaign,
                          targetAudienceAgeTo: e.target.value,
                        })
                      }
                    >
                      <option value="" disabled selected>
                        Target Age To
                      </option>
                      {[...Array(53)].map((_, index) => {
                        const age = 18 + index;
                        return (
                          <option key={age} value={age}>
                            {age}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Select Target Age
                    </Form.Control.Feedback>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mb-3">
                <Form.Label>Ad Campaign Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  required
                  value={adCampaign?.campaignDescription}
                  onChange={(e) => {
                    setAdCampaign({
                      ...adCampaign,
                      campaignDescription: e.target.value,
                    });
                  }}
                  maxLength={150}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Ad Campaign Description
                </Form.Control.Feedback>
              </div>
              <div className="col-md-12 mb-3">
                <Form.Label>Website URL</Form.Label>
                <Form.Control
                  type="text"
                  required
                  placeholder="Enter URL"
                  value={adCampaign?.additionalNotes}
                  onChange={(e) => {
                    setAdCampaign({
                      ...adCampaign,
                      additionalNotes: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Website URL
                </Form.Control.Feedback>
              </div>
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <Link to="#" className=" bg-danger" onClick={handleAddClose}>
                Cancel
              </Link>
              {adCampaign.adCampaignId ? (
                <button
                  className="ms-2"
                  type="submit"
                  onClick={addCampaignImageApi}
                >
                  Edit
                </button>
              ) : (
                <button
                  className="ms-2"
                  type="submit"
                  onClick={addCampaignImageApi}
                >
                  Save
                </button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* ======================= VIEW ========================== */}

      <Modal
        show={view}
        onHide={handleViewClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {filterId &&
          filterId.map((ad) => {
            return (
              <>
                <Modal.Header closeButton>
                  <Modal.Title> {ad.campaignName} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <figure>
                      <div className="modal-img-box mb-3">
                        <img
                          className="article_img"
                          src={!ad.adCreatives ? Article_Edit : ad.adCreatives}
                          alt="ad_Creative"
                        />
                      </div>

                      <figcaption>
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <div>
                              <label htmlFor="" className="key">
                                Start Date :
                              </label>
                              <label
                                htmlFor=""
                                className="value"
                                style={{ marginLeft: "5px" }}
                              >
                                {new Date(ad.startDate).toLocaleDateString(
                                  "en-GB"
                                )}
                              </label>
                            </div>

                            <div>
                              <label htmlFor="" className="key">
                                Start Time :
                              </label>
                              <label
                                htmlFor=""
                                className="value"
                                style={{ marginLeft: "5px" }}
                              >
                                {ad.startTime}
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6 mb-3">
                            <div>
                              <label htmlFor="" className="key">
                                Duration :
                              </label>
                            </div>
                            <div>
                              <label htmlFor="" className="value">
                                {/* {ad?.budget} */}
                                {/* {ad?.budget &&  */}
        {ad.budget === '1W' ? (
                                  "1 Week: £99.00"
                                ):ad.budget === '2W' ? (
                                  "2 Weeks: £189.00   [You save £9.00]"
                                ): ad.budget === "11M" ? (
                                  "1 Month: £359.00   [You save £37.00]"
                                ): ad.budget === "2M" ? (
                                  "2 Months: £679.00  [You save £113.00]"
                                ) : ad.budget === "3M" ? (
                                  "3 Months: £959.00  [You Save £229.00]"
                                ):(
                                  ad.budget
                                )
                                }
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6 mb-3">
                            <div>
                              <label htmlFor="" className="key">
                                Target Audience :
                              </label>
                            </div>
                            <div>
                              <label htmlFor="" className="value col-md-6">
                                Gender : {ad?.targetAudienceGender}
                              </label>
                              <label htmlFor="" className="value col-md-6">
                                Age : {ad?.targetAudienceAgeFrom}{" "}
                                {ad.targetAudienceAgeTo &&
                                  `to ${ad?.targetAudienceAgeTo}`}
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6 mb-3">
                            <div>
                              <label htmlFor="" className="key">
                                Additional notes :
                              </label>
                            </div>
                            <div>
                              <label htmlFor="" className="value">
                                {ad?.additionalNotes}
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12 mb-3">
                            <div>
                              <label htmlFor="" className="key">
                                Description :
                              </label>
                            </div>
                            <div>
                              <label htmlFor="" className="value">
                                {ad?.campaignDescription}
                              </label>
                            </div>
                          </div>
                        </div>
                      </figcaption>
                    </figure>
                  </div>
                </Modal.Body>
              </>
            );
          })}
      </Modal>

      {/* ======================= END VIEW ========================== */}

      {/* ======================= DELETE ========================== */}
      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              type="submit"
              onClick={() => {
                adCampaignDeleteApi();
                setDeleteModal(false);
              }}
            >
              Delete
            </button>
            <Link
              to="#"
              className=""
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>

      <div className="documents-wrapper">
        {loading ? (
          <div style={{ marginTop: "150px" }}>
            <Loader />
          </div>
        ) : (
          <div className="row g-3 inner-wrapper article-wrapper">
            {adCamaignData &&
              adCamaignData.map((ad) => {
                return (
                  <div className="col-lg-3" key={ad._id}>
                    <div
                      style={{ cursor: "pointer" }}
                      className="document-cards article-card "
                    >
                      <div className="row ">
                        <div className="col text-end justify-content-end mb-2 gap-5">
                          <>
                            <Link
                              to="#"
                              className="icon edit"
                              onClick={() => {
                                handleAddShow(ad);
                              }}
                            >
                              <img src={Article_Edit} alt="ad_creative" />
                            </Link>
                            <Link
                              to="#"
                              className="icon edit text-end ms-3"
                              onClick={() => {
                                setDeleteModal(true);
                                setAdId(ad._id);
                              }}
                            >
                              <img
                                src={redDelete}
                                className="text-end"
                                alt=""
                              />
                            </Link>
                          </>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          handleViewShow();

                          setAdCampaign({
                            ...adCampaign,
                            id: ad._id,
                          });
                        }}
                      >
                        <div className="img">
                          <img
                            src={
                              !ad.adCreatives ? Article_Edit : ad.adCreatives
                            }
                            alt="article_image"
                          />
                        </div>
                        <div className="profile-suggest-card-wrapper">
                          <label htmlFor="" className="name">
                            Campaign Name : {ad.campaignName}
                          </label>

                          <div>
                            <label htmlFor="" className="name">
                              From :{" "}
                              {new Date(ad.startDate).toLocaleDateString(
                                "en-GB"
                              )}
                            </label>
                          </div>

                          <div>
                            <label htmlFor="" className="name">
                              Description :
                            </label>
                            <p className="single-line-elipsis">
                              {ad.campaignDescription.slice(0, 40)}
                            </p>
                            {/* <p className="single-line-elipsis" dangerouslySetInnerHTML={{ __html: truncatedContent }} /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </main>
  );
};

export default AdCampaigns;
