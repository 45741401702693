import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
//Datatable Modules
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
const UserVarification = () => {
  const { sidebar } = useContext(AppContext);

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const navigate = useNavigate();

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">User Verification</label>
        </div>
      </div>

      <div className="table-wrapper">
        <div className="search-wrapper row">
          <Form.Group className="mb-3 col-3" controlId="formBasicEmail">
            <Form.Control type="text" placeholder="Search" />
          </Form.Group>
        </div>
        <div className="main-data-wrapper">
          <Table class="display" striped bordered hover>
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Name</th>
                <th>Gender</th>
                <th>Mobile No.</th>
                <th>Block/Unblock</th>
              </tr>
            </thead>
            <tbody>
              <tr onClick={() => navigate("/ViewUsers")}>
                <td>1</td>
                <td>Ronaldo</td>
                <td>Male</td>
                <td>9945786248</td>
                <td>
                  <button className="block-btn">Block</button>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>Ronaldo</td>
                <td>Male</td>
                <td>9945786248</td>
                <td>
                  <button className="unblock-btn">Unblock</button>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>Ronaldo</td>
                <td>Male</td>
                <td>9945786248</td>
                <td>
                  <button className="block-btn">Block</button>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>Ronaldo</td>
                <td>Male</td>
                <td>9945786248</td>
                <td>
                  <button className="unblock-btn">Unblock</button>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>Ronaldo</td>
                <td>Male</td>
                <td>9945786248</td>
                <td>
                  <button className="block-btn">Block</button>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>Ronaldo</td>
                <td>Male</td>
                <td>9945786248</td>
                <td>
                  <button className="unblock-btn">Unblock</button>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="pagination-wrapper">
          <div className="entry-number">
            <label htmlFor="">Showing 1 - 10 of 200</label>
          </div>
          <Pagination>
            <Pagination.First />
            <Pagination.Prev />
            <Pagination.Item>{1}</Pagination.Item>
            <Pagination.Ellipsis />

            <Pagination.Item>{10}</Pagination.Item>
            <Pagination.Item>{11}</Pagination.Item>
            <Pagination.Item active>{12}</Pagination.Item>
            <Pagination.Item>{13}</Pagination.Item>

            <Pagination.Ellipsis />
            <Pagination.Item>{20}</Pagination.Item>
            <Pagination.Next />
            <Pagination.Last />
          </Pagination>
        </div>
      </div>
    </main>
  );
};

export default UserVarification;
