import React, { createContext, useContext, useMemo } from "react";
import * as RechartsPrimitive from "recharts";
import "../../assets/styles/chart.css";

const ChartContext = createContext(null);

function useChart() {
  const context = useContext(ChartContext);
  if (!context) {
    throw new Error("useChart must be used within a <ChartContainer />");
  }
  return context;
}

const ChartContainer = React.forwardRef((props, ref) => {
  const { id, className, children, config, ...rest } = props;
  const uniqueId = React.useId();
  const chartId = `chart-${id || uniqueId.replace(/:/g, "")}`;

  const containerStyle = {
    display: "flex",
    aspectRatio: "16 / 9", // Equivalent to aspect-video in Tailwind
    justifyContent: "center",
    fontSize: "0.75rem", // Equivalent to text-xs in Tailwind
  };

  // Add more styles as needed
  const additionalStyles = {
    fill: "#ccc", // Replace as needed
    stroke: "#ccc", // Replace as needed
    outline: "none",
  };

  return (
    <ChartContext.Provider value={{ config }}>
      <div
        data-chart={chartId}
        ref={ref}
        style={{ ...containerStyle, ...additionalStyles }}
        {...rest}
      >
        <ChartStyle id={chartId} config={config} />
        <RechartsPrimitive.ResponsiveContainer>
          {children}
        </RechartsPrimitive.ResponsiveContainer>
      </div>
    </ChartContext.Provider>
  );
});
ChartContainer.displayName = "Chart";

const ChartStyle = ({ id, config }) => {
  const colorConfig = Object.entries(config).filter(
    ([_, config]) => config.color
  );

  if (!colorConfig.length) {
    return null;
  }

  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
[data-chart=${id}] {
${colorConfig
  .map(([key, itemConfig]) => {
    const color = itemConfig.color;
    return color ? `  --color-${key}: ${color};` : null;
  })
  .join("\n")}
}
`,
      }}
    />
  );
};

const ChartTooltip = RechartsPrimitive.Tooltip;

const ChartTooltipContent = React.forwardRef((props, ref) => {
  const {
    active,
    payload,
    className,
    indicator = "dot",
    hideLabel = false,
    hideIndicator = false,
    label,
    labelFormatter,
    labelClassName,
    formatter,
    color,
    nameKey,
    labelKey,
  } = props;

  const { config } = useChart();

  const tooltipLabel = React.useMemo(() => {
    if (hideLabel || !payload?.length) {
      return null;
    }

    const [item] = payload;
    const key = `${labelKey || item.dataKey || item.name || "value"}`;
    const itemConfig = getPayloadConfigFromPayload(config, item, key);
    const value =
      !labelKey && typeof label === "string"
        ? config[label]?.label || label
        : itemConfig?.label;

    if (labelFormatter) {
      return React.createElement("div", {
        className: `font-medium ${labelClassName}`,
        children: labelFormatter(value, payload),
      });
    }

    if (!value) {
      return null;
    }

    return React.createElement("div", {
      className: `font-medium ${labelClassName}`,
      children: value,
    });
  }, [
    label,
    labelFormatter,
    payload,
    hideLabel,
    labelClassName,
    config,
    labelKey,
  ]);

  if (!active || !payload?.length) {
    return null;
  }

  const nestLabel = payload.length === 1 && indicator !== "dot";

  const containerStyle = {
    display: "grid",
    minWidth: "9rem",
    gap: "1.2rem",
    borderRadius: "0.5rem",
    border: "1px solid rgba(0,0,0,0.1)",
    backgroundColor: "#fff",
    padding: "0.7rem",
    boxShadow: "0 0.5rem 1rem rgba(0,0,0,0.1)",
  };

  const indicatorStyle = {
    height: indicator === "dot" ? "1rem" : undefined,
    width: indicator === "dot" ? "1rem" : "0",
    border: indicator === "dashed" ? "1.5px dashed" : undefined,
    backgroundColor: color,
    borderColor: color,
    margin: nestLabel && indicator === "dashed" ? "0.5rem 0" : undefined,
  };

  return React.createElement(
    "div",
    { ref, className, style: containerStyle },
    !nestLabel ? tooltipLabel : null,
    React.createElement(
      "div",
      { style: { display: "grid", gap: "1.5rem" } },
      payload.map((item, index) => {
        const key = `${nameKey || item.name || item.dataKey || "value"}`;
        const itemConfig = getPayloadConfigFromPayload(config, item, key);
        const indicatorColor = color || item.payload.fill || item.color;

        return React.createElement(
          "div",
          {
            key: item.dataKey,
            style: {
              display: "flex",
              alignItems: indicator === "dot" ? "center" : undefined,
              gap: "2rem",
            },
          },
          formatter && item?.value !== undefined && item.name
            ? formatter(item.value, item.name, item, index, item.payload)
            : [
                itemConfig?.icon
                  ? React.createElement(itemConfig.icon)
                  : !hideIndicator &&
                    React.createElement("div", {
                      style: {
                        ...indicatorStyle,
                        backgroundColor: indicatorColor,
                        borderColor: indicatorColor,
                      },
                    }),
                React.createElement(
                  "div",
                  {
                    style: {
                      display: "flex",
                      flex: 1,
                      justifyContent: "space-between",
                      alignItems: nestLabel ? "end" : "center",
                    },
                  },
                  React.createElement(
                    "div",
                    { style: { display: "grid", gap: "1.5rem" } },
                    nestLabel ? tooltipLabel : null,
                    React.createElement(
                      "span",
                      { style: { color: "#888" } },
                      itemConfig?.label || item.name
                    )
                  ),
                  item.value &&
                    React.createElement(
                      "span",
                      {
                        style: {
                          fontFamily: "monospace",
                          fontWeight: "bold",
                          color: "#000",
                        },
                      },
                      item.value.toLocaleString()
                    )
                ),
              ]
        );
      })
    )
  );
});

ChartTooltipContent.displayName = "ChartTooltip";

// Helper to extract item config from a payload.
function getPayloadConfigFromPayload(config, payload, key) {
  if (typeof payload !== "object" || payload === null) {
    return undefined;
  }

  const payloadPayload =
    "payload" in payload &&
    typeof payload.payload === "object" &&
    payload.payload !== null
      ? payload.payload
      : undefined;

  let configLabelKey = key;

  if (key in payload && typeof payload[key] === "string") {
    configLabelKey = payload[key];
  } else if (
    payloadPayload &&
    key in payloadPayload &&
    typeof payloadPayload[key] === "string"
  ) {
    configLabelKey = payloadPayload[key];
  }

  return configLabelKey in config ? config[configLabelKey] : config[key];
}

export { ChartContainer, ChartTooltip, ChartTooltipContent };
