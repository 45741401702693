import React, { useContext } from "react";
import { AppContext } from "./../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Form from "react-bootstrap/Form";
const GoogleAnalytics = () => {
  const { sidebar } = useContext(AppContext);

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="wrapper-push-notification">
        <div className="row main-section">
          <div className="col-md-6 left-section">
            <h4>Step by step working</h4>
            <div class="container">
              {/* <!-- completed --> */}
              <div class="step active">
                <div class="v-stepper">
                  <div class="circle"></div>
                  <div class="line"></div>
                </div>

                <div class="content">
                  <h5>Quick Start</h5>
                  <p>
                    The Google Analytics Data API v1 gives you programmatic
                    access to Google Analytics 4 (GA4) report data.
                  </p>
                </div>
              </div>

              {/* <!-- active --> */}
              <div class="step active">
                <div class="v-stepper">
                  <div class="circle"></div>
                  <div class="line"></div>
                </div>

                <div class="content">
                  <h5>Enable the API</h5>
                  <p>
                    Access your Google Analytics dashboard from here click.
                    Select your project and move to APIs and Services section ib
                    left tab and open Enable APIs and Services. Click on + sign
                    to enable apis. Enable the google analytics api, reporting
                    api, data api.
                  </p>
                </div>
              </div>

              <div class="step active">
                <div class="v-stepper">
                  <div class="circle"></div>
                  <div class="line"></div>
                </div>

                <div class="content">
                  <h5>Create Service Account</h5>
                  <p>
                    After this go to the admin section at the bottom of left
                    tab, and select service account option. Now click on + sign
                    to create service account, add the required fields like
                    name. Then grant this account role and done the process.
                  </p>
                </div>
              </div>

              <div class="step active">
                <div class="v-stepper">
                  <div class="circle"></div>
                  <div class="line"></div>
                </div>

                <div class="content">
                  <h5>Download Credentials File</h5>
                  <p>
                    Go to the APIs and services section and look for credentials
                    section. Scroll down and select the service account that you
                    have created. Select key tab and add new key from ADD KEY
                    option and select JSON option. Downloading will get started
                    for credentials.json file.
                  </p>
                </div>
              </div>

              <div class="step active">
                <div class="v-stepper">
                  <div class="circle"></div>
                  <div class="line"></div>
                </div>

                <div class="content">
                  <h5>Add User to Analytics Dashboard</h5>
                  <p>
                    Go to the admin section at the bottom of left tab, select
                    property access management and from + option on right top
                    corner add user with the email address associated with
                    client_email in the downloaded json file. From same tab you
                    can access property settings from where you can get property
                    id.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 right-section">
            <h4>Google Analytics Settings</h4>
            <div className="mt-5">
              <div className="mb-4">
                <Form.Label>Property ID</Form.Label>
                <Form.Control required type="text" />
                <Form.Control.Feedback
                  type="invalid"
                  placeholder="Email Address"
                >
                  Please Enter Your Email.
                </Form.Control.Feedback>
              </div>

              <div className="mb-5">
                <Form.Label>Credentials JSON File</Form.Label>
                <Form.Control required type="file" />
                <Form.Control.Feedback type="invalid">
                  Please Enter your password.
                </Form.Control.Feedback>
              </div>
              <div className="text-end">
                <button>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default GoogleAnalytics;
