import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
//Datatable Modules
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import passport from "../assets/images/passport.jpg";
import sara from "../assets/images/sara.svg";
import ic_eyes from "../assets/images/ic_eyes.svg";
import Modal from "react-bootstrap/Modal";
import Pagination from "react-bootstrap/Pagination";
import {
  PostCallWithErrorResponse,
  postWithAuthCall,
  simpleGetCall,
} from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { ToastMsg } from "./ToastMsg";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import moment from "moment";
import { Button, InputGroup } from "react-bootstrap";

const ProfileSuggestion = () => {
  const { sidebar, userData } = useContext(AppContext);
  const [showSuggestModel, setShowSuggestModel] = useState(false);
  const handleCloseSuggestModel = () => setShowSuggestModel(false);
  const [membershipTiers, setMembershipTiers] = useState([]);
  const [planUsers, setPlanUsers] = useState([]);
  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [approvedUsers, setApprovedUsers] = useState([]);

  const handleSearch = (query) => {
    setIsLoading(true);

    simpleGetCall(
      `${ApiConfig.GET_SUBSCRIPTION_PLAN_MEMBERS}/${selectedPlan}?name=${query}`
    )
      .then((response) => {
        if (response.data) setPlanUsers(response?.data);
        setIsLoading(false);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (selectedPlan)
      simpleGetCall(
        `${ApiConfig.GET_SUBSCRIPTION_PLAN_MEMBERS}/${selectedPlan}`
      )
        .then((response) => {
          if (response.data) setPlanUsers(response?.data);
        })
        .catch((error) => {});
  }, [selectedPlan]);

  useEffect(() => {
    if (selectedUser) {
      simpleGetCall(
        `${ApiConfig.GET_UNIQUE_USER_BY_USERID}/${selectedUser.subscriptionUserId}`
      )
        .then((response) => {
          if (response.data) setSuggestedUsers(response?.data);
        })
        .catch((error) => {});
    }
  }, [selectedUser]);

  //Plan Array//

  useEffect(() => {
    simpleGetCall(ApiConfig.GET_ALL_SUBSCRIPTION_PLANS)
      .then((res) => {
        console.log("GET_ALL_SUBSCRIPTION_PLANS", res.data);
        setMembershipTiers(res.data?.plans ?? []);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const isUserSuggested = (userId) => {
    return approvedUsers.some((user) => user._id === userId);
  };

  const persistSuggestedList = () => {
    const payload = {
      suggestedUsers: approvedUsers.map((u) => u._id),
      plan: selectedPlan,
      subscription: selectedUser.subscriptionId,
      user: selectedUser.subscriptionUserId,
    };
    postWithAuthCall(
      `${ApiConfig.PERSIST_SUGGESTED_USERS_PREMIUM}`,
      JSON.stringify(payload)
    )
      .then((response) => {
        if (response) {
          if (response.error) {
            ToastMsg("error", response.message);
          } else {
            ToastMsg("success", response.message);
          }
          handleCloseSuggestModel();
        }
      })
      .catch((error) => {
        console.log(error);
        handleCloseSuggestModel();
        ToastMsg("error", error.message);
      });
  };
  console.log("render", {
    membershipTiers,
    selectedPlan,
    planUsers,
    selectedUser,
    suggestedUsers,
  });
  const filterBy = () => true;
  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Profile Suggestion</label>
        </div>
      </div>

      <Modal
        show={showSuggestModel}
        onHide={handleCloseSuggestModel}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">
              Are you sure you want to Suggest following users to{" "}
              {selectedUser?.fullName}?
            </label>

            <ol className="mt-3 text-start overflow-auto cx-vh50 pe-4">
              {approvedUsers.map((user, index) => (
                <li key={`approved-users-${index}`}>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Recipient's username"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      readOnly
                      value={user.fullName}
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={() => {
                        setApprovedUsers((prevState) => {
                          return prevState.filter((u) => u._id !== user._id);
                        });
                      }}
                    >
                      Remove
                    </Button>
                  </InputGroup>
                </li>
              ))}
            </ol>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button className="me-2 bg-danger" onClick={persistSuggestedList}>
              Yes
            </button>
            <Link to="#" className="" onClick={handleCloseSuggestModel}>
              No
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
      {/* ======================= END DELETE ========================== */}

      <div className="documents-wrapper">
        <div className="search-wrapper row">
          <Form.Group className=" col-md-4" controlId="formBasicEmail">
            <Form.Label className="label-select">Select Plan</Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => {
                setSelectedPlan(e.target.value);
              }}
            >
              {membershipTiers.length > 0 &&
                membershipTiers.map((plan) => {
                  return <option value={plan.title}>{plan.title}</option>;
                })}
            </Form.Select>
          </Form.Group>
          <Form.Group className=" col-md-4" controlId="formBasicEmail">
            <Form.Label className="label-select">Select User</Form.Label>
            <AsyncTypeahead
              filterBy={filterBy}
              id="async-example"
              isLoading={isLoading}
              disabled={!selectedPlan}
              labelKey="subscriptionUser"
              useCache={false}
              onSearch={handleSearch}
              minLength={0}
              options={planUsers}
              placeholder="Type name to search users"
              onChange={(users) => {
                setSelectedUser(users[0]);
              }}
              renderMenuItemChildren={(option) => (
                <>
                  <span>{option.subscriptionUser}</span>
                </>
              )}
            />
          </Form.Group>
          <div className="buttons-wrapper mt-auto col-md-4">
            <button
              className={`btn ${
                approvedUsers.length === 0 ? "bg-gradient" : "bg-success"
              } text-white me-2`}
              disabled={approvedUsers.length === 0}
              onClick={() => setShowSuggestModel(true)}
            >
              Suggest Selected Users
            </button>
          </div>
        </div>
        <hr />
        <div className="row g-3 inner-wrapper">
          {suggestedUsers.length > 0 &&
            suggestedUsers.map((user) => {
              return (
                <div className="col-lg-3">
                  <div className="document-cards">
                    <div className="img">
                      <img
                        src={!user.photo_path ? sara : user.photo_path}
                        alt=""
                      />
                    </div>
                    <div className="profile-suggest-card-wrapper">
                      <label htmlFor="" className="name">
                        {user.fullName} ,
                      </label>
                      <label htmlFor="" className="age">
                        {`${moment().diff(
                          moment(user.dateOfBirth),
                          "years"
                        )} Years`}
                      </label>
                      <div>
                        <div className="heights">{user.height}"</div>
                        <div className="heights">
                          {user?.employee?.jobTitle}
                        </div>
                        <div className="heights">
                          {user?.employee?.location}
                        </div>
                      </div>
                    </div>
                    <div className="buttons-wrapper mt-3">
                      <button
                        className={`btn ${
                          !isUserSuggested(user._id)
                            ? "bg-success"
                            : "bg-danger"
                        } text-white me-2`}
                        onClick={(e) => {
                          if (!isUserSuggested(user._id)) {
                            setApprovedUsers((prevState) => [
                              ...prevState,
                              user,
                            ]);
                          } else {
                            setApprovedUsers((prevState) => {
                              return prevState.filter(
                                (u) => u._id !== user._id
                              );
                            });
                          }
                        }}
                      >
                        {!isUserSuggested(user._id) ? "Suggest" : "Remove"}
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </main>
  );
};

export default ProfileSuggestion;
