import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
//Datatable Modules
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import ic_delete from "../assets/images/ic_delete.svg";
import ic_edit from "../assets/images/ic_edit.svg";
import ic_eyes from "../assets/images/ic_eyes.svg";
import Loader from "./Loader";
import Modal from "react-bootstrap/Modal";
import {
  DeleteCallWithErrorResponse,
  PostCallWithErrorResponse,
  simpleGetCall,
} from "../api/ApiServices";
import ApiConfig, { BASE_URL } from "../api/ApiConfig";
import { ToastMsg } from "./ToastMsg";
import Pagination from "./Pagination";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Admin = () => {
  const [adminList, setAdminList] = useState([]);

  const { sidebar, useDebounce, userData } = useContext(AppContext);
  localStorage.setItem("IssuperAdmin", "true");
  const userDataLoc = localStorage.getItem("IssuperAdmin");

  const [search, setSearch] = useState("");
  const [add, setAdd] = useState(false);
  const [admin, setAdmin] = useState();
  const [viewAdmin, setViewAdmin] = useState({});
  const [loading, setLoading] = useState(false);
  const [viewModal, setView] = useState(false);
  const handleViewClose = () => setView(false);

  const [currrentpage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState();

  const handleViewShow = (admin) => {
    console.log("handleViewShow", admin);
    setViewAdmin({
      adminName: admin.full_name,
      mailId: admin.username,
      adminPass: admin.password,
      contactNo: admin.mobile_number,
      canActiveUser: admin.is_active,
      canVerifyUserDocs: admin.is_document_verification,
      canBlockUser: admin.is_block_user,
      canDeleteUser: admin.is_delete_user,
      canSuggestProfile: admin.is_profile_suggestion,
      canCreateArticles: admin.is_article_updation,
      is_super_admin: admin.is_super_admin,
      adminId: admin._id,
    });
    setView(true);
  };

  const handleAddClose = () => {
    setAdd(false);
  };

  const [validated, setValidated] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addAdmin, setAddAdmin] = useState({
    adminName: "",
    mailId: "",
    adminPass: "",
    contactNo: "",
    canActiveUser: true,
    canVerifyUserDocs: false,
    canBlockUser: false,
    canDeleteUser: false,
    canSuggestProfile: false,
    canCreateArticles: false,
    is_super_admin: false,
    adminId: "",
  });

  const handleAddShow = (admin) => {
    console.log("admin", admin);
    let id = admin._id;
    if (id) {
      console.log("handleAddShow", id);
      console.log("admn", admin.full_name);
      setAddAdmin({
        adminName: admin.full_name,
        mailId: admin.username,
        adminPass: admin.password,
        contactNo: admin.mobile_number,
        canActiveUser: admin.is_active,
        canVerifyUserDocs: admin.is_document_verification,
        canBlockUser: admin.is_block_user,
        canDeleteUser: admin.is_delete_user,
        canSuggestProfile: admin.is_profile_suggestion,
        canCreateArticles: admin.is_article_updation,
        is_super_admin: admin.is_super_admin,
        adminId: admin._id,
      });
      handleAddModalOpen();
    } else {
      console.log("handleAddShow2");
      setAdd(true);
      setAddAdmin({
        adminName: "",
        mailId: "",
        adminPass: "",
        contactNo: "",
        canActiveUser: true,
        canVerifyUserDocs: false,
        canBlockUser: false,
        canDeleteUser: false,
        canSuggestProfile: false,
        canCreateArticles: false,
        is_super_admin: false,
        adminId: "",
      });
    }
  };

  const handleAddModalOpen = () => {
    console.log("handleAddModalOpen", addAdmin);
    setAdd(true);
  };
  const [errMsg, setErrMsg] = useState({
    adminName: "",
    mailId: "",
    adminPass: "",
    contactNo: "",
  });

  //paginate react

  const searchedDataPageCounts =
    adminList &&
    adminList.length &&
    adminList.filter((item) =>
      item.ProjectName?.toLowerCase().includes(search)
    );

  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    getAdminListApi(debouncedSearchTerm, currrentpage);
    getAllAdminListApi(debouncedSearchTerm, currrentpage);
  }, [debouncedSearchTerm]);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected + 1);
    getAdminListApi("", selected + 1);
  };

  const getAdminListApi = (search, currrentpage) => {
    setLoading(true);
  };

  const getAllAdminListApi = (search, currrentpage) => {
    setLoading(true);
    simpleGetCall(
      BASE_URL + `/admin/list-users?page=${currrentpage}&search=${search}`
    ).then((res) => {
      if (!res.error) {
        setLoading(false);
        setAdminList(res.data);
        if (res.data.length) {
          const totalPage = Number(res.total_pages);
          setTotalPages(totalPage);
          setTotalPages(1);
          setCurrentPage(1);
          setTotalCount(res.data.length);
          setLastPage(res.last_page);
        }
      } else {
        setAdminList([]);
      }
    });
  };

  //View Admin Api
  const getEditAdminApi = (id) => {
    setLoading(true);
    simpleGetCall(
      `https://api.marryem.com:3001/admin/admin-details?adminId=${id}`
    ).then((response) => {
      console.log("response", response);
      setLoading(false);
      let res = response.data[0];
      setAddAdmin({
        adminName: res.full_name,
        mailId: res.mail_id,
        contactNo: res.contact_no,
        adminPass: res.password,
        canActiveUser: res.is_user_activation,
        canBlockUser: false,
        canVerifyUserDocs: res.is_user_document_verified,
        canSuggestProfile: res.is_user_profile_suggested,
        canCreateArticles: res.is_user_create_articals,
        canDeleteUser: res.is_user_delete_user,
        canChat: res.is_user_chats,
        adminId: res.admin_id,
      });
    });
  };

  //View Admin Api
  const getViewAdminApi = (id) => {
    simpleGetCall(
      `https://api.marryem.com:3001/admin/admin-details?adminId=${id}`
    ).then((response) => {
      if (response.success) setViewAdmin(response.data[0]);
    });
  };

  // ADD Admin API
  const addAdminApi = async (e) => {
    e.preventDefault();
    const regex = /^[a-zA-Z0-9.]+@(?:[a-zA-Z0-9.]+\.)+[A-Za-z]+$/;
    if (addAdmin.adminName === "") {
      setErrMsg({ ...errMsg, adminName: "Please Enter Admin Name " });
      return;
    }
    if (addAdmin.contactNo.trim().length === 0) {
      setErrMsg({ ...errMsg, contactNo: "Enter Valid contactNo " });
      return;
    }
    if (!regex.test(addAdmin.mailId)) {
      setErrMsg({ ...errMsg, mailId: " Please Enter valid Email Address" });
      return;
    }
    if (addAdmin.adminPass > 0) {
      setErrMsg({ ...errMsg, adminPass: "Pleae Enter Password " });
      return;
    } else {
      const response = await PostCallWithErrorResponse(
        ApiConfig.ADD_UPDATE_ADMIN,
        JSON.stringify(addAdmin)
      );
      console.log("response", response);
      console.log("res2r", response.response);
      if (response.error) {
        console.log("error", response.error);
        ToastMsg("error", response.json.message);
      } else if (response.response.ok == true) {
        ToastMsg("success", response.json.message);
        handleAddClose();
        getAllAdminListApi(search, currrentpage);
        // getAdminListApi(search, currrentpage);
      }
    }
    // setValidated(true);
  };

  //   Update Admin API
  const updateAdminApi = async (e) => {
    e.preventDefault();
    const regex = /^[a-zA-Z0-9.]+@(?:[a-zA-Z0-9.]+\.)+[A-Za-z]+$/;
    if (addAdmin.adminName === "") {
      setErrMsg({ ...errMsg, adminName: "Please Enter Admin Name " });
      return;
    }
    if (addAdmin.contactNo.trim().length === 0) {
      setErrMsg({ ...errMsg, contactNo: "Enter Valid contactNo " });
      return;
    }
    if (!regex.test(addAdmin.mailId)) {
      setErrMsg({ ...errMsg, mailId: " Please Enter valid Email Address" });
      return;
    }
    if (addAdmin.adminPass > 0) {
      setErrMsg({ ...errMsg, adminPass: "Pleae Enter Password " });
      return;
    } else {
      const response = await PostCallWithErrorResponse(
        ApiConfig.UPDATE_ADMIN,
        JSON.stringify(addAdmin)
      );
      console.log("response", response);
      console.log("res2r", response.response);
      if (response.error) {
        console.log("error", response.error);
        ToastMsg("error", response.json.message);
      } else if (response.response.ok == true) {
        ToastMsg("success", response.json.message);
        handleAddClose();

        getAllAdminListApi(search, currrentpage);
      }
    }
  };

  // ADD adminDesdleteApid API
  const adminDeleteApi = () => {
    DeleteCallWithErrorResponse(
      BASE_URL + "/admin/deleteUser",
      JSON.stringify({ adminId: admin })
    ).then((response) => {
      console.log(response);
      if (response.error) {
        ToastMsg("error", response.json.message);
      } else if (response.response.ok == true) {
        ToastMsg("success", response.json.message);
        handleAddClose();
        // getAdminListApi(search, currrentpage);
        getAllAdminListApi(search, currrentpage);
      }
    });
  };

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Admin</label>
        </div>
        <div className={userDataLoc === "true" ? "add-btn" : "add-btnFalse"}>
          <button
            disabled={userDataLoc === "true" ? false : true}
            onClick={handleAddShow}
          >
            Add Admin
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path fill="white" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
            </svg>
          </button>
        </div>
      </div>
      {/* ======================= Add Admin ========================== */}
      <Modal
        show={add}
        onHide={handleAddClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {addAdmin.adminId ? "Edit Admin" : "Add Admin"}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={addAdminApi}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={addAdmin.adminName}
                  onChange={(e) => {
                    setAddAdmin({
                      ...addAdmin,
                      adminName: e.target.value,
                    });

                    setErrMsg({
                      ...errMsg,
                      adminName: "",
                    });
                  }}
                />

                {errMsg.adminName.length > 0 && (
                  <span className="text-danger text-center">
                    {errMsg.adminName}
                  </span>
                )}
                <Form.Control.Feedback type="invalid">
                  Please Enter Name
                </Form.Control.Feedback>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Label>Mobile No.</Form.Label>
                <PhoneInput
                  country={"in"}
                  value={addAdmin.contactNo}
                  onChange={(phone) =>
                    setAddAdmin({ ...addAdmin, contactNo: phone })
                  }
                />

                <Form.Control.Feedback type="invalid">
                  Please Enter Mobile No.
                </Form.Control.Feedback>
                {errMsg.contactNo.length > 0 && (
                  <span className="text-danger text-center">
                    {errMsg.contactNo}
                  </span>
                )}
              </div>

              <div className="col-md-6 mb-3">
                <Form.Label>Email ID</Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={addAdmin.mailId}
                  onChange={(e) => {
                    setAddAdmin({
                      ...addAdmin,
                      mailId: e.target.value,
                    });
                    setErrMsg({
                      ...errMsg,
                      mailId: "",
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Email ID.
                </Form.Control.Feedback>
                {errMsg.mailId.length > 0 && (
                  <span className="text-danger text-center">
                    {errMsg.mailId}
                  </span>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  required
                  maxLength={12}
                  minLength={12}
                  value={addAdmin.adminPass}
                  onChange={(e) => {
                    setAddAdmin({
                      ...addAdmin,
                      adminPass: e.target.value,
                    });
                    setErrMsg({
                      ...errMsg,
                      adminPass: "",
                    });
                  }}
                />
                {errMsg.adminPass.length > 0 && (
                  <span className="text-danger text-center">
                    {errMsg.adminPass}
                  </span>
                )}
                <Form.Control.Feedback type="invalid">
                  Please Enter Password.
                </Form.Control.Feedback>
              </div>
            </div>
            {userDataLoc === "true" ? (
              <div className="row">
                <h5 className="access-heading">Admin Access</h5>
                <div className="col-md-6 mb-3">
                  <Form.Check
                    type="checkbox"
                    id="checkbox1"
                    label="Documents Verification"
                    checked={addAdmin.canVerifyUserDocs ? true : false}
                    onChange={(e) => {
                      setAddAdmin({
                        ...addAdmin,
                        canVerifyUserDocs: e.target.checked,
                      });
                    }}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <Form.Check
                    id="checkbox2"
                    type="checkbox"
                    label="Profile Suggetion"
                    checked={addAdmin.canSuggestProfile ? true : false}
                    onChange={(e) => {
                      setAddAdmin({
                        ...addAdmin,
                        canSuggestProfile: e.target.checked,
                      });
                    }}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <Form.Check
                    id="checkbox3"
                    type="checkbox"
                    label="User Block "
                    checked={addAdmin.canBlockUser ? true : false}
                    onChange={(e) => {
                      setAddAdmin({
                        ...addAdmin,
                        canBlockUser: e.target.checked,
                      });
                    }}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <Form.Check
                    type="checkbox"
                    id="checkbox4"
                    label="Articles Updation"
                    checked={addAdmin.canCreateArticles ? true : false}
                    onChange={(e) => {
                      setAddAdmin({
                        ...addAdmin,
                        canCreateArticles: e.target.checked,
                      });
                    }}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <Form.Check
                    type="checkbox"
                    id="checkbox5"
                    label="Delete User"
                    checked={addAdmin.canDeleteUser ? true : false}
                    onChange={(e) => {
                      setAddAdmin({
                        ...addAdmin,
                        canDeleteUser: e.target.checked,
                      });
                    }}
                  />
                </div>
              </div>
            ) : null}
            <div className="buttons-modal border-top pt-3 text-end" required>
              <Link to="#" className=" bg-danger" onClick={handleAddClose}>
                Cancel
              </Link>
              {addAdmin.adminId ? (
                <button className="ms-2" type="submit" onClick={updateAdminApi}>
                  Edit
                </button>
              ) : (
                <button className="ms-2" type="submit" onClick={addAdminApi}>
                  Save
                </button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END ADD ========================== */}

      {/* ======================= DELETE ========================== */}

      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              type="submit"
              onClick={() => {
                adminDeleteApi();
                setDeleteModal(false);
              }}
            >
              Delete
            </button>
            <Link
              to="#"
              className=""
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
      {/* ======================= END DELETE ========================== */}

      {/* ======================= VIEW ========================== */}
      <Modal
        show={viewModal}
        onHide={handleViewClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Admin Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Name
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewAdmin.adminName}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Phone No.
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewAdmin.contactNo}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Email ID.
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewAdmin.mailId}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Status
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewAdmin.canActiveUser == true ? "Active" : "InActive"}
                  </label>
                </div>
              </div>
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <Link to="#" className="bg-danger" onClick={handleViewClose}>
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END VIEW ========================== */}

      <div className="table-wrapper">
        <div className="search-wrapper row">
          <Form.Group className="mb-3 col-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              placeholder="Search"
              onChange={(e) => {
                setSearch(e.target.value);
                setCurrentPage(1);
              }}
            />
          </Form.Group>
        </div>
        {loading ? (
          <div style={{ marginTop: "150px" }}>
            <Loader />
          </div>
        ) : (
          <>
            <div className="main-data-wrapper">
              <Table class="display" striped bordered hover>
                <thead>
                  <tr>
                    <th> </th>
                    <th>Name</th>
                    <th>User Name</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {adminList && adminList.length > 0 ? (
                    adminList.map((admin, index) => {
                      return (
                        <tr key={"admin" + index}>
                          <td>{(currrentpage - 1) * 10 + index + 1} </td>
                          <td>{admin.full_name}</td>
                          <td>{admin.username}</td>
                          <td>
                            <span
                              className={
                                admin.is_active
                                  ? "active-status"
                                  : "inactive-status"
                              }
                            >
                              {admin.is_active === true ? (
                                <span>Active</span>
                              ) : (
                                <span>InActive</span>
                              )}
                            </span>
                          </td>
                          <td className="action">
                            {/* icon edit start  */}
                            <span>
                              {userDataLoc === "true" ||
                              admin._id == userData.AdminID ? (
                                <Link
                                  to="#"
                                  className="icon edit"
                                  onClick={() => {
                                    handleAddShow(admin);
                                  }}
                                >
                                  <img src={ic_edit} alt="" />
                                </Link>
                              ) : null}
                            </span>

                            {/* icon edit End */}

                            {/* icon view Start */}
                            {userDataLoc === "true" ||
                            admin._id == userData.AdminID ? (
                              <Link
                                to="#"
                                className="icon view"
                                onClick={() => {
                                  handleViewShow(admin);
                                }}
                              >
                                <img src={ic_eyes} alt="" />
                              </Link>
                            ) : null}

                            {userDataLoc === "true" && (
                              <Link
                                to="#"
                                className="icon delete"
                                onClick={() => {
                                  setAdmin(admin._id);
                                  setDeleteModal(true);
                                }}
                              >
                                <img src={ic_delete} alt="" />
                              </Link>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        <div className=" justify-content-center align-items-center text-danger text-center mt-3">
                          Data Not Found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            {adminList.length > 0 ? (
              <div className="pagination-wrapper">
                <div className="entry-number">
                  Showing {(currrentpage - 1) * 10 + 1} -{" "}
                  {lastPage == true ? totalCount : currrentpage * 10} of{" "}
                  {totalCount}
                </div>

                <Pagination
                  data={adminList}
                  pageChangeHandler={pageChangeHandler}
                  // usersPerPage={usersPerPage}
                  currentPage={currrentpage}
                  searchValue={search}
                  totalPages={totalPages}
                  searchedDataPageCounts={searchedDataPageCounts}
                />
              </div>
            ) : null}
          </>
        )}
      </div>
    </main>
  );
};

export default Admin;
