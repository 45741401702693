import React from "react";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "./components/chart";
import '../assets/styles/variable.css';
import '../assets/styles/chart.css';

const chartConfig = {
  users: {
    label: "Users",
    color: "hsl(var(--chart-1))",
  },
};

export function BarChartComponent({ data, xAxisKey, yAxisKey, tickFormatter }) {
  return (
    <ChartContainer config={chartConfig}>
      <BarChart accessibilityLayer data={data}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey={xAxisKey}
          tickLine={false}
          tickMargin={10}
          axisLine={false}
          tickFormatter={tickFormatter}
        />
        <YAxis tickLine={false} tickMargin={10} />
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent hideLabel />}
        />
        <Bar dataKey={yAxisKey} fill="var(--chart-1)" radius={8} barSize={30} />
      </BarChart>
    </ChartContainer>
  );
}
