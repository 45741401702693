import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { Form, Modal, Table } from "react-bootstrap";
import {
  DeleteCallWithErrorResponse,
  multipartPostCallWithErrorResponse,
  PostCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../api/ApiServices";
import { BASE_URL } from "../api/ApiConfig";
import { ToastMsg } from "./ToastMsg";
import Article_Edit from "../assets/images/Article_Edit.svg";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import ic_delete from "../assets/images/ic_delete.svg";
import ic_edit from "../assets/images/ic_edit.svg";
import ic_eyes from "../assets/images/ic_eyes.svg";
import Pagination from "./Pagination";

const MarketingCampaigns = () => {
  const { sidebar, useDebounce, userData } = useContext(AppContext);
  const [campaignData, setCampaignData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [currrentpage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState();


  const [campaign, setCampaign] = useState({
    campaignName: "",
    campaignType: "",
    startDate: "",
    endDate: "",
    budget: null,
    platform: "",
    targetAudience: "",
    goals: "",
    keyMetrics: "",
    adCreatives: "",
    campaignDescription: "",
    conversionEvents: "",
    totalConversions: null,
    totalSpend: null,
    revenueGenerated: null,
    campaignId: "",
  });
  const [add, setAdd] = useState(false);
  const [validated, setValidated] = useState(false);
  const [viewCampaign, setViewCampaign] = useState();
  const [viewModal, setView] = useState(false);
  const [deleteCampaign, setdeleteCampaign] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  const handleViewClose = () => setView(false);

  useEffect(() => {
    getCampaignsApi();
  }, []);

  const searchedDataPageCounts =
    campaignData &&
    campaignData.length &&
    campaignData.filter((item) =>
      item.campaignName?.toLowerCase().includes(search)
    );


  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected + 1);
    getCampaignsApi("", selected + 1);
  };

  const handleViewShow = (marketingCampaign) => {
    setViewCampaign(marketingCampaign);
    setView(true);
  };

  const handleAddShow = (campaign) => {
    console.log("campaign", campaign);
    let id = campaign._id;

    if (id) {
      setCampaign({
        campaignName: campaign.campaignName,
        campaignType: campaign.campaignType,
        startDate: campaign.startDate ? campaign.startDate.split("T")[0] : "",
        endDate: campaign.endDate ? campaign.endDate.split("T")[0] : "",
        budget: campaign.budget,
        platform: campaign.platform,
        targetAudience: campaign.targetAudience,
        goals: campaign.goals,
        keyMetrics: campaign.keyMetrics,
        adCreatives: campaign.adCreatives,
        campaignDescription: campaign.campaignDescription,
        conversionEvents: campaign.conversionEvents,
        totalConversions: campaign.totalConversions,
        totalSpend: campaign.totalSpend,
        revenueGenerated: campaign.revenueGenerated,
        campaignId: campaign._id,
      });
    }
    setAdd(true);
  };

  const handleAddClose = () => {
    setAdd(false);
    setCampaign({
      campaignName: "",
      campaignType: "",
      startDate: "",
      endDate: "",
      budget: null,
      platform: "",
      targetAudience: "",
      goals: "",
      keyMetrics: "",
      adCreatives: "",
      campaignDescription: "",
      conversionEvents: "",
      totalConversions: null,
      totalSpend: null,
      revenueGenerated: null,
      campaignId: "",
    });
  };

  const getCampaignsApi = () => {
    simpleGetCallWithErrorResponse(`${BASE_URL}/campaign/all`)
      .then((response) => {
        console.log("response get campaigns", response);

        if (response.json.data) setCampaignData(response.json?.data);
        if(response.json.data.length){
          setTotalPages(1);
          setCurrentPage(1);
          setTotalCount(response.data.length);
          setLastPage(1);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        ToastMsg("error", error);
      });
  };
  //   add campaign
  const addCampaignApi = async (event) => {
    event.preventDefault();

    if (campaign.adCreatives instanceof File) {
      // If an image is selected, upload it using multipartPostCallWithErrorResponse
      const formData = new FormData();
      formData.append("file", campaign.adCreatives);

      multipartPostCallWithErrorResponse(
        `${BASE_URL}/utils/image-upload`,
        formData
      )
        .then((res) => {
          if (res.error) {
            ToastMsg("error", res.message);
          } else if (res.error === false) {
            ToastMsg("success", res.message);
            const imageurl = res.json.data.file_url;
            if (campaign.campaignId) {
              setCampaign({
                ...campaign,
                adCreatives: imageurl,
              });
              getUpdateCampaignApi(imageurl);
            } else {
              getAddCampaignApi(imageurl);
            }
          }
        })
        .catch((error) => console.error("Error:", error));
    } else {
      if (campaign.campaignId) {
        getUpdateCampaignApi(campaign.adCreatives);
      } else {
        getAddCampaignApi("");
      }
    }
  };

  const getAddCampaignApi = async (imageurl) => {
    console.log("campaign add", campaign);
    PostCallWithErrorResponse(
      `${BASE_URL}/campaign/create`,
      JSON.stringify({
        ...campaign,
        adCreatives: imageurl,
      })
    ).then((res) => {
      if (res.error) {
        ToastMsg("error", res.json.message);
      } else if (res.success === true) {
        ToastMsg("success", res.json.message);
      }
      getCampaignsApi();
      handleAddClose();
    });
  };

  const getUpdateCampaignApi = async (imageurl) => {
    const response = await PostCallWithErrorResponse(
      `${BASE_URL}/campaign/update`,
      JSON.stringify({
        ...campaign,
        adCreatives: imageurl,
      })
    );
    if (response.error) {
      ToastMsg("error", response.json.message);
    } else if (response.response.ok == true) {
      ToastMsg("success", response.json.message);
      getCampaignsApi();
      handleAddClose();
    }
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    setCampaign({
      ...campaign,
      adCreatives: file,
    });
  };

  const campaignDeleteApi = () => {
    DeleteCallWithErrorResponse(
      BASE_URL + "/campaign/delete",
      JSON.stringify({ campaignId: deleteCampaign })
    ).then((response) => {
      if (response.error) {
        ToastMsg("error", response.json.message);
      } else if (response.response.ok == true) {
        ToastMsg("success", response.json.message);
        handleAddClose();
        getCampaignsApi();
      }
    });
  };

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Marketin Campaign</label>
        </div>
        <div className="add-btn">
          <button onClick={handleAddShow}>
            Add Campaigns
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path fill="white" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
            </svg>
          </button>
        </div>
      </div>

      {/* ======================= Add Campaign ========================== */}
      <Modal
        show={add}
        onHide={handleAddClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {campaign.campaignId ? "Edit Campaign" : "Add Campaign"}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={addCampaignApi}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <Form.Label>Campaign Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  placeholder="Ex: Summer Love 2024"
                  value={campaign.campaignName}
                  onChange={(e) => {
                    setCampaign({
                      ...campaign,
                      campaignName: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Campaign Name
                </Form.Control.Feedback>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Label>Campaign Type</Form.Label>
                <Form.Control
                  type="text"
                  required
                  placeholder="Ex: Social Media"
                  value={campaign.campaignType}
                  onChange={(e) => {
                    setCampaign({
                      ...campaign,
                      campaignType: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Campaign Type
                </Form.Control.Feedback>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  required
                  value={campaign.startDate}
                  onChange={(e) => {
                    setCampaign({
                      ...campaign,
                      startDate: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Campaign Start Date
                </Form.Control.Feedback>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  required
                  value={campaign.endDate}
                  onChange={(e) => {
                    setCampaign({
                      ...campaign,
                      endDate: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Campaign End Date
                </Form.Control.Feedback>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Label>Budget</Form.Label>
                <Form.Control
                  type="number"
                  required
                  placeholder="Ex: 10000"
                  value={campaign.budget}
                  onChange={(e) => {
                    setCampaign({
                      ...campaign,
                      budget: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Budget
                </Form.Control.Feedback>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Label>Platform</Form.Label>
                <Form.Control
                  type="text"
                  // required
                  placeholder="Ex: Facebook"
                  value={campaign.platform}
                  onChange={(e) => {
                    setCampaign({
                      ...campaign,
                      platform: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Platform
                </Form.Control.Feedback>
              </div>

              <div className="col-md-12 mb-3">
                <Form.Label>Key Metrics</Form.Label>
                <Form.Control
                  type="text"
                  // required
                  placeholder="Ex: Impressions"
                  value={campaign.keyMetrics}
                  onChange={(e) => {
                    setCampaign({
                      ...campaign,
                      keyMetrics: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Key Metrics
                </Form.Control.Feedback>
              </div>

              <div className="col-md-12 mb-3">
                {campaign.campaignId ? (
                  <>
                    <Form.Label>Campaign Ad Creative</Form.Label>
                    {campaign?.adCreatives && (
                      <div className="edit-modal-img-box mb-3">
                        <img
                          className="article_img"
                          src={
                            !campaign.adCreatives
                              ? Article_Edit
                              : campaign.adCreatives
                          }
                          alt="ad_creative"
                        />
                      </div>
                    )}

                    <Form.Label>Change Campaign Ad Creative</Form.Label>
                    <Form.Control type="file" onChange={handleImage} />
                    <Form.Control.Feedback type="invalid">
                      Please Upload Campaign Ad Creative
                    </Form.Control.Feedback>
                  </>
                ) : (
                  <>
                    <Form.Label>Upload Campaign Ad Creative</Form.Label>
                    <Form.Control type="file" onChange={handleImage} />
                    <Form.Control.Feedback type="invalid">
                      Please Upload Campaign Ad Creative
                    </Form.Control.Feedback>
                  </>
                )}
              </div>

              <div className="col-md-12 mb-3">
                <Form.Label>Target Audience</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  // required
                  placeholder="Ex: Young adults"
                  value={campaign.targetAudience}
                  onChange={(e) => {
                    setCampaign({
                      ...campaign,
                      targetAudience: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Target Audience
                </Form.Control.Feedback>
              </div>

              <div className="col-md-12 mb-3">
                <Form.Label>Goals</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  // required
                  placeholder="Ex: Increase brand awareness"
                  value={campaign.goals}
                  onChange={(e) => {
                    setCampaign({
                      ...campaign,
                      goals: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Goals
                </Form.Control.Feedback>
              </div>

              <div className="col-md-12 mb-3">
                <Form.Label>Campaign Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  // required
                  placeholder="Ex: Description about the campaign"
                  value={campaign.campaignDescription}
                  onChange={(e) => {
                    setCampaign({
                      ...campaign,
                      campaignDescription: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Campaign Description
                </Form.Control.Feedback>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Label>Conversion Events</Form.Label>
                <Form.Control
                  type="text"
                  // required
                  placeholder="Ex: Profile Completions"
                  value={campaign.conversionEvents}
                  onChange={(e) => {
                    setCampaign({
                      ...campaign,
                      conversionEvents: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Conversion Events
                </Form.Control.Feedback>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Label>Total Conversions</Form.Label>
                <Form.Control
                  type="number"
                  // required
                  placeholder="Ex: 550"
                  value={campaign.totalConversions}
                  onChange={(e) => {
                    setCampaign({
                      ...campaign,
                      totalConversions: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Total Conversions
                </Form.Control.Feedback>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Label>Total Spend</Form.Label>
                <Form.Control
                  type="number"
                  // required
                  placeholder="Ex: 9500"
                  value={campaign.totalSpend}
                  onChange={(e) => {
                    setCampaign({
                      ...campaign,
                      totalSpend: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Total Spend
                </Form.Control.Feedback>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Label>Generated Revenue</Form.Label>
                <Form.Control
                  type="number"
                  // required
                  placeholder="Ex: 25000"
                  value={campaign.revenueGenerated}
                  onChange={(e) => {
                    setCampaign({
                      ...campaign,
                      revenueGenerated: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Generated Revenue
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="buttons-modal border-top pt-3 text-end ">
              <Link to="#" className=" bg-danger" onClick={handleAddClose}>
                Cancel
              </Link>
              {campaign.campaignId ? (
                <button className="ms-2" type="submit" onClick={addCampaignApi}>
                  Edit
                </button>
              ) : (
                <button className="ms-2" type="submit" onClick={addCampaignApi}>
                  Save
                </button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END ADD ========================== */}

      {/* ======================= DELETE ========================== */}

      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              type="submit"
              onClick={() => {
                campaignDeleteApi();
                setDeleteModal(false);
              }}
            >
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* ======================= END DELETE ========================== */}

      {/* ======================= VIEW ========================== */}

      <Modal
        show={viewModal}
        onHide={handleViewClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Marketing Campaign Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              {viewCampaign?.adCreatives && (
                <div className="modal-img-box mb-3">
                  <img
                    className="article_img"
                    src={
                      !viewCampaign?.adCreatives
                        ? Article_Edit
                        : viewCampaign?.adCreatives
                    }
                    alt="ad_creative"
                  />
                </div>
              )}

              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Campaign Name
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewCampaign?.campaignName}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Campaign Type
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewCampaign?.campaignType}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Start Date
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewCampaign?.startDate?.split("T")[0]}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    End Date
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewCampaign?.endDate?.split("T")[0]}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Budget
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewCampaign?.budget}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Platform
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewCampaign?.platform}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Key Metrics
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewCampaign?.keyMetrics}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Conversion Events
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewCampaign?.conversionEvents}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Total Conversions
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewCampaign?.totalConversions}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Total Spend
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewCampaign?.totalSpend}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Generated Revenue
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewCampaign?.revenueGenerated}
                  </label>
                </div>
              </div>

              <div className="col-md-12 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Target Audience
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewCampaign?.targetAudience}
                  </label>
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Goals
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewCampaign?.goals}
                  </label>
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Campaign Description
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewCampaign?.campaignDescription}
                  </label>
                </div>
              </div>
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <Link to="#" className="bg-danger" onClick={handleViewClose}>
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* ======================= END VIEW ========================== */}

      <div className="table-wrapper">
        {/* <div className="search-wrapper row">
          <Form.Group className="mb-3 col-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              placeholder="Search"
              onChange={(e) => {
                setSearch(e.target.value);
                setCurrentPage(1);
              }}
            />
          </Form.Group>
        </div> */}
        {loading ? (
          <div style={{ marginTop: "150px" }}>
            <Loader />
          </div>
        ) : (
          <>
          <div className="main-data-wrapper">
            <Table class="display" striped bordered hover>
              <thead>
                <tr>
                  <th> </th>
                  <th>Campaign Name</th>
                  <th>Campaign Type</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Budget</th>
                  <th>Revenue</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {campaignData && campaignData.length > 0 ? (
                  campaignData.map((marketingCampaign, index) => {
                    return (
                      <tr key={"campaign" + index}>
                        <td>{(currrentpage - 1) * 10 + index + 1} </td>
                        <td>{marketingCampaign.campaignName}</td>
                        <td>{marketingCampaign.campaignType}</td>
                        {/* <td>{marketingCampaign.startDate}</td>
                        <td>{marketingCampaign.endDate}</td> */}
                        <td>
                          {new Date(marketingCampaign.startDate)
                            .toISOString()
                            .slice(0, 10)}
                        </td>
                        <td>
                          {new Date(marketingCampaign.endDate)
                            .toISOString()
                            .slice(0, 10)}
                        </td>

                        <td>{marketingCampaign.budget}</td>
                        <td>{marketingCampaign.revenueGenerated}</td>
                        <td className="action">
                          <span>
                            <Link
                              to="#"
                              className="icon edit"
                              onClick={() => {
                                handleAddShow(marketingCampaign);
                              }}
                            >
                              <img src={ic_edit} alt="ic_edit" />
                            </Link>
                          </span>
                          <Link
                            to="#"
                            className="icon view"
                            onClick={() => {
                              handleViewShow(marketingCampaign);
                            }}
                          >
                            <img src={ic_eyes} alt="ic_view" />
                          </Link>

                          <Link
                            to="#"
                            className="icon delete"
                            onClick={() => {
                              setdeleteCampaign(marketingCampaign._id);
                              setDeleteModal(true);
                            }}
                          >
                            <img src={ic_delete} alt="" />
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6}>
                      <div className=" justify-content-center align-items-center text-danger text-center mt-3">
                        Data Not Found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {campaignData.length > 0 ? (
            <div className="pagination-wrapper">
              <div className="entry-number">
              Showing {(currrentpage - 1) * 10 + 1} -{" "}
              {lastPage == true ? totalCount : currrentpage * 10} of{" "}
                  {totalCount}
              </div>
              <Pagination
                  data={campaignData}
                  pageChangeHandler={pageChangeHandler}
                  // usersPerPage={usersPerPage}
                  currentPage={currrentpage}
                  searchValue={search}
                  totalPages={totalPages}
                  searchedDataPageCounts={searchedDataPageCounts}
                />
            </div>
          ): null}
          </>
        )}
      </div>
    </main>
  );
};

export default MarketingCampaigns;
