import React, { useContext, useState } from "react";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import circle_logo from "../assets/images/logo_small.png";
import { AppContext } from "../context/AppContext";
import { PostCallWithErrorResponse } from "../api/ApiServices";
import { ToastMsg } from "./ToastMsg";
import ApiConfig from "../api/ApiConfig";

const Login = ({ setLoggedIn }) => {
  const { setUserData } = useContext(AppContext);
  const [validated, setValidated] = useState(false);
  const [user, setUser] = useState({
    username: "",
    password: "",
  });

  const [errMsg, setErrMsg] = useState({
    username: "",
    password: "",
  });

  const LogInSubmit = async (e) => {
    e.preventDefault();
    const regex = /^[a-zA-Z0-9.]+@(?:[a-zA-Z0-9.]+\.)+[A-Za-z]+$/;
    if (user.username.length > 0 === "") {
      setErrMsg({ ...errMsg, username: "Enter Valid  Email ID " });
      return;
    }
    if (user.password.trim().length === 0) {
      setErrMsg({ ...errMsg, password: "Enter Valid Password " });
      return;
    } else {
      PostCallWithErrorResponse(ApiConfig.LOGIN, JSON.stringify(user)).then(
        (res) => {
          if (res.error) {
            ToastMsg("error", res.json.message);
          } else if (res.json) {
            ToastMsg("success", res.json.message);

            setUserData({
              Token: res.json.data.auth_token,
              FullName: res.json.data.admin.full_name,
              USERNAME: res.json.data.admin.username,
              IsActive: res.json.data.admin.is_active,
              IssuperAdmin: res.json.data.admin.is_super_admin,
              IsArticalUpdation: res.json.data.admin.is_artical_updation,
              IsBlockUser: res.json.data.admin.is_block_user,
              IsDeleteUser: res.json.data.admin.is_delete_user,
              IsDocumentValidation:
                res.json.data.admin.is_document_verification,
              IsProfileSuggesion: res.json.data.admin.is_profile_suggession,
            });

            localStorage.setItem("Token", res.json.data.auth_token);
            localStorage.setItem("FullName", res.json.data.admin.full_name);
            localStorage.setItem("USERNAME", res.json.data.admin.username);
            localStorage.setItem("IsActive", res.json.data.admin.is_active);
            localStorage.setItem(
              "IssuperAdmin",
              res.json.data.admin.is_super_admin
            );
            localStorage.setItem(
              "IsArticalUpdation",
              res.json.data.admin.is_artical_updation
            );
            localStorage.setItem(
              "IsBlockUser",
              res.json.data.admin.is_block_user
            );
            localStorage.setItem(
              "IsDeleteUser",
              res.json.data.admin.is_delete_user
            );
            localStorage.setItem(
              "IsDocumentValidation",
              res.json.data.admin.is_document_verification
            );
            localStorage.setItem(
              "IsProfileSuggesion",
              res.json.data.admin.is_profile_suggession
            );
            localStorage.setItem("logedIn", true);
            setLoggedIn(true);
          }
        }
      );
    }
    setValidated(true);
  };

  return (
    <main className="main-login">
      <div className="wrapper row">
        <div className="left col-md-8">
          <div className="logo">
            <img src={circle_logo} alt="" />
          </div>
        </div>
        <div className="right col-md-4">
          <div className="inner-form">
            <div className="text-center mb-4 heading-login">
              <h2>Welcome</h2>
              <label htmlFor="">Please enter your credentials</label>
            </div>
            <Form noValidate validated={validated} onSubmit={LogInSubmit}>
              <div className="mb-3">
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  required
                  type="email"
                  onChange={(e) => {
                    setUser({
                      ...user,
                      username: e.target.value,
                    });
                    setErrMsg({
                      ...errMsg,
                      username: "",
                    });
                  }}
                  value={user.username}
                />
                {errMsg.username.length > 0 && (
                  <span className="text-danger text-center">
                    {errMsg.username}
                  </span>
                )}
              </div>
              <div className="mb-4">
                <Form.Label>Password</Form.Label>

                <Form.Control
                  required
                  type="password"
                  onChange={(e) => {
                    setUser({
                      ...user,
                      password: e.target.value,
                    });
                    setErrMsg({
                      ...errMsg,
                      password: "",
                    });
                  }}
                  value={user.password}
                  onKeyUp={(e) => {
                    if (e.key == "Enter") LogInSubmit(e);
                  }}
                />
                {errMsg.password.length > 0 && (
                  <span className="text-danger text-center">
                    {errMsg.password}
                  </span>
                )}
              </div>
              <div className="login-btn-main ">
                <Link to="#" className="innerLink">
                  <button
                    className="cx-btn-1"
                    type="button"
                    onClick={LogInSubmit}
                  >
                    Login
                  </button>
                </Link>
                <Link to="/Support" className="support_link">
                  <p className="register ">Support</p>
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
