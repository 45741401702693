import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import ic_sidemenu_toggle from "../assets/images/ic_sidemenu_toggle.svg";
import { useMediaQuery } from "react-responsive";
import Deeniyat_logo from "../assets/images/logo_big.png";
import deeniyatAppicon from "../assets/images/logo_small.png";

import ic_dashboard from "../assets/images/ic_dashboard.svg";
import grades from "../assets/images/grades.png";

import { AppContext } from "../context/AppContext";
import QueryManagement from "./../pages/QueryManagement";

const Sidebar = () => {
  const navigate = useNavigate();
  const currentRoute = useLocation().pathname;
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  useEffect(() => {
    isMobile ? setSidebar(false) : setSidebar(true);
    return () => {};
  }, [isMobile]);

  const { sidebar, setSidebar, Dark } = useContext(AppContext);
  const handleSidebar = () => {
    setSidebar(!sidebar);
  };

  const [setting, setsetting] = useState(false);
  const [Cmanagement, setCmanagement] = useState(false);

  return (
    <main className="cx-sidebar">
      <div
        className={
          sidebar ? "cx-sidebar-wrapper " : "cx-sidebar-wrapper sidebarActive"
        }
      >
        <aside>
          <div className="logo-main">
            {sidebar ? (
              <div className="big">
                <img src={Deeniyat_logo} alt="" />
              </div>
            ) : (
              <div className="small">
                <img src={deeniyatAppicon} alt="" />
              </div>
            )}
          </div>

          <div className="left-arrow"></div>

          <div className="menus-main">
            <div className="menus-items" id="arrow-double">
              <div
                className={sidebar ? "text-end " : "doubleArrowActive"}
                onClick={handleSidebar}
                id="arrow-inner"
              >
                <img src={ic_sidemenu_toggle} alt="" />
              </div>
            </div>
            {/* DASHBOARD */}
            {/* <div
              className={
                currentRoute === "/Dashboard"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/Dashboard" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/dashboard" className="me-2">
                      {currentRoute === "/Dashboard" ? (
                        <img src={ic_dashboard} alt="" />
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#055d9d"
                            d="M4 13h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zm0 8h6c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1zm10 0h6c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zM13 4v4c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1z"
                          />
                        </svg>
                      )}
                    </Link>
                    Dashboard
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/Dashboard" ? (
                      <img src={ic_dashboard} alt="" />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#055d9d"
                          d="M4 13h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zm0 8h6c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1zm10 0h6c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zM13 4v4c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1z"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </Link>
            </div> */}
            {/* DASHBOARD Analytics*/}
            <div
              className={
                currentRoute === "/Dashboard"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/Dashboard" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/Dashboard" className="me-2">
                      {currentRoute === "/Dashboard" ? (
                        <img src={ic_dashboard} alt="" />
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#055d9d"
                            d="M4 13h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zm0 8h6c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1zm10 0h6c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zM13 4v4c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1z"
                          />
                        </svg>
                      )}
                    </Link>
                    Dashboard Analytics
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/Dashboard" ? (
                      <img src={ic_dashboard} alt="" />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#055d9d"
                          d="M4 13h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zm0 8h6c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1zm10 0h6c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zM13 4v4c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1z"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </Link>
            </div>
            {/* ADMIN */}
            <div
              className={
                currentRoute === "/Admin"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/Admin" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/Admin" className="me-2">
                      {currentRoute === "/Admin" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#fff"
                            d="M12 14v8H4a8 8 0 0 1 8-8Zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6s6 2.685 6 6s-2.685 6-6 6Zm9 4h1v5h-8v-5h1v-1a3 3 0 1 1 6 0v1Zm-2 0v-1a1 1 0 1 0-2 0v1h2Z"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#055d9d"
                            d="M12 14v8H4a8 8 0 0 1 8-8Zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6s6 2.685 6 6s-2.685 6-6 6Zm9 4h1v5h-8v-5h1v-1a3 3 0 1 1 6 0v1Zm-2 0v-1a1 1 0 1 0-2 0v1h2Z"
                          />
                        </svg>
                      )}
                    </Link>
                    Admin
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/Admin" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#fff"
                          d="M12 14v8H4a8 8 0 0 1 8-8Zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6s6 2.685 6 6s-2.685 6-6 6Zm9 4h1v5h-8v-5h1v-1a3 3 0 1 1 6 0v1Zm-2 0v-1a1 1 0 1 0-2 0v1h2Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#055d9d"
                          d="M12 14v8H4a8 8 0 0 1 8-8Zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6s6 2.685 6 6s-2.685 6-6 6Zm9 4h1v5h-8v-5h1v-1a3 3 0 1 1 6 0v1Zm-2 0v-1a1 1 0 1 0-2 0v1h2Z"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </Link>
            </div>
            {/* USERS */}
            <div
              className={
                currentRoute === "/Users" || currentRoute === "/ViewUsers"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/Users" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/Users" className="me-2">
                      {currentRoute === "/Users" ||
                      currentRoute === "/ViewUsers" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#fff"
                            d="M12 5.5A3.5 3.5 0 0 1 15.5 9a3.5 3.5 0 0 1-3.5 3.5A3.5 3.5 0 0 1 8.5 9A3.5 3.5 0 0 1 12 5.5M5 8c.56 0 1.08.15 1.53.42c-.15 1.43.27 2.85 1.13 3.96C7.16 13.34 6.16 14 5 14a3 3 0 0 1-3-3a3 3 0 0 1 3-3m14 0a3 3 0 0 1 3 3a3 3 0 0 1-3 3c-1.16 0-2.16-.66-2.66-1.62a5.536 5.536 0 0 0 1.13-3.96c.45-.27.97-.42 1.53-.42M5.5 18.25c0-2.07 2.91-3.75 6.5-3.75s6.5 1.68 6.5 3.75V20h-13v-1.75M0 20v-1.5c0-1.39 1.89-2.56 4.45-2.9c-.59.68-.95 1.62-.95 2.65V20H0m24 0h-3.5v-1.75c0-1.03-.36-1.97-.95-2.65c2.56.34 4.45 1.51 4.45 2.9V20Z"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#055d9d"
                            d="M12 5.5A3.5 3.5 0 0 1 15.5 9a3.5 3.5 0 0 1-3.5 3.5A3.5 3.5 0 0 1 8.5 9A3.5 3.5 0 0 1 12 5.5M5 8c.56 0 1.08.15 1.53.42c-.15 1.43.27 2.85 1.13 3.96C7.16 13.34 6.16 14 5 14a3 3 0 0 1-3-3a3 3 0 0 1 3-3m14 0a3 3 0 0 1 3 3a3 3 0 0 1-3 3c-1.16 0-2.16-.66-2.66-1.62a5.536 5.536 0 0 0 1.13-3.96c.45-.27.97-.42 1.53-.42M5.5 18.25c0-2.07 2.91-3.75 6.5-3.75s6.5 1.68 6.5 3.75V20h-13v-1.75M0 20v-1.5c0-1.39 1.89-2.56 4.45-2.9c-.59.68-.95 1.62-.95 2.65V20H0m24 0h-3.5v-1.75c0-1.03-.36-1.97-.95-2.65c2.56.34 4.45 1.51 4.45 2.9V20Z"
                          />
                        </svg>
                      )}
                    </Link>
                    Users
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/Users" ||
                    currentRoute === "/ViewUsers" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#fff"
                          d="M12 5.5A3.5 3.5 0 0 1 15.5 9a3.5 3.5 0 0 1-3.5 3.5A3.5 3.5 0 0 1 8.5 9A3.5 3.5 0 0 1 12 5.5M5 8c.56 0 1.08.15 1.53.42c-.15 1.43.27 2.85 1.13 3.96C7.16 13.34 6.16 14 5 14a3 3 0 0 1-3-3a3 3 0 0 1 3-3m14 0a3 3 0 0 1 3 3a3 3 0 0 1-3 3c-1.16 0-2.16-.66-2.66-1.62a5.536 5.536 0 0 0 1.13-3.96c.45-.27.97-.42 1.53-.42M5.5 18.25c0-2.07 2.91-3.75 6.5-3.75s6.5 1.68 6.5 3.75V20h-13v-1.75M0 20v-1.5c0-1.39 1.89-2.56 4.45-2.9c-.59.68-.95 1.62-.95 2.65V20H0m24 0h-3.5v-1.75c0-1.03-.36-1.97-.95-2.65c2.56.34 4.45 1.51 4.45 2.9V20Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#055d9d"
                          d="M12 5.5A3.5 3.5 0 0 1 15.5 9a3.5 3.5 0 0 1-3.5 3.5A3.5 3.5 0 0 1 8.5 9A3.5 3.5 0 0 1 12 5.5M5 8c.56 0 1.08.15 1.53.42c-.15 1.43.27 2.85 1.13 3.96C7.16 13.34 6.16 14 5 14a3 3 0 0 1-3-3a3 3 0 0 1 3-3m14 0a3 3 0 0 1 3 3a3 3 0 0 1-3 3c-1.16 0-2.16-.66-2.66-1.62a5.536 5.536 0 0 0 1.13-3.96c.45-.27.97-.42 1.53-.42M5.5 18.25c0-2.07 2.91-3.75 6.5-3.75s6.5 1.68 6.5 3.75V20h-13v-1.75M0 20v-1.5c0-1.39 1.89-2.56 4.45-2.9c-.59.68-.95 1.62-.95 2.65V20H0m24 0h-3.5v-1.75c0-1.03-.36-1.97-.95-2.65c2.56.34 4.45 1.51 4.45 2.9V20Z"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </Link>
            </div>

            {/* Chaperone */}
            <div
              className={
                currentRoute === "/Chaperone"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/Chaperone" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/Chaperone" className="me-2">
                      {currentRoute === "/Chaperone" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                        >
                          <path
                            fill="#fff"
                            d="M14.423 12.17a5.233 5.233 0 0 1-3.102 1.03c-1.163 0-2.23-.39-3.103-1.03c-.75.625-1.498 1.52-2.11 2.623c-1.423 2.563-1.58 5.192-.35 5.874c.548.312 1.126.078 1.722-.496a10.51 10.51 0 0 0-.167 1.874c0 2.938 1.14 5.312 2.543 5.312c.846 0 1.265-.865 1.466-2.188c.2 1.314.62 2.188 1.46 2.188c1.397 0 2.546-2.375 2.546-5.312c0-.66-.062-1.29-.168-1.873c.6.575 1.176.813 1.726.497c1.227-.682 1.068-3.31-.354-5.874c-.61-1.104-1.36-1.998-2.11-2.623zm-3.103.03a4.279 4.279 0 1 0-.003-8.561a4.279 4.279 0 0 0 .003 8.563zm10.667 5.47c1.508 0 2.732-1.224 2.732-2.734S23.493 12.2 21.986 12.2a2.737 2.737 0 0 0-2.736 2.736a2.737 2.737 0 0 0 2.737 2.735zm3.33 1.657c-.39-.705-.868-1.277-1.348-1.677c-.56.41-1.24.66-1.983.66c-.744 0-1.426-.25-1.983-.66c-.48.4-.958.972-1.35 1.677c-.91 1.638-1.01 3.318-.224 3.754c.35.2.72.05 1.1-.316a6.874 6.874 0 0 0-.104 1.197c0 1.88.728 3.397 1.625 3.397c.54 0 .81-.553.938-1.398c.128.84.396 1.397.934 1.397c.893 0 1.627-1.518 1.627-3.396c0-.42-.04-.824-.108-1.196c.383.367.752.52 1.104.317c.782-.434.68-2.115-.228-3.753z"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                        >
                          <path
                            fill="#055d9d"
                            d="M14.423 12.17a5.233 5.233 0 0 1-3.102 1.03c-1.163 0-2.23-.39-3.103-1.03c-.75.625-1.498 1.52-2.11 2.623c-1.423 2.563-1.58 5.192-.35 5.874c.548.312 1.126.078 1.722-.496a10.51 10.51 0 0 0-.167 1.874c0 2.938 1.14 5.312 2.543 5.312c.846 0 1.265-.865 1.466-2.188c.2 1.314.62 2.188 1.46 2.188c1.397 0 2.546-2.375 2.546-5.312c0-.66-.062-1.29-.168-1.873c.6.575 1.176.813 1.726.497c1.227-.682 1.068-3.31-.354-5.874c-.61-1.104-1.36-1.998-2.11-2.623zm-3.103.03a4.279 4.279 0 1 0-.003-8.561a4.279 4.279 0 0 0 .003 8.563zm10.667 5.47c1.508 0 2.732-1.224 2.732-2.734S23.493 12.2 21.986 12.2a2.737 2.737 0 0 0-2.736 2.736a2.737 2.737 0 0 0 2.737 2.735zm3.33 1.657c-.39-.705-.868-1.277-1.348-1.677c-.56.41-1.24.66-1.983.66c-.744 0-1.426-.25-1.983-.66c-.48.4-.958.972-1.35 1.677c-.91 1.638-1.01 3.318-.224 3.754c.35.2.72.05 1.1-.316a6.874 6.874 0 0 0-.104 1.197c0 1.88.728 3.397 1.625 3.397c.54 0 .81-.553.938-1.398c.128.84.396 1.397.934 1.397c.893 0 1.627-1.518 1.627-3.396c0-.42-.04-.824-.108-1.196c.383.367.752.52 1.104.317c.782-.434.68-2.115-.228-3.753z"
                          />
                        </svg>
                      )}
                    </Link>
                    Chaperone
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/Chaperone" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="#fff"
                          d="M14.423 12.17a5.233 5.233 0 0 1-3.102 1.03c-1.163 0-2.23-.39-3.103-1.03c-.75.625-1.498 1.52-2.11 2.623c-1.423 2.563-1.58 5.192-.35 5.874c.548.312 1.126.078 1.722-.496a10.51 10.51 0 0 0-.167 1.874c0 2.938 1.14 5.312 2.543 5.312c.846 0 1.265-.865 1.466-2.188c.2 1.314.62 2.188 1.46 2.188c1.397 0 2.546-2.375 2.546-5.312c0-.66-.062-1.29-.168-1.873c.6.575 1.176.813 1.726.497c1.227-.682 1.068-3.31-.354-5.874c-.61-1.104-1.36-1.998-2.11-2.623zm-3.103.03a4.279 4.279 0 1 0-.003-8.561a4.279 4.279 0 0 0 .003 8.563zm10.667 5.47c1.508 0 2.732-1.224 2.732-2.734S23.493 12.2 21.986 12.2a2.737 2.737 0 0 0-2.736 2.736a2.737 2.737 0 0 0 2.737 2.735zm3.33 1.657c-.39-.705-.868-1.277-1.348-1.677c-.56.41-1.24.66-1.983.66c-.744 0-1.426-.25-1.983-.66c-.48.4-.958.972-1.35 1.677c-.91 1.638-1.01 3.318-.224 3.754c.35.2.72.05 1.1-.316a6.874 6.874 0 0 0-.104 1.197c0 1.88.728 3.397 1.625 3.397c.54 0 .81-.553.938-1.398c.128.84.396 1.397.934 1.397c.893 0 1.627-1.518 1.627-3.396c0-.42-.04-.824-.108-1.196c.383.367.752.52 1.104.317c.782-.434.68-2.115-.228-3.753z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="#055d9d"
                          d="M14.423 12.17a5.233 5.233 0 0 1-3.102 1.03c-1.163 0-2.23-.39-3.103-1.03c-.75.625-1.498 1.52-2.11 2.623c-1.423 2.563-1.58 5.192-.35 5.874c.548.312 1.126.078 1.722-.496a10.51 10.51 0 0 0-.167 1.874c0 2.938 1.14 5.312 2.543 5.312c.846 0 1.265-.865 1.466-2.188c.2 1.314.62 2.188 1.46 2.188c1.397 0 2.546-2.375 2.546-5.312c0-.66-.062-1.29-.168-1.873c.6.575 1.176.813 1.726.497c1.227-.682 1.068-3.31-.354-5.874c-.61-1.104-1.36-1.998-2.11-2.623zm-3.103.03a4.279 4.279 0 1 0-.003-8.561a4.279 4.279 0 0 0 .003 8.563zm10.667 5.47c1.508 0 2.732-1.224 2.732-2.734S23.493 12.2 21.986 12.2a2.737 2.737 0 0 0-2.736 2.736a2.737 2.737 0 0 0 2.737 2.735zm3.33 1.657c-.39-.705-.868-1.277-1.348-1.677c-.56.41-1.24.66-1.983.66c-.744 0-1.426-.25-1.983-.66c-.48.4-.958.972-1.35 1.677c-.91 1.638-1.01 3.318-.224 3.754c.35.2.72.05 1.1-.316a6.874 6.874 0 0 0-.104 1.197c0 1.88.728 3.397 1.625 3.397c.54 0 .81-.553.938-1.398c.128.84.396 1.397.934 1.397c.893 0 1.627-1.518 1.627-3.396c0-.42-.04-.824-.108-1.196c.383.367.752.52 1.104.317c.782-.434.68-2.115-.228-3.753z"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </Link>
            </div>
            {/* subscription */}
            <div
              className={
                currentRoute === "/Subscription"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/Subscription" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/Users" className="me-2">
                      {currentRoute === "/Subscription" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#fff"
                            d="M8 4q-.425 0-.713-.288T7 3q0-.425.288-.713T8 2h8q.425 0 .713.288T17 3q0 .425-.288.713T16 4H8ZM5 7q-.425 0-.713-.288T4 6q0-.425.288-.713T5 5h14q.425 0 .713.288T20 6q0 .425-.288.713T19 7H5ZM4 22q-.825 0-1.413-.588T2 20V10q0-.825.588-1.413T4 8h16q.825 0 1.413.588T22 10v10q0 .825-.588 1.413T20 22H4Zm7.55-4.025l3.2-2.15q.45-.3.45-.825t-.45-.825l-3.2-2.15q-.5-.35-1.025-.05t-.525.9v4.25q0 .6.525.9t1.025-.05Z"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#055d9d"
                            d="M8 4q-.425 0-.713-.288T7 3q0-.425.288-.713T8 2h8q.425 0 .713.288T17 3q0 .425-.288.713T16 4H8ZM5 7q-.425 0-.713-.288T4 6q0-.425.288-.713T5 5h14q.425 0 .713.288T20 6q0 .425-.288.713T19 7H5ZM4 22q-.825 0-1.413-.588T2 20V10q0-.825.588-1.413T4 8h16q.825 0 1.413.588T22 10v10q0 .825-.588 1.413T20 22H4Zm7.55-4.025l3.2-2.15q.45-.3.45-.825t-.45-.825l-3.2-2.15q-.5-.35-1.025-.05t-.525.9v4.25q0 .6.525.9t1.025-.05Z"
                          />
                        </svg>
                      )}
                    </Link>
                    Subscription
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/Subscription" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#fff"
                          d="M8 4q-.425 0-.713-.288T7 3q0-.425.288-.713T8 2h8q.425 0 .713.288T17 3q0 .425-.288.713T16 4H8ZM5 7q-.425 0-.713-.288T4 6q0-.425.288-.713T5 5h14q.425 0 .713.288T20 6q0 .425-.288.713T19 7H5ZM4 22q-.825 0-1.413-.588T2 20V10q0-.825.588-1.413T4 8h16q.825 0 1.413.588T22 10v10q0 .825-.588 1.413T20 22H4Zm7.55-4.025l3.2-2.15q.45-.3.45-.825t-.45-.825l-3.2-2.15q-.5-.35-1.025-.05t-.525.9v4.25q0 .6.525.9t1.025-.05Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#055d9d"
                          d="M8 4q-.425 0-.713-.288T7 3q0-.425.288-.713T8 2h8q.425 0 .713.288T17 3q0 .425-.288.713T16 4H8ZM5 7q-.425 0-.713-.288T4 6q0-.425.288-.713T5 5h14q.425 0 .713.288T20 6q0 .425-.288.713T19 7H5ZM4 22q-.825 0-1.413-.588T2 20V10q0-.825.588-1.413T4 8h16q.825 0 1.413.588T22 10v10q0 .825-.588 1.413T20 22H4Zm7.55-4.025l3.2-2.15q.45-.3.45-.825t-.45-.825l-3.2-2.15q-.5-.35-1.025-.05t-.525.9v4.25q0 .6.525.9t1.025-.05Z"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </Link>
            </div>
            {/*  DOCUMENTS VERIFICATION */}
            {/* <div
              className={
                currentRoute === "/DocumentsVarification" ||
                currentRoute === "/ViewDocuments"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/DocumentsVarification" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/DocumentsVarification" className="me-2">
                      {currentRoute === "/DocumentsVarification" ||
                      currentRoute === "/ViewDocuments" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#FFF"
                            d="m23.5 17l-5 5l-3.5-3.5l1.5-1.5l2 2l3.5-3.5l1.5 1.5M6 2c-1.11 0-2 .89-2 2v16c0 1.11.89 2 2 2h7.81c-.53-.91-.81-1.95-.81-3c0-.33.03-.67.08-1H6v-2h7.81c.46-.8 1.1-1.5 1.87-2H6v-2h12v1.08c.33-.05.67-.08 1-.08c.34 0 .67.03 1 .08V8l-6-6m-1 1.5L18.5 9H13Z"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#055d9d"
                            d="m23.5 17l-5 5l-3.5-3.5l1.5-1.5l2 2l3.5-3.5l1.5 1.5M6 2c-1.11 0-2 .89-2 2v16c0 1.11.89 2 2 2h7.81c-.53-.91-.81-1.95-.81-3c0-.33.03-.67.08-1H6v-2h7.81c.46-.8 1.1-1.5 1.87-2H6v-2h12v1.08c.33-.05.67-.08 1-.08c.34 0 .67.03 1 .08V8l-6-6m-1 1.5L18.5 9H13Z"
                          />
                        </svg>
                      )}
                    </Link>
                    Document Verification
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/DocumentsVarification" ||
                    currentRoute === "/ViewDocuments" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#FFF"
                          d="m23.5 17l-5 5l-3.5-3.5l1.5-1.5l2 2l3.5-3.5l1.5 1.5M6 2c-1.11 0-2 .89-2 2v16c0 1.11.89 2 2 2h7.81c-.53-.91-.81-1.95-.81-3c0-.33.03-.67.08-1H6v-2h7.81c.46-.8 1.1-1.5 1.87-2H6v-2h12v1.08c.33-.05.67-.08 1-.08c.34 0 .67.03 1 .08V8l-6-6m-1 1.5L18.5 9H13Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#055d9d"
                          d="m23.5 17l-5 5l-3.5-3.5l1.5-1.5l2 2l3.5-3.5l1.5 1.5M6 2c-1.11 0-2 .89-2 2v16c0 1.11.89 2 2 2h7.81c-.53-.91-.81-1.95-.81-3c0-.33.03-.67.08-1H6v-2h7.81c.46-.8 1.1-1.5 1.87-2H6v-2h12v1.08c.33-.05.67-.08 1-.08c.34 0 .67.03 1 .08V8l-6-6m-1 1.5L18.5 9H13Z"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </Link>
            </div> */}

            {/* USER Repored  */}
            <div
              className={
                currentRoute === "/ReportManagement"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/ReportManagement" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/ReportManagement" className="me-2">
                      {currentRoute === "/ReportManagement" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#fff"
                            d="m21.1 12.5l1.4 1.41l-6.53 6.59L12.5 17l1.4-1.41l2.07 2.08l5.13-5.17M10 17l3 3H3v-2c0-2.21 3.58-4 8-4l1.89.11L10 17m1-13a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4Z"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#055d9d"
                            d="m21.1 12.5l1.4 1.41l-6.53 6.59L12.5 17l1.4-1.41l2.07 2.08l5.13-5.17M10 17l3 3H3v-2c0-2.21 3.58-4 8-4l1.89.11L10 17m1-13a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4Z"
                          />
                        </svg>
                      )}
                    </Link>
                    Report Management
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/ReportManagement" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#fff"
                          d="m21.1 12.5l1.4 1.41l-6.53 6.59L12.5 17l1.4-1.41l2.07 2.08l5.13-5.17M10 17l3 3H3v-2c0-2.21 3.58-4 8-4l1.89.11L10 17m1-13a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#055d9d"
                          d="m21.1 12.5l1.4 1.41l-6.53 6.59L12.5 17l1.4-1.41l2.07 2.08l5.13-5.17M10 17l3 3H3v-2c0-2.21 3.58-4 8-4l1.89.11L10 17m1-13a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4Z"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </Link>
            </div>

            {/* T & C */}
            <div
              className={
                currentRoute === "/QueryManagement"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/QueryManagement" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/TermsConditions" className="me-2">
                      {currentRoute === "/QueryManagement" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill="#fff"
                            d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.496 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24c0 1.08-.635 1.594-1.244 2.057c-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987c.59-.444.965-.736.965-1.371c0-.825-.628-1.168-1.314-1.168c-.803 0-1.253.478-1.342 1.134c-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927c0-.552.425-.94 1.01-.94c.609 0 1.028.388 1.028.94c0 .533-.42.927-1.029.927z"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill="#055d9d"
                            d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.496 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24c0 1.08-.635 1.594-1.244 2.057c-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987c.59-.444.965-.736.965-1.371c0-.825-.628-1.168-1.314-1.168c-.803 0-1.253.478-1.342 1.134c-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927c0-.552.425-.94 1.01-.94c.609 0 1.028.388 1.028.94c0 .533-.42.927-1.029.927z"
                          />
                        </svg>
                      )}
                    </Link>
                    Query Management
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/QueryManagement" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="#fff"
                          d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.496 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24c0 1.08-.635 1.594-1.244 2.057c-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987c.59-.444.965-.736.965-1.371c0-.825-.628-1.168-1.314-1.168c-.803 0-1.253.478-1.342 1.134c-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927c0-.552.425-.94 1.01-.94c.609 0 1.028.388 1.028.94c0 .533-.42.927-1.029.927z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="#055d9d"
                          d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.496 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24c0 1.08-.635 1.594-1.244 2.057c-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987c.59-.444.965-.736.965-1.371c0-.825-.628-1.168-1.314-1.168c-.803 0-1.253.478-1.342 1.134c-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927c0-.552.425-.94 1.01-.94c.609 0 1.028.388 1.028.94c0 .533-.42.927-1.029.927z"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </Link>
            </div>

            {/* PROFILE SUGGESTION */}
            <div
              className={
                currentRoute === "/ProfileSuggestion" ||
                currentRoute === "/ViewProfileSuggestion"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/ProfileSuggestion" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/ProfileSuggestion" className="me-2">
                      {currentRoute === "/ProfileSuggestion" ||
                      currentRoute === "/ViewProfileSuggestion" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="512"
                          height="512"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="#fff"
                            d="M132.255 18.093c-32.842 0-59.592 33.923-59.592 75.935c0 21.274 7.079 40.468 18.092 54.284C22.623 158.879 7.903 253.733 24.1 339.874h23.563L46.63 234.53l18.688-.185l1.25 126.685l8.75 132.877h46.875v-172.29c-15.257-18.713-25.615-39.351-26.424-64.825l-.004-.1v-.1c-.154-15.988 6.478-35.643 19.219-52.566c12.741-16.922 32.481-30.98 57.783-31.29c17.252-.212 35.458 5.013 51.338 14.771c-12.131-20.214-29.245-34.177-51.26-38.32c11.507-13.88 18.971-33.345 18.971-55.157c.097-36.903-24.805-76.041-59.562-75.937zm247.841 0c-32.842 0-59.591 33.923-59.591 75.935c0 21.274 7.078 40.468 18.091 54.284c-25.296 3.923-43.223 19.473-54.945 41.875c16.803-11.476 36.697-17.682 55.465-17.452c25.302.311 45.042 14.369 57.783 31.291c12.741 16.923 19.373 36.578 19.219 52.567v.1l-.004.1c-.826 26.007-11.608 46.974-27.393 65.997v171.113h44.315l11.5-154.029h.03l1.062-105.531l18.687.185l-1.062 105.346h24.53c18.363-88.46-4.724-178.95-67.095-190.687c11.507-13.88 18.97-33.345 18.97-55.157c.098-36.903-24.805-76.041-59.562-75.937zM172.99 190.733c-18.837.232-33.281 10.381-43.625 24.12c-10.326 13.715-15.675 31.13-15.594 41.506c1.226 36.63 26.557 61.833 61.219 92.044c26.758 23.322 58.19 48.519 80.617 83.38l.357-.637l.336.601c22.427-34.843 53.847-60.03 80.596-83.344c34.662-30.211 59.993-55.414 61.219-92.044c.081-10.376-5.268-27.791-15.594-41.506c-10.344-13.739-24.788-23.888-43.625-24.12c-28.453-.349-63.84 20.288-74.414 52.022l-8.539 25.625l-8.54-25.625c-10.573-31.734-45.96-52.37-74.413-52.021zm-32.11 150.989v152.181h44.315l7.893-105.714c-9.94-9.023-20.098-17.651-29.924-26.215c-7.726-6.734-15.242-13.43-22.283-20.252zm229.157.93c-6.762 6.5-13.94 12.894-21.315 19.322c-10.636 9.27-21.66 18.622-32.375 28.463l6.815 103.47h46.875z"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="512"
                          height="512"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="#055d9d"
                            d="M132.255 18.093c-32.842 0-59.592 33.923-59.592 75.935c0 21.274 7.079 40.468 18.092 54.284C22.623 158.879 7.903 253.733 24.1 339.874h23.563L46.63 234.53l18.688-.185l1.25 126.685l8.75 132.877h46.875v-172.29c-15.257-18.713-25.615-39.351-26.424-64.825l-.004-.1v-.1c-.154-15.988 6.478-35.643 19.219-52.566c12.741-16.922 32.481-30.98 57.783-31.29c17.252-.212 35.458 5.013 51.338 14.771c-12.131-20.214-29.245-34.177-51.26-38.32c11.507-13.88 18.971-33.345 18.971-55.157c.097-36.903-24.805-76.041-59.562-75.937zm247.841 0c-32.842 0-59.591 33.923-59.591 75.935c0 21.274 7.078 40.468 18.091 54.284c-25.296 3.923-43.223 19.473-54.945 41.875c16.803-11.476 36.697-17.682 55.465-17.452c25.302.311 45.042 14.369 57.783 31.291c12.741 16.923 19.373 36.578 19.219 52.567v.1l-.004.1c-.826 26.007-11.608 46.974-27.393 65.997v171.113h44.315l11.5-154.029h.03l1.062-105.531l18.687.185l-1.062 105.346h24.53c18.363-88.46-4.724-178.95-67.095-190.687c11.507-13.88 18.97-33.345 18.97-55.157c.098-36.903-24.805-76.041-59.562-75.937zM172.99 190.733c-18.837.232-33.281 10.381-43.625 24.12c-10.326 13.715-15.675 31.13-15.594 41.506c1.226 36.63 26.557 61.833 61.219 92.044c26.758 23.322 58.19 48.519 80.617 83.38l.357-.637l.336.601c22.427-34.843 53.847-60.03 80.596-83.344c34.662-30.211 59.993-55.414 61.219-92.044c.081-10.376-5.268-27.791-15.594-41.506c-10.344-13.739-24.788-23.888-43.625-24.12c-28.453-.349-63.84 20.288-74.414 52.022l-8.539 25.625l-8.54-25.625c-10.573-31.734-45.96-52.37-74.413-52.021zm-32.11 150.989v152.181h44.315l7.893-105.714c-9.94-9.023-20.098-17.651-29.924-26.215c-7.726-6.734-15.242-13.43-22.283-20.252zm229.157.93c-6.762 6.5-13.94 12.894-21.315 19.322c-10.636 9.27-21.66 18.622-32.375 28.463l6.815 103.47h46.875z"
                          />
                        </svg>
                      )}
                    </Link>
                    Profile Suggestion
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/ProfileSuggestion" ||
                    currentRoute === "/ViewProfileSuggestion" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="512"
                        height="512"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="#fff"
                          d="M132.255 18.093c-32.842 0-59.592 33.923-59.592 75.935c0 21.274 7.079 40.468 18.092 54.284C22.623 158.879 7.903 253.733 24.1 339.874h23.563L46.63 234.53l18.688-.185l1.25 126.685l8.75 132.877h46.875v-172.29c-15.257-18.713-25.615-39.351-26.424-64.825l-.004-.1v-.1c-.154-15.988 6.478-35.643 19.219-52.566c12.741-16.922 32.481-30.98 57.783-31.29c17.252-.212 35.458 5.013 51.338 14.771c-12.131-20.214-29.245-34.177-51.26-38.32c11.507-13.88 18.971-33.345 18.971-55.157c.097-36.903-24.805-76.041-59.562-75.937zm247.841 0c-32.842 0-59.591 33.923-59.591 75.935c0 21.274 7.078 40.468 18.091 54.284c-25.296 3.923-43.223 19.473-54.945 41.875c16.803-11.476 36.697-17.682 55.465-17.452c25.302.311 45.042 14.369 57.783 31.291c12.741 16.923 19.373 36.578 19.219 52.567v.1l-.004.1c-.826 26.007-11.608 46.974-27.393 65.997v171.113h44.315l11.5-154.029h.03l1.062-105.531l18.687.185l-1.062 105.346h24.53c18.363-88.46-4.724-178.95-67.095-190.687c11.507-13.88 18.97-33.345 18.97-55.157c.098-36.903-24.805-76.041-59.562-75.937zM172.99 190.733c-18.837.232-33.281 10.381-43.625 24.12c-10.326 13.715-15.675 31.13-15.594 41.506c1.226 36.63 26.557 61.833 61.219 92.044c26.758 23.322 58.19 48.519 80.617 83.38l.357-.637l.336.601c22.427-34.843 53.847-60.03 80.596-83.344c34.662-30.211 59.993-55.414 61.219-92.044c.081-10.376-5.268-27.791-15.594-41.506c-10.344-13.739-24.788-23.888-43.625-24.12c-28.453-.349-63.84 20.288-74.414 52.022l-8.539 25.625l-8.54-25.625c-10.573-31.734-45.96-52.37-74.413-52.021zm-32.11 150.989v152.181h44.315l7.893-105.714c-9.94-9.023-20.098-17.651-29.924-26.215c-7.726-6.734-15.242-13.43-22.283-20.252zm229.157.93c-6.762 6.5-13.94 12.894-21.315 19.322c-10.636 9.27-21.66 18.622-32.375 28.463l6.815 103.47h46.875z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="512"
                        height="512"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="#055d9d"
                          d="M132.255 18.093c-32.842 0-59.592 33.923-59.592 75.935c0 21.274 7.079 40.468 18.092 54.284C22.623 158.879 7.903 253.733 24.1 339.874h23.563L46.63 234.53l18.688-.185l1.25 126.685l8.75 132.877h46.875v-172.29c-15.257-18.713-25.615-39.351-26.424-64.825l-.004-.1v-.1c-.154-15.988 6.478-35.643 19.219-52.566c12.741-16.922 32.481-30.98 57.783-31.29c17.252-.212 35.458 5.013 51.338 14.771c-12.131-20.214-29.245-34.177-51.26-38.32c11.507-13.88 18.971-33.345 18.971-55.157c.097-36.903-24.805-76.041-59.562-75.937zm247.841 0c-32.842 0-59.591 33.923-59.591 75.935c0 21.274 7.078 40.468 18.091 54.284c-25.296 3.923-43.223 19.473-54.945 41.875c16.803-11.476 36.697-17.682 55.465-17.452c25.302.311 45.042 14.369 57.783 31.291c12.741 16.923 19.373 36.578 19.219 52.567v.1l-.004.1c-.826 26.007-11.608 46.974-27.393 65.997v171.113h44.315l11.5-154.029h.03l1.062-105.531l18.687.185l-1.062 105.346h24.53c18.363-88.46-4.724-178.95-67.095-190.687c11.507-13.88 18.97-33.345 18.97-55.157c.098-36.903-24.805-76.041-59.562-75.937zM172.99 190.733c-18.837.232-33.281 10.381-43.625 24.12c-10.326 13.715-15.675 31.13-15.594 41.506c1.226 36.63 26.557 61.833 61.219 92.044c26.758 23.322 58.19 48.519 80.617 83.38l.357-.637l.336.601c22.427-34.843 53.847-60.03 80.596-83.344c34.662-30.211 59.993-55.414 61.219-92.044c.081-10.376-5.268-27.791-15.594-41.506c-10.344-13.739-24.788-23.888-43.625-24.12c-28.453-.349-63.84 20.288-74.414 52.022l-8.539 25.625l-8.54-25.625c-10.573-31.734-45.96-52.37-74.413-52.021zm-32.11 150.989v152.181h44.315l7.893-105.714c-9.94-9.023-20.098-17.651-29.924-26.215c-7.726-6.734-15.242-13.43-22.283-20.252zm229.157.93c-6.762 6.5-13.94 12.894-21.315 19.322c-10.636 9.27-21.66 18.622-32.375 28.463l6.815 103.47h46.875z"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </Link>
            </div>
            {/* article */}
            <div
              className={
                currentRoute === "/Articles"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/Articles" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/Articles" className="me-2">
                      {currentRoute === "/Articles" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                        >
                          <path
                            fill="#fff"
                            d="M22.006 11h-12v3h12v-3Zm-3 9h3v2h-3v-2ZM9 4a5 5 0 0 0-5 5v14a5 5 0 0 0 5 5h14a5 5 0 0 0 5-5V9a5 5 0 0 0-5-5H9Zm-.994 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2v-3Zm11 7h3a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2Zm-11 1a1 1 0 0 1 1-1h5a1 1 0 0 1 0 2h-5a1 1 0 0 1-1-1Zm1 3h5a1 1 0 0 1 0 2h-5a1 1 0 1 1 0-2Z"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                        >
                          <path
                            fill="#055d9d"
                            d="M22.006 11h-12v3h12v-3Zm-3 9h3v2h-3v-2ZM9 4a5 5 0 0 0-5 5v14a5 5 0 0 0 5 5h14a5 5 0 0 0 5-5V9a5 5 0 0 0-5-5H9Zm-.994 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2v-3Zm11 7h3a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2Zm-11 1a1 1 0 0 1 1-1h5a1 1 0 0 1 0 2h-5a1 1 0 0 1-1-1Zm1 3h5a1 1 0 0 1 0 2h-5a1 1 0 1 1 0-2Z"
                          />
                        </svg>
                      )}
                    </Link>
                    Articles
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/Articles" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#fff"
                          d="M12 14v8H4a8 8 0 0 1 8-8Zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6s6 2.685 6 6s-2.685 6-6 6Zm9 4h1v5h-8v-5h1v-1a3 3 0 1 1 6 0v1Zm-2 0v-1a1 1 0 1 0-2 0v1h2Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#055d9d"
                          d="M12 14v8H4a8 8 0 0 1 8-8Zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6s6 2.685 6 6s-2.685 6-6 6Zm9 4h1v5h-8v-5h1v-1a3 3 0 1 1 6 0v1Zm-2 0v-1a1 1 0 1 0-2 0v1h2Z"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </Link>
            </div>

            {/* push notification */}
            <div
              className={
                currentRoute === "/PushNotifications"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/PushNotifications" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/PushNotifications" className="me-2">
                      {currentRoute === "/PushNotifications" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                        >
                          <path
                            fill="#fff"
                            d="M14.423 12.17a5.233 5.233 0 0 1-3.102 1.03c-1.163 0-2.23-.39-3.103-1.03c-.75.625-1.498 1.52-2.11 2.623c-1.423 2.563-1.58 5.192-.35 5.874c.548.312 1.126.078 1.722-.496a10.51 10.51 0 0 0-.167 1.874c0 2.938 1.14 5.312 2.543 5.312c.846 0 1.265-.865 1.466-2.188c.2 1.314.62 2.188 1.46 2.188c1.397 0 2.546-2.375 2.546-5.312c0-.66-.062-1.29-.168-1.873c.6.575 1.176.813 1.726.497c1.227-.682 1.068-3.31-.354-5.874c-.61-1.104-1.36-1.998-2.11-2.623zm-3.103.03a4.279 4.279 0 1 0-.003-8.561a4.279 4.279 0 0 0 .003 8.563zm10.667 5.47c1.508 0 2.732-1.224 2.732-2.734S23.493 12.2 21.986 12.2a2.737 2.737 0 0 0-2.736 2.736a2.737 2.737 0 0 0 2.737 2.735zm3.33 1.657c-.39-.705-.868-1.277-1.348-1.677c-.56.41-1.24.66-1.983.66c-.744 0-1.426-.25-1.983-.66c-.48.4-.958.972-1.35 1.677c-.91 1.638-1.01 3.318-.224 3.754c.35.2.72.05 1.1-.316a6.874 6.874 0 0 0-.104 1.197c0 1.88.728 3.397 1.625 3.397c.54 0 .81-.553.938-1.398c.128.84.396 1.397.934 1.397c.893 0 1.627-1.518 1.627-3.396c0-.42-.04-.824-.108-1.196c.383.367.752.52 1.104.317c.782-.434.68-2.115-.228-3.753z"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                        >
                          <path
                            fill="#055d9d"
                            d="M14.423 12.17a5.233 5.233 0 0 1-3.102 1.03c-1.163 0-2.23-.39-3.103-1.03c-.75.625-1.498 1.52-2.11 2.623c-1.423 2.563-1.58 5.192-.35 5.874c.548.312 1.126.078 1.722-.496a10.51 10.51 0 0 0-.167 1.874c0 2.938 1.14 5.312 2.543 5.312c.846 0 1.265-.865 1.466-2.188c.2 1.314.62 2.188 1.46 2.188c1.397 0 2.546-2.375 2.546-5.312c0-.66-.062-1.29-.168-1.873c.6.575 1.176.813 1.726.497c1.227-.682 1.068-3.31-.354-5.874c-.61-1.104-1.36-1.998-2.11-2.623zm-3.103.03a4.279 4.279 0 1 0-.003-8.561a4.279 4.279 0 0 0 .003 8.563zm10.667 5.47c1.508 0 2.732-1.224 2.732-2.734S23.493 12.2 21.986 12.2a2.737 2.737 0 0 0-2.736 2.736a2.737 2.737 0 0 0 2.737 2.735zm3.33 1.657c-.39-.705-.868-1.277-1.348-1.677c-.56.41-1.24.66-1.983.66c-.744 0-1.426-.25-1.983-.66c-.48.4-.958.972-1.35 1.677c-.91 1.638-1.01 3.318-.224 3.754c.35.2.72.05 1.1-.316a6.874 6.874 0 0 0-.104 1.197c0 1.88.728 3.397 1.625 3.397c.54 0 .81-.553.938-1.398c.128.84.396 1.397.934 1.397c.893 0 1.627-1.518 1.627-3.396c0-.42-.04-.824-.108-1.196c.383.367.752.52 1.104.317c.782-.434.68-2.115-.228-3.753z"
                          />
                        </svg>
                      )}
                    </Link>
                    Push Notifications
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/PushNotifications" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="#fff"
                          d="M14.423 12.17a5.233 5.233 0 0 1-3.102 1.03c-1.163 0-2.23-.39-3.103-1.03c-.75.625-1.498 1.52-2.11 2.623c-1.423 2.563-1.58 5.192-.35 5.874c.548.312 1.126.078 1.722-.496a10.51 10.51 0 0 0-.167 1.874c0 2.938 1.14 5.312 2.543 5.312c.846 0 1.265-.865 1.466-2.188c.2 1.314.62 2.188 1.46 2.188c1.397 0 2.546-2.375 2.546-5.312c0-.66-.062-1.29-.168-1.873c.6.575 1.176.813 1.726.497c1.227-.682 1.068-3.31-.354-5.874c-.61-1.104-1.36-1.998-2.11-2.623zm-3.103.03a4.279 4.279 0 1 0-.003-8.561a4.279 4.279 0 0 0 .003 8.563zm10.667 5.47c1.508 0 2.732-1.224 2.732-2.734S23.493 12.2 21.986 12.2a2.737 2.737 0 0 0-2.736 2.736a2.737 2.737 0 0 0 2.737 2.735zm3.33 1.657c-.39-.705-.868-1.277-1.348-1.677c-.56.41-1.24.66-1.983.66c-.744 0-1.426-.25-1.983-.66c-.48.4-.958.972-1.35 1.677c-.91 1.638-1.01 3.318-.224 3.754c.35.2.72.05 1.1-.316a6.874 6.874 0 0 0-.104 1.197c0 1.88.728 3.397 1.625 3.397c.54 0 .81-.553.938-1.398c.128.84.396 1.397.934 1.397c.893 0 1.627-1.518 1.627-3.396c0-.42-.04-.824-.108-1.196c.383.367.752.52 1.104.317c.782-.434.68-2.115-.228-3.753z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="#055d9d"
                          d="M14.423 12.17a5.233 5.233 0 0 1-3.102 1.03c-1.163 0-2.23-.39-3.103-1.03c-.75.625-1.498 1.52-2.11 2.623c-1.423 2.563-1.58 5.192-.35 5.874c.548.312 1.126.078 1.722-.496a10.51 10.51 0 0 0-.167 1.874c0 2.938 1.14 5.312 2.543 5.312c.846 0 1.265-.865 1.466-2.188c.2 1.314.62 2.188 1.46 2.188c1.397 0 2.546-2.375 2.546-5.312c0-.66-.062-1.29-.168-1.873c.6.575 1.176.813 1.726.497c1.227-.682 1.068-3.31-.354-5.874c-.61-1.104-1.36-1.998-2.11-2.623zm-3.103.03a4.279 4.279 0 1 0-.003-8.561a4.279 4.279 0 0 0 .003 8.563zm10.667 5.47c1.508 0 2.732-1.224 2.732-2.734S23.493 12.2 21.986 12.2a2.737 2.737 0 0 0-2.736 2.736a2.737 2.737 0 0 0 2.737 2.735zm3.33 1.657c-.39-.705-.868-1.277-1.348-1.677c-.56.41-1.24.66-1.983.66c-.744 0-1.426-.25-1.983-.66c-.48.4-.958.972-1.35 1.677c-.91 1.638-1.01 3.318-.224 3.754c.35.2.72.05 1.1-.316a6.874 6.874 0 0 0-.104 1.197c0 1.88.728 3.397 1.625 3.397c.54 0 .81-.553.938-1.398c.128.84.396 1.397.934 1.397c.893 0 1.627-1.518 1.627-3.396c0-.42-.04-.824-.108-1.196c.383.367.752.52 1.104.317c.782-.434.68-2.115-.228-3.753z"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </Link>
            </div>

            {/* marketing campaign */}
            <div
              className={
                currentRoute === "/MarketingCampaigns"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/MarketingCampaigns" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/MarketingCampaigns" className="me-2">
                      {currentRoute === "/MarketingCampaigns" ? (
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#FFF"
                          d="m23.5 17l-5 5l-3.5-3.5l1.5-1.5l2 2l3.5-3.5l1.5 1.5M6 2c-1.11 0-2 .89-2 2v16c0 1.11.89 2 2 2h7.81c-.53-.91-.81-1.95-.81-3c0-.33.03-.67.08-1H6v-2h7.81c.46-.8 1.1-1.5 1.87-2H6v-2h12v1.08c.33-.05.67-.08 1-.08c.34 0 .67.03 1 .08V8l-6-6m-1 1.5L18.5 9H13Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#055d9d"
                          d="m23.5 17l-5 5l-3.5-3.5l1.5-1.5l2 2l3.5-3.5l1.5 1.5M6 2c-1.11 0-2 .89-2 2v16c0 1.11.89 2 2 2h7.81c-.53-.91-.81-1.95-.81-3c0-.33.03-.67.08-1H6v-2h7.81c.46-.8 1.1-1.5 1.87-2H6v-2h12v1.08c.33-.05.67-.08 1-.08c.34 0 .67.03 1 .08V8l-6-6m-1 1.5L18.5 9H13Z"
                        />
                      </svg>
                      )}
                    </Link>
                    Marketing Campaigns
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/MarketingCampaigns" ? (
                      <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#FFF"
                        d="m23.5 17l-5 5l-3.5-3.5l1.5-1.5l2 2l3.5-3.5l1.5 1.5M6 2c-1.11 0-2 .89-2 2v16c0 1.11.89 2 2 2h7.81c-.53-.91-.81-1.95-.81-3c0-.33.03-.67.08-1H6v-2h7.81c.46-.8 1.1-1.5 1.87-2H6v-2h12v1.08c.33-.05.67-.08 1-.08c.34 0 .67.03 1 .08V8l-6-6m-1 1.5L18.5 9H13Z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#055d9d"
                        d="m23.5 17l-5 5l-3.5-3.5l1.5-1.5l2 2l3.5-3.5l1.5 1.5M6 2c-1.11 0-2 .89-2 2v16c0 1.11.89 2 2 2h7.81c-.53-.91-.81-1.95-.81-3c0-.33.03-.67.08-1H6v-2h7.81c.46-.8 1.1-1.5 1.87-2H6v-2h12v1.08c.33-.05.67-.08 1-.08c.34 0 .67.03 1 .08V8l-6-6m-1 1.5L18.5 9H13Z"
                      />
                    </svg>
                    )}
                  </div>
                )}
              </Link>
            </div>

            {/* ad campaign */}
            <div
              className={
                currentRoute === "/AdCampaigns"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/AdCampaigns" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/AdCampaigns" className="me-2">
                      {currentRoute === "/AdCampaigns" ? (
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="#fff"
                          d="M22.006 11h-12v3h12v-3Zm-3 9h3v2h-3v-2ZM9 4a5 5 0 0 0-5 5v14a5 5 0 0 0 5 5h14a5 5 0 0 0 5-5V9a5 5 0 0 0-5-5H9Zm-.994 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2v-3Zm11 7h3a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2Zm-11 1a1 1 0 0 1 1-1h5a1 1 0 0 1 0 2h-5a1 1 0 0 1-1-1Zm1 3h5a1 1 0 0 1 0 2h-5a1 1 0 1 1 0-2Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="#055d9d"
                          d="M22.006 11h-12v3h12v-3Zm-3 9h3v2h-3v-2ZM9 4a5 5 0 0 0-5 5v14a5 5 0 0 0 5 5h14a5 5 0 0 0 5-5V9a5 5 0 0 0-5-5H9Zm-.994 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2v-3Zm11 7h3a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2Zm-11 1a1 1 0 0 1 1-1h5a1 1 0 0 1 0 2h-5a1 1 0 0 1-1-1Zm1 3h5a1 1 0 0 1 0 2h-5a1 1 0 1 1 0-2Z"
                        />
                      </svg>
                      )}
                    </Link>
                    Ad Campaigns
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/AdCampaigns" ? (
                      <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#fff"
                        d="M12 14v8H4a8 8 0 0 1 8-8Zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6s6 2.685 6 6s-2.685 6-6 6Zm9 4h1v5h-8v-5h1v-1a3 3 0 1 1 6 0v1Zm-2 0v-1a1 1 0 1 0-2 0v1h2Z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#055d9d"
                        d="M12 14v8H4a8 8 0 0 1 8-8Zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6s6 2.685 6 6s-2.685 6-6 6Zm9 4h1v5h-8v-5h1v-1a3 3 0 1 1 6 0v1Zm-2 0v-1a1 1 0 1 0-2 0v1h2Z"
                      />
                    </svg>
                    )}
                  </div>
                )}
              </Link>
            </div>

            {/* Content Management */}

            <div
              className={
                currentRoute === "/AboutUs" ||
                currentRoute === "/PravicyPolicy" ||
                currentRoute === "/TermsConditions"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link
                to="#"
                className="icon-menu-name"
                onClick={() => setCmanagement(!Cmanagement)}
              >
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="#" className="me-2">
                      {currentRoute === "/AboutUs" ||
                      currentRoute === "/PravicyPolicy" ||
                      currentRoute === "/TermsConditions" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                        >
                          <path
                            fill="#fff"
                            d="M22.006 11h-12v3h12v-3Zm-3 9h3v2h-3v-2ZM9 4a5 5 0 0 0-5 5v14a5 5 0 0 0 5 5h14a5 5 0 0 0 5-5V9a5 5 0 0 0-5-5H9Zm-.994 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2v-3Zm11 7h3a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2Zm-11 1a1 1 0 0 1 1-1h5a1 1 0 0 1 0 2h-5a1 1 0 0 1-1-1Zm1 3h5a1 1 0 0 1 0 2h-5a1 1 0 1 1 0-2Z"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                        >
                          <path
                            fill="#055d9d"
                            d="M22.006 11h-12v3h12v-3Zm-3 9h3v2h-3v-2ZM9 4a5 5 0 0 0-5 5v14a5 5 0 0 0 5 5h14a5 5 0 0 0 5-5V9a5 5 0 0 0-5-5H9Zm-.994 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2v-3Zm11 7h3a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2Zm-11 1a1 1 0 0 1 1-1h5a1 1 0 0 1 0 2h-5a1 1 0 0 1-1-1Zm1 3h5a1 1 0 0 1 0 2h-5a1 1 0 1 1 0-2Z"
                          />
                        </svg>
                      )}
                    </Link>
                    Content Management
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/AboutUs" ||
                    currentRoute === "/PravicyPolicy" ||
                    currentRoute === "/TermsConditions" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="#fff"
                          d="M22.006 11h-12v3h12v-3Zm-3 9h3v2h-3v-2ZM9 4a5 5 0 0 0-5 5v14a5 5 0 0 0 5 5h14a5 5 0 0 0 5-5V9a5 5 0 0 0-5-5H9Zm-.994 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2v-3Zm11 7h3a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2Zm-11 1a1 1 0 0 1 1-1h5a1 1 0 0 1 0 2h-5a1 1 0 0 1-1-1Zm1 3h5a1 1 0 0 1 0 2h-5a1 1 0 1 1 0-2Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="#055d9d"
                          d="M22.006 11h-12v3h12v-3Zm-3 9h3v2h-3v-2ZM9 4a5 5 0 0 0-5 5v14a5 5 0 0 0 5 5h14a5 5 0 0 0 5-5V9a5 5 0 0 0-5-5H9Zm-.994 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2v-3Zm11 7h3a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2Zm-11 1a1 1 0 0 1 1-1h5a1 1 0 0 1 0 2h-5a1 1 0 0 1-1-1Zm1 3h5a1 1 0 0 1 0 2h-5a1 1 0 1 1 0-2Z"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </Link>
            </div>
            {(sidebar && Cmanagement) == true ? (
              <div className="sub-menu-main">
                <Link to="/AboutUs" className="sub-menu-item">
                  <div
                    className={
                      currentRoute === "/AboutUs"
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    About Us
                  </div>
                </Link>

                <Link to="/PravicyPolicy" className="sub-menu-item">
                  <div
                    className={
                      currentRoute === "/PravicyPolicy"
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    Privacy Policy
                  </div>
                </Link>

                <Link to="/TermsConditions" className="sub-menu-item">
                  <div
                    className={
                      currentRoute === "/TermsConditions"
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    Terms & Conditions
                  </div>
                </Link>
              </div>
            ) : (
              ""
            )}

            {/* Setting */}
            {/* <div
              className={
                currentRoute === "/GoogleAnalytics" ||
                currentRoute === "/PushNotification"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link
                to="#"
                className="icon-menu-name"
                onClick={() => setsetting(!setting)}
              >
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="#" className="me-2">
                      {currentRoute === "/GoogleAnalytics" ||
                      currentRoute === "/PushNotification" ? (
                        <img src={ic_setting_active} alt="" />
                      ) : (
                        <img src={ic_setting} alt="" />
                      )}
                    </Link>
                    Setting
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/GoogleAnalytics" ||
                    currentRoute === "/PushNotification" ? (
                      <img src={ic_setting_active} alt="" />
                    ) : (
                      <img src={ic_setting} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div> */}
            {(sidebar && setting) == true ? (
              <div className="sub-menu-main">
                <Link to="/GoogleAnalytics" className="sub-menu-item">
                  <div
                    className={
                      currentRoute === "/GoogleAnalytics"
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    Google Analytics
                  </div>
                </Link>

                <Link to="/PushNotification" className="sub-menu-item">
                  <div
                    className={
                      currentRoute === "/PushNotification"
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    Push Notification
                  </div>
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
        </aside>
      </div>
    </main>
  );
};

export default Sidebar;
