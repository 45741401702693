import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/styles/toggle.scss";
//Datatable Modules
import pdfPreview from '../assets/images/pdf.png'
import { Link, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import card from "../assets/images/card.svg";
import sara from "../assets/images/sara.svg";
import {
  putWithAuthCall,
  postWithAuthCall,
  simpleGetCallWithErrorResponse,
  userDelete,
} from "../api/ApiServices";
import ApiConfig, { BASE_URL } from "../api/ApiConfig";
import { ToastMsg } from "./ToastMsg";
import Loader from "./Loader";
import redDelete from "../assets/images/redDelete.svg";

const ViewUsers = () => {
  const params = useParams();
  const navigate = useNavigate();
  let id = params.id;
  var fromDocuments = localStorage.getItem("fromDocuments");
  const userDataLoc = localStorage.getItem("IsUserUnblock");
  const deleteUser = localStorage.getItem("IsDeleteUser") === "true";
  const blockUser = localStorage.getItem("IsBlockUser") === "true";

  const [userDetails, setUserDetails] = useState({
    userDetailsVerification: {},
  });
  const [loading, setLoading] = useState(false);
  const { sidebar, document, userData } = useContext(AppContext);
  const [docPayload, setDocPaylod] = useState({
    profileId: "",
    verify: "",
    docType: "",
  });

  const [passView, setPassView] = useState();

  const [confirmModel, setConfirmModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [verified, setVerified] = useState(null);
  const [blocked, setBlocked] = useState(false);

  const [verifyDoc, setVerifyDoc] = useState("");

  const [verifyLicen, setVerifyLicen] = useState(true);

  const [viewModal, setView] = useState(false);
  const [modalPreviewImage, setModalPreviewImage] = useState(null);
  const [confirmVerifyAction, setConfirmVerifyAction] = useState(null);

  const handleViewClose = () => setView(false);

  const [profileMediaPreview, setProfileMediaPreview] = useState();
  const [mediaModal, setMediaModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [mediaType, setMediaType] = useState("");

  const handleViewImage = (title, media, type) => {
    setModalTitle(title);
    setProfileMediaPreview(media);
    setMediaType(type);
    setMediaModal(true);
  };

  const handleViewImageClose = () => setMediaModal(false);

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  useEffect(() => {
    setLoading(true);
    simpleGetCallWithErrorResponse(`${ApiConfig.USER_DETAILS}/${id}`)
      .then((response) => {
        const data = response.json.data;
        if (data) {
          setUserDetails(data.user);

          if (
            data.user != null &&
            data.user.verification != null &&
            data.user.verification.verified
          ) {
            setVerified(true);
          }

          setBlocked(data.user != null && data.user.blocked == true);
        }

        // setCitizenship(response?.json.data[0]?.citizenship_documents[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const additionalLang = userDetails?.language;

  const CalculateAge =
    new Date().getFullYear() - userDetails?.dateOfBirth?.slice(0, 4);

  // BlockUnblockApi
  const BlockUnblockApi = (id, block) => {
    putWithAuthCall(ApiConfig.USER_UNBLOCK, JSON.stringify(id, block)).then(
      (response) => {
        if (response.error) {
          ToastMsg("error", response.message);
        } else if (response.success === true) {
          ToastMsg("success", response.message);
        }
      }
    );
  };

  // VerifyDocApi

  const VerifyDocApi = (docPayload) => {
    putWithAuthCall(ApiConfig.VERIFY_DOC, JSON.stringify(docPayload)).then(
      (response) => {
        if (response.error) {
          ToastMsg("error", response.message);
        } else if (response.success == true) {
          ToastMsg("success", response.message);

          if (verifyDoc == "pt") {
            setVerified(true);
          } else if (verifyDoc == "dl") {
            setVerifyLicen(true);
          } else {
          }
        }
      }
    );
  };

  // delete user
  const DeletedUserApi = (userID) => {
    userDelete(
      BASE_URL + "/account/deleteUser",
      JSON.stringify({ _id: userID })
    ).then((response) => {
      console.log(response);
      if (response.message === "User deleted successfully") {
        ToastMsg("success", response.message);
        navigate("/Users");
      } else {
        ToastMsg("error", response.message);
      }
    });
  };

  const BlockUser = (blocked) => {
    postWithAuthCall(
      ApiConfig.BLOCK_USER + `/${id}`,
      JSON.stringify({ blocked: blocked })
    ).then((response) => {
      if (response.error) {
        ToastMsg("error", response.message);
      } else {
        ToastMsg("success", response.message);
        setVerified(blocked);
      }
    });
  };

  const verifyUserDetails = async (e) => {
    e.preventDefault();
    console.log("update user details", userDetails.userDetailsVerification);
    postWithAuthCall(
      BASE_URL + `/admin/verify-user-details/${id}`,
      JSON.stringify(userDetails)
    ).then((response) => {
      if (response.error) {
        ToastMsg("error", response.message);
      } else {
        ToastMsg("success", response.message);
        // setVerified(blocked);
      }
    });
  };

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <Link to="/Users" className="back-btns">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
            >
              <path
                fill="#E40288"
                d="M20 11v2H8l5.5 5.5l-1.42 1.42L4.16 12l7.92-7.92L13.5 5.5L8 11h12Z"
              />
            </svg>
          </Link>
          <label htmlFor="">
            {" "}
            {fromDocuments == "fromDocuments"
              ? "Documents"
              : "User Details"}{" "}
          </label>
        </div>
      </div>

      {/*==================== start user delete model======================*/}
      <Modal
        show={deleteModel}
        onHide={() => setDeleteModel(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to Delete this User?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              type="submit"
              onClick={() => {
                DeletedUserApi(userDetails._id);
                setDeleteModel(false);
              }}
            >
              Yes
            </button>
            <Link
              to="#"
              className=""
              onClick={() => {
                setDeleteModel(false);
              }}
            >
              No
            </Link>
          </div>
        </Modal.Footer>
      </Modal>

      {/*========================end user delete model=============*/}

      <Modal
        show={confirmModel}
        onHide={() => setConfirmModel(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">
              Are you sure you want to{" "}
              {confirmVerifyAction ? "Verify" : "Reject"} this User?
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              type="submit"
              onClick={() => {
                VerifyDocApi(docPayload);
                setConfirmModel(false);
              }}
            >
              Yes
            </button>
            <Link
              to="#"
              className=""
              onClick={() => {
                setConfirmModel(false);
              }}
            >
              No
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
      {/* ======================= END DELETE ========================== */}

      {/* ======================= VIEW ========================== */}

      <Modal
        show={mediaModal}
        onHide={handleViewImageClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="document-preview-wrapper">
              {mediaType === "image" ? (
                <img
                  width={300}
                  src={profileMediaPreview ?? card}
                  alt={modalTitle}
                />
              ) : (
                <video
                  controls
                  style={{ height: "250px", width: "100%", borderRadius: "0%" }}
                >
                  <source src={profileMediaPreview} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <Link to="#" className="bg-danger" onClick={handleViewImageClose}>
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END VIEW ========================== */}
      {/* ======================= VIEW ========================== */}

      <Modal
        show={viewModal}
        onHide={handleViewClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {passView == true ? "PassPort" : "Licence"}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="document-preview-wrapper">
              <img width={300} src={modalPreviewImage ?? card} />
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <Link to="#" className="bg-danger" onClick={handleViewClose}>
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* ======================= END VIEW ========================== */}

      <div className="documents-wrapper user-details-main-card">
        {loading ? (
          <div style={{ marginTop: "150px" }}>
            <Loader />
          </div>
        ) : (
          <div className="  user-detail-card">
            <div className="document-cards">
              <div className="paln-card p">
                <div className="prf-img">
                  <img
                    src={
                      !userDetails.profilePicture
                        ? sara
                        : userDetails.profilePicture
                    }
                    alt=""
                    style={{ height: "90px", borderRadius: "50%" }}
                  />
                </div>
              </div>
              <div
                className="active-plan prf-name"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <label htmlFor="" className="value ms-0">
                  {userDetails.fullName} , {CalculateAge} Years
                </label>
                <div className="d-flex justify-content-end">
                  {blockUser && (
                    <div
                      style={{
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      Block User
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={blocked}
                          onChange={(e) => {
                            setBlocked((prev) => !prev);
                            BlockUser(!blocked);
                          }}
                        />
                        <span
                          className="slider round"
                          style={{
                            backgroundColor: blocked ? "#ff0000" : null,
                          }}
                        ></span>
                      </label>
                    </div>
                  )}

                  {deleteUser && (
                    <div
                      style={{
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Link
                        to="#"
                        className="icon edit text-end ms-2"
                        onClick={() => {
                          setDeleteModel(true);
                        }}
                      >
                        <img
                          src={redDelete}
                          className="text-end"
                          alt=""
                          style={{ height: "50px !important" }}
                        />
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="u-details-inner-card row mx-2 my-3">
                <h3>Basic Information</h3>

                <div className="col-md-6">
                  <div className="active-plan">
                  <label htmlFor="" className="key">
                      Height
                    </label>
                    :
                    <label htmlFor="" className="value">
                      {userDetails?.height}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="active-plan">
                  <label htmlFor="" className="key">
                      Job Title
                    </label>
                    :
                    <label htmlFor="" className="value">
                      {userDetails.employee
                        ? userDetails.employee.jobTitle
                        : ""}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="active-plan">
                  <label htmlFor="" className="key">
                      Date of Birth
                    </label>
                    :
                    <label htmlFor="" className="value">
                      {userDetails?.dateOfBirth?.slice(0, 10)}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="active-plan">
                  <label htmlFor="" className="key">
                      Income
                    </label>
                    :
                    <label htmlFor="" className="value">
                      {userDetails.employee ? userDetails.employee.income : ""}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="active-plan">
                  <label htmlFor="" className="key">
                      Sector
                    </label>
                    :
                    <label htmlFor="" className="value">
                      {userDetails.regionalDetails
                        ? userDetails.regionalDetails.sect_Id
                        : ""}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="active-plan">
                  <label htmlFor="" className="key">
                     Education Level / Subject
                    </label>
                    :
                    <label htmlFor="" className="value">
                      {userDetails.education ? userDetails.education.level : ""}{" "}
                      /{" "}
                      {userDetails.education
                        ? userDetails.education.subject
                        : ""}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="active-plan">
                  <label htmlFor="" className="key">
                      Angola
                    </label>
                    :
                    <label htmlFor="" className="value">
                      {userDetails.country}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="active-plan">
                  <label htmlFor="" className="key">
                      Marital Status
                    </label>
                    :
                    <label htmlFor="" className="value">
                      {userDetails.marital_status}
                    </label>
                  </div>
                </div>
              </div>
              <div className="u-details-inner-card row mx-2 my-3">
                <h3>About Me</h3>

                <div className="col-md-12">
                  <div className="active-plan">
                    <p className="key">{userDetails.about}</p>
                  </div>
                </div>
              </div>
              <div className="u-details-inner-card row mx-2 my-3">
                <h3>Reginal</h3>

                <div className="col-md-6">
                  <div className="active-plan">
                    <label htmlFor="" className="key">
                      Sector
                    </label>
                    :
                    <label htmlFor="" className="value">
                      {userDetails.regionalDetails
                        ? userDetails.regionalDetails.sect_Id
                        : "Not Set"}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="active-plan">
                    <label htmlFor="" className="key">
                      Level Of Religiousness
                    </label>
                    :
                    <label htmlFor="" className="value">
                      {userDetails.regionalDetails
                        ? userDetails.regionalDetails.level_of_religiousness_Id
                        : "Not Set"}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="active-plan">
                    <label htmlFor="" className="key">
                      Pray
                    </label>
                    :
                    <label htmlFor="" className="value">
                      {userDetails.regionalDetails
                        ? userDetails.regionalDetails.prayer_Id
                        : "Not Set"}
                    </label>
                  </div>
                </div>
              </div>
              <div className="u-details-inner-card row mx-2 my-3">
                <h3>Eating & Drinking Habits</h3>

                <div className="col-md-6">
                  <div className="active-plan">
                    <label htmlFor="" className="key">
                      Drink
                    </label>
                    :
                    <label htmlFor="" className="value">
                      {userDetails.habits
                        ? userDetails.habits.drink_Id
                        : "Not Set"}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="active-plan">
                    <label htmlFor="" className="key">
                      Smoke
                    </label>
                    :
                    <label htmlFor="" className="value">
                      {userDetails.habits
                        ? userDetails.habits.smoke_Id
                        : "Not Set"}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="active-plan">
                    <label htmlFor="" className="key">
                      Halal
                    </label>
                    :
                    <label htmlFor="" className="value">
                      {userDetails.habits
                        ? userDetails.habits.halal_Id
                        : "Not Set"}
                    </label>
                  </div>
                </div>
              </div>

              <div className="u-details-inner-card row mx-2 my-3">
                <h3>Marital Status</h3>

                <div className="col-md-6">
                  <div className="active-plan">
                    <label htmlFor="" className="key">
                      Marital Status
                    </label>
                    :
                    <label htmlFor="" className="value">
                      {userDetails.maritalStatus
                        ? userDetails.maritalStatus.marital_status_Id
                        : "Not Set"}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="active-plan">
                    <label htmlFor="" className="key">
                      Prenup
                    </label>
                    :
                    <label htmlFor="" className="value">
                      {userDetails.maritalStatus
                        ? userDetails.maritalStatus.prenup_Id
                        : "Not Set"}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="active-plan">
                    <label htmlFor="" className="key">
                      Kids
                    </label>
                    :
                    <label htmlFor="" className="value">
                      {userDetails.maritalStatus
                        ? userDetails.maritalStatus.is_kids
                        : "Not Set"}
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="active-plan">
                    <label htmlFor="" className="key">
                      Number of Kids want
                    </label>
                    :
                    <label htmlFor="" className="value">
                      {userDetails.maritalStatus
                        ? userDetails.maritalStatus.how_many_kids
                        : "Not Set"}
                    </label>
                  </div>
                </div>
              </div>

              <div className="u-details-inner-card row mx-2 my-3">
                <h3>Languages</h3>

                <div className="col-md-6">
                  <div className="active-plan">
                    <label htmlFor="" className="key">
                      Mother Tongue
                    </label>
                    :
                    <label htmlFor="" className="value">
                      {userDetails.language?.firstLanguage}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="active-plan">
                    <label htmlFor="" className="key">
                      Additional Languages
                    </label>
                    :
                    {userDetails.language?.additionalLanguages.map((language, index)=>(
                      <label htmlFor="" className="value" key={index}>
                      {language}
                    </label>
                    ))}
                    {/* <label htmlFor="" className="value">
                      {additionalLang?.additionalLanguages}
                    </label> */}
                  </div>
                </div>
              </div>
              <div className="u-details-inner-card row mx-2 my-3">
                <h3>Social</h3>

                <div className="col-md-6">
                  <div className="active-plan">
                    <label htmlFor="" className="key">
                      Linkedin Profile
                    </label>
                    :
                    <label htmlFor="" className="value">
                      {userDetails.linkedin}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="active-plan">
                    <label htmlFor="" className="key">
                      Instagram Profile
                    </label>
                    :
                    <label htmlFor="" className="value">
                      {userDetails.instagram}
                    </label>
                  </div>
                </div>
              </div>

              {userDetails.profilePicture && (
                <div className="u-details-inner-card row mx-2 my-3">
                  <h3>Profile Picture</h3>

                  <div className="col-md-12">
                    <div
                      className="active-plan"
                      onClick={() =>
                        handleViewImage(
                          "Profile Picture",
                          userDetails.profilePicture,
                          "image"
                        )
                      }
                    >
                      <img
                        src={userDetails.profilePicture}
                        alt=""
                        style={{ height: "90px", borderRadius: "0%" }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {userDetails.secondaryProfilePictures &&
                userDetails.secondaryProfilePictures.length > 0 && (
                  <div className="u-details-inner-card row mx-2 my-3">
                    <h3>Secondary Profile Pictures</h3>
                    {userDetails.secondaryProfilePictures.map((user, index) => (
                      <div className="col-md-3" key={index}>
                        <div
                          className="active-plan"
                          onClick={() =>
                            handleViewImage(
                              "Secondary Profile Picture",
                              user,
                              "image"
                            )
                          }
                        >
                          <img
                            src={user}
                            alt=""
                            style={{ height: "90px", borderRadius: "0%" }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}

              {userDetails.profileVideo && (
                <div className="u-details-inner-card row mx-2 my-3">
                  <h3>Profile Video</h3>

                  <div className="col-md-12">
                    <div
                      className="active-plan"
                      onClick={() =>
                        handleViewImage(
                          "Profile Video",
                          userDetails.profileVideo,
                          "video"
                        )
                      }
                    >
                      <video
                        controls
                        style={{
                          height: "250px",
                          width: "250px",
                          borderRadius: "0%",
                        }}
                      >
                        <source
                          src={userDetails.profileVideo}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                </div>
              )}

              {userDetails.secondaryprofileVideo &&
                userDetails.secondaryprofileVideo.length > 0 && (
                  <div className="u-details-inner-card row mx-2 my-3">
                    <h3>Secondary Profile Videos</h3>
                    {userDetails.secondaryprofileVideo.map((video, index) => (
                      <div className="col-md-3" key={index}>
                        <div
                          className="active-plan"
                          onClick={() =>
                            handleViewImage("Profile Video", video, "video")
                          }
                        >
                          <video
                            controls
                            style={{
                              // height: "250px",
                              width: "150px",
                              borderRadius: "0%",
                            }}
                          >
                            <source src={video} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

              {userDataLoc === "true" && (
                <div className="mt-4 text-end">
                  {verified ? (
                    <button
                      className="btn bg-danger text-white"
                      // disabled={userData.UserUnblocked === "true" ? true : false}
                      onClick={() => {
                        BlockUnblockApi({
                          userId: userDetails.phoneNumber,
                          block: true,
                        });
                        setVerified(false);
                        setVerifyLicen(true);
                      }}
                    >
                      Block
                    </button>
                  ) : (
                    <button
                      className="btn bg-danger text-white"
                      disabled={true}
                    >
                      Blocked
                    </button>
                  )}
                  {verifyLicen ? (
                    <button
                      className="btn bg-success text-white ms-2"
                      // disabled={userData.UserUnblocked ? true : false}
                      onClick={() => {
                        BlockUnblockApi({
                          userId: userDetails.phoneNumber,
                          block: false,
                        });
                        setVerified(true);
                        setVerifyLicen(false);
                      }}
                    >
                      Unblock
                    </button>
                  ) : (
                    <button
                      className="btn bg-primary text-white"
                      disabled={true}
                    >
                      Unblocked
                    </button>
                  )}
                </div>
              )}
            </div>

            <div className="documents-wrapper">
              <div className="row g-3">
                {userDetails.verification &&
                userDetails.verification.citizenshipPassport ? (
                  <VerificationCard
                    id={id}
                    type={"citizenshipPassport"}
                    image={userDetails.verification.citizenshipPassport}
                    title="Passport"
                    verified={
                      userDetails.verification.citizenshipPassportVerified
                    }
                  />
                ) : (
                  <></>
                )}

                {userDetails.verification &&
                userDetails.verification.citizenshipDrivingLicense ? (
                  <VerificationCard
                    id={id}
                    type={"citizenshipDrivingLicense"}
                    image={userDetails.verification.citizenshipDrivingLicense}
                    title="License"
                    verified={
                      userDetails.verification.citizenshipDrivingLicenseVerified
                    }
                  />
                ) : (
                  <></>
                )}

                {userDetails.verification &&
                userDetails.verification.employementProof ? (
                  <VerificationCard
                    id={id}
                    type={"employementProof"}
                    image={userDetails.verification.employementProof}
                    title="Proof of Emplyement"
                    verified={userDetails.verification.employementProofVerified}
                  />
                ) : (
                  <></>
                )}

                {userDetails.verification &&
                userDetails.verification.employementWageSlip ? (
                  <VerificationCard
                    id={id}
                    type={"employementWageSlip"}
                    image={userDetails.verification.employementWageSlip}
                    title="Wage Slip"
                    verified={
                      userDetails.verification.employementWageSlipVerified
                    }
                  />
                ) : (
                  <></>
                )}

                {userDetails.verification &&
                userDetails.verification.educationUniAcceptenceLetter ? (
                  <VerificationCard
                    id={id}
                    type={"educationUniAcceptenceLetter"}
                    image={
                      userDetails.verification.educationUniAcceptenceLetter
                    }
                    title="University Acceptence Letter"
                    verified={
                      userDetails.verification
                        .educationUniAcceptenceLetterVerified
                    }
                  />
                ) : (
                  <></>
                )}

                {userDetails.verification &&
                userDetails.verification.educationProof ? (
                  <VerificationCard
                    id={id}
                    type={"educationProof"}
                    image={userDetails.verification.educationProof}
                    title="Proof of Education"
                    verified={userDetails.verification.educationProofVerified}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>

            {userDetails.userDetailsVerification && (
              <div className="u-details-inner-card row mx-2 my-3">
                <h3>Verify User Details</h3>

                <div className="col-md-2">
                  <Form.Check
                    type="checkbox"
                    id="checkbox1"
                    label="Age"
                    checked={
                      userDetails.userDetailsVerification.ageVerified
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      setUserDetails((prevUserDetails) => ({
                        ...prevUserDetails,
                        userDetailsVerification: {
                          ...prevUserDetails.userDetailsVerification,
                          ageVerified: e.target.checked,
                        },
                      }));
                    }}
                  />
                </div>
                <div className="col-md-2">
                  <Form.Check
                    type="checkbox"
                    id="checkbox1"
                    label="Occupation"
                    checked={
                      userDetails.userDetailsVerification.occupationVerified
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      setUserDetails((prevUserDetails) => ({
                        ...prevUserDetails,
                        userDetailsVerification: {
                          ...prevUserDetails.userDetailsVerification,
                          occupationVerified: e.target.checked,
                        },
                      }));
                    }}
                  />
                </div>
                <div className="col-md-2">
                  <Form.Check
                    type="checkbox"
                    id="checkbox1"
                    label="Education"
                    checked={
                      userDetails.userDetailsVerification.educationVerified
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      setUserDetails((prevUserDetails) => ({
                        ...prevUserDetails,
                        userDetailsVerification: {
                          ...prevUserDetails.userDetailsVerification,
                          educationVerified: e.target.checked,
                        },
                      }));
                    }}
                  />
                </div>
                <div className="col-md-2">
                  <Form.Check
                    type="checkbox"
                    id="checkbox1"
                    label="Salary Bracket"
                    checked={
                      userDetails.userDetailsVerification.salaryVerified
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      setUserDetails((prevUserDetails) => ({
                        ...prevUserDetails,
                        userDetailsVerification: {
                          ...prevUserDetails.userDetailsVerification,
                          salaryVerified: e.target.checked,
                        },
                      }));
                    }}
                  />
                </div>
                <div className="col-md-2">
                  <Form.Check
                    type="checkbox"
                    id="checkbox1"
                    label="Nationality"
                    checked={
                      userDetails.userDetailsVerification.nationalityVerified
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      setUserDetails((prevUserDetails) => ({
                        ...prevUserDetails,
                        userDetailsVerification: {
                          ...prevUserDetails.userDetailsVerification,
                          nationalityVerified: e.target.checked,
                        },
                      }));
                    }}
                  />
                </div>
                <div className="col-md-2">
                  <div className="buttons-modal" required>
                    <button
                      className="ms-2"
                      style={{ width: "150px", maxWidth: "100%" }}
                      type="submit"
                      onClick={verifyUserDetails}
                    >
                      Update Details
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="buttons-wrapper mt-3">
        {/* <button className="btn bg-success text-white me-2"
          disabled={verified != null}
          hidden={verified == false}
          onClick={() => {
            verifyUser(true);
            // setConfirmVerifyAction(true);
            // setConfirmModel(true);

          }}
        >
          {verified == true ? "Verified" : "Verify"}
        </button>
        <button className="btn bg-danger text-white me-2"
          disabled={verified != null}
          hidden={verified == true}
          onClick={() => {
            // setDocPaylod({
            //   ...docPayload,
            //   profileId: userDetails.user_profile_id,
            //   verify: false,
            //   docType: "pt",
            // });
            verifyUser(false);
            // setConfirmVerifyAction(false);
            // setConfirmModel(true);
          }}
        >
          {verified == false ? "Rejected" : "Reject"}
        </button> */}
      </div>

      {/* <div className="col-md-4">
        <div className="document-cards">
          <div className="paln-card b">
            <div>
              <img src={ic_sub} alt="" />
            </div>
            <div className="name-c">Premium</div>
          </div>
          <div className="active-plan">
            <label htmlFor="" className="key">
              Active Plan
            </label>
            :
            <label htmlFor="" className="value">
              6 Months
            </label>
          </div>
          <div className="buttons-wrapper mt-3">
            <button
              style={{ backgroundColor: "#055D9D" }}
              className="btn  text-white w-100"
              onClick={handleViewShow}
            >
              Upgrade Plan
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="document-cards">
          <div className="paln-card bp">
            <div>
              <img src={ic_sub} alt="" />
            </div>
            <div className="name-c">Premium</div>
          </div>
          <div className="active-plan">
            <label htmlFor="" className="key">
              Active Plan
            </label>
            :
            <label htmlFor="" className="value">
              Not a Membership
            </label>
          </div>
          <div className="buttons-wrapper mt-3">
            <button
              style={{ backgroundColor: "#055D9D" }}
              className="btn  text-white w-100"
              onClick={handleViewShow}
            >
              Upgrade Plan
            </button>
          </div>
        </div>
      </div> */}
    </main>
  );
};

const VerificationCard = ({
  id,
  title,
  image,
  verified: docVerified,
  type,
}) => {
  const [viewModal, setView] = useState(false);
  const [confirmModel, setConfirmModel] = useState(false);
  const [confirmVerifyAction, setConfirmVerifyAction] = useState(null);
  const [verified, setVerified] = useState(docVerified);
  const [isToggled, setIsToggled] = useState(false);

  const handleViewClose = () => setView(false);
  const handleViewShow = () => setView(true);

  const verifyUser = (verify) => {
    postWithAuthCall(
      ApiConfig.VERIFY_DCOUMENT + `/${id}`,
      JSON.stringify({ [type]: verify })
    ).then((response) => {
      if (response.error) {
        ToastMsg("error", response.message);
      } else {
        ToastMsg("success", response.message);
        setVerified(verify);
      }
    });
  };

  return (
    <>
      <Modal
        show={confirmModel}
        onHide={() => setConfirmModel(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">
              Are you sure you want to{" "}
              {confirmVerifyAction == true ? "Verify" : "Reject"} this Document?
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              type="submit"
              onClick={() => {
                // VerifyDocApi(docPayload);
                setConfirmModel(false);
              }}
            >
              Yes
            </button>
            <Link
              to="#"
              className=""
              onClick={() => {
                setConfirmModel(false);
              }}
            >
              No
            </Link>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={viewModal}
        onHide={handleViewClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* <div className="document-preview-wrapper">
              <img width={300} src={image ?? card} />
            </div> */}
            <div className="document-preview-wrapper">
              {image.endsWith('.pdf') ? (
                <a href={image} target="_blank" rel="noopener noreferrer" style={{display:'flex', justifyContent:'center'}}>
                  <img src={pdfPreview} alt={'click here to view document'} style={{objectFit:'contain', width:'auto'}}/>
                </a>
              ) : (
                <img width={300} src={image} />
              )}
            </div>
            <div className="buttons-modal border-top pt-3 text-end  ">
              <Link to="#" className="bg-danger" onClick={handleViewClose}>
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <div className="col-md-4">
        <div className="document-cards">
          <h5>{title}</h5>
          {/* <div className="img">
            <img src={image ?? card} alt="" />
          </div> */}
          <div className="img">
            {image.endsWith('.pdf') ? (
              <img src={pdfPreview} alt={title} style={{objectFit:'contain'}}/>
            ) : (
              <img src={image} alt={title} style={{objectFit:'contain'}}/>
            )}
          </div>
          <div className="buttons-wrapper mt-3">
            <button
              className="btn bg-secondary text-white me-2"
              onClick={() => {
                handleViewShow();
              }}
            >
              Preview
            </button>

            <label className="switch">
              <input
                type="checkbox"
                checked={verified == true}
                onChange={(e) => {
                  setVerified((prev) => !prev);
                  verifyUser(!verified);
                }}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewUsers;
