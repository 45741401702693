import { LabelList, Pie, PieChart, Tooltip } from "recharts";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "./components/chart";

const chartConfig = {
  users: {
    label: "users",
  },
  male: {
    label: "Male",
    color: "hsl(var(--chart-1))",
  },
  female: {
    label: "Female",
    color: "hsl(var(--chart-2))",
  },
  OTHER: {
    label: "Other",
    color: "hsl(var(--chart-3))",
  },
};

export function PieChartComponent({ data, dataKey, nameKey }) {
  return (
    <ChartContainer config={chartConfig}>
      <PieChart width={500} height={300}>
        <ChartTooltip
          content={<ChartTooltipContent nameKey={nameKey} hideLabel />}
        />
        <Pie data={data} dataKey={dataKey}>
          <LabelList
            dataKey={nameKey}
            className="fill-background"
            stroke="none"
            fontSize={12}
            formatter={(value) => chartConfig[value]?.label}
          />
        </Pie>
      </PieChart>
    </ChartContainer>
  );
}
