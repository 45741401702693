import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
//Datatable Modules
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import ic_delete from "../assets/images/ic_delete.svg";
import ic_edit from "../assets/images/ic_edit.svg";
import Active from "../assets/images/Active.png";
import inActive from "../assets/images/inActive.png";
import Loader from "./Loader";
import Modal from "react-bootstrap/Modal";
import {
  DELETEPUTMETHOD,
  PostCallWithErrorResponse,
  SimplePutCall,
  putWithAuthCall,
  simpleGetCall,
} from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { ToastMsg } from "./ToastMsg";

const Subject = () => {
  const [adminList, setAdminList] = useState([]);
  const { sidebar, useDebounce, userData } = useContext(AppContext);
  const [search, setSearch] = useState("");
  const [add, setAdd] = useState(false);
  const [admin, setAdmin] = useState();
  const [loading, setLoading] = useState(false);
  const handleAddClose = () => setAdd(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [subj, setSubj] = useState({
    subjectName: "",
    subjectId: "",
  });

  const [errMsg, setErrMsg] = useState({
    subjectName: "",
  });

  useEffect(() => {
    getSubjectList();
  }, []);

  const getSubjectList = () => {
    setLoading(true);
    simpleGetCall(ApiConfig.SUBJECT_LIST).then((res) => {
      if (res.success) {
        setLoading(false);
        setAdminList(res.data);
        if (res.data.length) {
          const totalPage = Number(res.total_pages);
        }
      } else {
        setAdminList([]);
      }
    });
  };

  //View Admin Api
  const getSubjectEditApi = (id) => {
    setLoading(true);
    SimplePutCall(ApiConfig.UPDATE_SUBJECT, JSON.stringify(subj)).then(
      (response) => {
        setLoading(false);
        if (response.error) {
          ToastMsg("error", response.json.message);
        } else if (response.json.success == true) {
          ToastMsg("success", response.json.message);
          handleAddClose();
          setSubj({
            subjectName: "",
            subjectId: "",
          });
          getSubjectList();
        }
      }
    );
  };

  // ADD Admin API
  const AddSubjectApi = async () => {
    if (subj.subjectName === "") {
      setErrMsg({ ...errMsg, subjectName: "Please Enter subject Name " });
      return;
    } else {
      const response = await PostCallWithErrorResponse(
        ApiConfig.ADD_SUBJECT,
        JSON.stringify(subj)
      );
      if (response.error) {
        ToastMsg("error", response.json.message);
      } else if (response.json.success == true) {
        ToastMsg("success", response.json.message);
        handleAddClose();
        setSubj({
          subjectName: "",
          subjectId: "",
        });
        getSubjectList(search);
      }
    }
  };

  // ADD adminDesdleteApid API
  const subjectDeleteApi = () => {
    DELETEPUTMETHOD(
      ApiConfig.DELETE_SUBJECT,
      JSON.stringify({ subjectId: admin })
    ).then((response) => {
      if (response.error) {
        ToastMsg("error", response.json.message);
      } else if (response.json.success == true) {
        ToastMsg("success", response.json.message);
        handleAddClose();
        getSubjectList();
      }
    });
  };
  // ADD adminDesdleteApid API
  const SubjectAtiveInActiveApi = (id, val) => {
    console.log(id, val, "active==>");
    putWithAuthCall(
      ApiConfig.SUBJECT_ACTIVE,
      JSON.stringify({
        subjectId: id,
        isActive: val,
      })
    ).then((response) => {
      console.log(response);
      if (response.error) {
        ToastMsg("error", response.message);
      } else if (response.success == true) {
        ToastMsg("success", response.message);

        handleAddClose();
        getSubjectList();
      }
    });
  };

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Subject</label>
        </div>
      </div>

      {/* ======================= DELETE ========================== */}

      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              type="submit"
              onClick={() => {
                subjectDeleteApi();
                setDeleteModal(false);
              }}
            >
              Delete
            </button>
            <Link
              to="#"
              className=""
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
      {/* ======================= END DELETE ========================== */}

      <div className="table-wrapper">
        <div className="search-wrapper row">
          <div className="col-md-2 mt-1 text-start">
            <Form.Label className="subject_label">Subject Name</Form.Label>
          </div>
          <div className="col-md-8 mb-3">
            <Form.Control
              type="text"
              placeholder="Enter Subject Name..."
              required
              value={subj.subjectName}
              onChange={(e) => {
                setSubj({
                  ...subj,
                  subjectName: e.target.value,
                });

                setErrMsg({
                  ...errMsg,
                  subjectName: "",
                });
              }}
            />

            {errMsg.subjectName.length > 0 && (
              <span className="text-danger text-center">
                {errMsg.subjectName}
              </span>
            )}
          </div>
          <div className="col-md-2 text-end mb-3">
            <button
              className="artcle_btnFalse "
              type="submit"
              onClick={(e) => {
                subj.subjectId ? getSubjectEditApi() : AddSubjectApi();
              }}
            >
              {subj.subjectId ? "Update Subject" : "Add Subject"}
            </button>
          </div>
        </div>
        {loading ? (
          <div style={{ marginTop: "150px" }}>
            <Loader />
          </div>
        ) : (
          <>
            <div className="main-data-wrapper">
              <Table class="display" striped bordered hover>
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>Subject Name</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {adminList && adminList.length > 0 ? (
                    adminList.map((admin, index) => {
                      return (
                        <tr key={"admin" + index}>
                          <td> {index + 1}</td>
                          <td>{admin.educational_subject}</td>
                          <td>
                            <span
                              className={
                                admin.is_active == true
                                  ? "active-status"
                                  : "inactive-status"
                              }
                            >
                              {admin.is_active == true ? (
                                <span>Active</span>
                              ) : (
                                <span>InActive</span>
                              )}
                            </span>
                          </td>
                          <td className="action">
                            {/* icon edit start  */}
                            <span>
                              <Link
                                to="#"
                                className="icon edit"
                                onClick={() => {
                                  setSubj({
                                    ...subj,
                                    subjectName: admin.educational_subject,
                                    subjectId: admin.educational_subject_Id,
                                  });
                                }}
                              >
                                <img src={ic_edit} alt="" />
                              </Link>
                            </span>

                            {/* icon edit End */}

                            <Link
                              to="#"
                              className="icon delete"
                              onClick={() => {
                                setAdmin(admin.educational_subject_Id);
                                setDeleteModal(true);
                              }}
                            >
                              <img src={ic_delete} alt="" />
                            </Link>
                            {/* Active & InActive*/}

                            {admin.is_active ? (
                              <Link
                                to="#"
                                className="icon delete"
                                onClick={() => {
                                  SubjectAtiveInActiveApi(
                                    admin.educational_subject_Id,
                                    false
                                  );
                                }}
                              >
                                <img src={Active} alt="" />
                              </Link>
                            ) : (
                              <Link
                                to="#"
                                className="icon delete"
                                onClick={() => {
                                  SubjectAtiveInActiveApi(
                                    admin.educational_subject_Id,
                                    true
                                  );
                                }}
                              >
                                <img src={inActive} alt="" />
                              </Link>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        <div className=" justify-content-center align-items-center text-danger text-center mt-3">
                          Data Not Found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </>
        )}
      </div>
    </main>
  );
};

export default Subject;
