import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
//Datatable Modules
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import ic_eyes from "../assets/images/ic_eyes.svg";
import Modal from "react-bootstrap/Modal";
import Pagination from "./Pagination";
import Loader from "./Loader";

const DocumentsVarification = () => {
  const { document, setDocument } = useContext(AppContext);
  const { sidebar, useDebounce } = useContext(AppContext);
  const [currrentpage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState();
  const [add, setAdd] = useState(false);
  const handleAddClose = () => setAdd(false);
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDelete] = useState(false);
  const handleDeleteClose = () => setDelete(false);

  const [viewModal, setView] = useState(false);
  const handleViewClose = () => setView(false);
  const handleViewShow = () => setView(true);
  const [userList, setUserList] = useState([]);
  const [search, setSearch] = useState("");

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const navigate = useNavigate();

  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    getUserListApi(debouncedSearchTerm, currrentpage);
  }, [debouncedSearchTerm]);

  const getUserListApi = (search, currrentpage) => {
    setLoading(true);
    // simpleGetCall(
    //   `https://api.marryem.com:3001/users/users-list?page=${currrentpage}&search=${search}`
    // ).then((res) => {
    //   if (res.success) {
    //     setLoading(false);
    //     setUserList(res.data);
    //     if (res.data.length) {
    //       const totalPage = Number(res.total_pages);
    //       setTotalPages(totalPage);
    //       setCurrentPage(res.current_page);
    //       setTotalCount(res.total_count);
    //       setLastPage(res.last_page);
    //     }
    //   } else {
    //     setUserList([]);
    //   }
    // });
  };

  //Pagination

  const searchedDataPageCounts =
    userList &&
    userList.length &&
    userList.filter((item) => item.ProjectName?.toLowerCase().includes(search));

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected + 1);
    getUserListApi("", selected + 1);
  };

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Documents Verification</label>
        </div>
      </div>
      {/* ======================= START ADD ========================== */}
      <Modal
        show={add}
        onHide={handleAddClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" />
                <Form.Control.Feedback type="invalid">
                  Please Enter Name
                </Form.Control.Feedback>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Label>Mobile No.</Form.Label>
                <Form.Control type="text" />
                <Form.Control.Feedback type="invalid">
                  Please Enter Mobile No.
                </Form.Control.Feedback>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Label>Email ID</Form.Label>
                <Form.Control type="text" />
                <Form.Control.Feedback type="invalid">
                  Please Enter Email ID.
                </Form.Control.Feedback>
              </div>
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <button className="me-2" type="submit">
                Save
              </button>
              <Link to="#" className=" bg-danger" onClick={handleAddClose}>
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END ADD ========================== */}

      {/* ======================= DELETE ========================== */}

      <Modal
        show={deleteModal}
        onHide={handleDeleteClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button className="me-2 bg-danger" type="submit">
              Delete
            </button>
            <Link to="#" className="" onClick={handleDeleteClose}>
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
      {/* ======================= END DELETE ========================== */}

      {/* ======================= VIEW ========================== */}
      <Modal
        show={viewModal}
        onHide={handleViewClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Admin Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Name
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    Admin2
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Phone No.
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    7389457689
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Email ID.
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    admin@gmail.com
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Status
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    Active
                  </label>
                </div>
              </div>
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <Link to="#" className="bg-danger" onClick={handleViewClose}>
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END VIEW ========================== */}

      <div className="table-wrapper">
        <div className="search-wrapper row">
          <Form.Group className="mb-3 col-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              placeholder="Search"
              onChange={(e) => {
                setSearch(e.target.value);
                setCurrentPage(1);
              }}
            />
          </Form.Group>
        </div>
        <div className="main-data-wrapper">
          {loading ? (
            <div style={{ marginTop: "150px" }}>
              <Loader />
            </div>
          ) : (
            <Table class="display" striped bordered hover>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Name</th>
                  <th>Gender</th>
                  <th>Mobile No.</th>
                  <th>Status</th>
                  <th className="text-center">Documents</th>
                </tr>
              </thead>

              <tbody>
                {userList && userList.length > 0 ? (
                  userList.map((user, index) => {
                    return (
                      <tr
                        onClick={() => {
                          navigate("/ViewDocuments/" + user.user_profile_id);
                          setDocument("fromDocuments");
                          localStorage.setItem(
                            "fromDocuments",
                            "fromDocuments"
                          );
                        }}
                        key={"user" + index}
                      >
                        <td>{(currrentpage - 1) * 10 + index + 1} </td>
                        <td>{user.full_name}</td>
                        <td>{user.gender}</td>
                        <td>{user.phone_number}</td>
                        <td>
                          <span
                            className={
                              user.is_verified == true
                                ? "active-status"
                                : "inactive-status"
                            }
                          >
                            {" "}
                            {user.is_verified == true
                              ? "verified"
                              : "Not verified"}{" "}
                          </span>
                        </td>
                        <td className="action text-center">
                          <Link
                            to="/ViewDocuments"
                            className="icon view"
                            onClick={handleViewShow}
                          >
                            <img src={ic_eyes} alt="" />
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6}>
                      <div className=" justify-content-center align-items-center text-danger text-center mt-3">
                        Data Not Found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </div>
        {userList.length > 0 ? (
          <div className="pagination-wrapper">
            <div className="entry-number">
              <label htmlFor="">
                Showing {(currrentpage - 1) * 10 + 1} -{" "}
                {lastPage == true ? totalCount : currrentpage * 10} of{" "}
                {totalCount}
              </label>
            </div>
            <Pagination
              data={userList}
              pageChangeHandler={pageChangeHandler}
              // usersPerPage={usersPerPage}
              currentPage={currrentpage}
              searchValue={search}
              totalPages={totalPages}
              searchedDataPageCounts={searchedDataPageCounts}
            />
          </div>
        ) : null}
      </div>
    </main>
  );
};

export default DocumentsVarification;
