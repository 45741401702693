// export const BASE_URL = "https://api.marryem.com:3001";
// export const BASE_URL = "https://api.marryem.com:3001";
// export const BASE_URL = "https://app.marryem.com:5000";
// export const BASE_URL = "https://api.marryem.com:3001";
// const BASE_DOMAIN = "http://localhost:5000";
// export const BASE_URL = "https://api.marryem.com/api/v1";
export const BASE_URL = "https://api.marryem.com/api/v1";
// export const BASE_URL = "http://localhost:5000/api/v1";
// export const BASE_URL = BASE_DOMAIN+"/api/v1";
// export const BASE_URL = BASE_DOMAIN;
// export const BASE_URL = "http://203.129.225.99:5000";

const ApiConfig = {
  BASE_URL_FOR_IMAGES_Profile: "https://api.marryem.com/",
  // BASE_URL_FOR_IMAGES: "https://api.marryem.com/uploads/articles/",
  BASE_URL_FOR_IMAGES: "https://api.marryem.com:3001/uploads/articles/",
  // BASE_URL: BASE_URL,
  //  ============= ADMIN API =============================
  LOGIN: BASE_URL + "/admin/login",
  ADD_UPDATE_ADMIN: BASE_URL + "/admin/addUpdate-admin",
  UPDATE_ADMIN: BASE_URL + "/admin/UpdateAdmin",
  ADMIN_DELETE: BASE_URL + "/admin/delete-admin",
  VERIFY_USER: BASE_URL + "/admin/verify-user",
  VERIFY_DCOUMENT: BASE_URL + "/admin/verify-document",
  BLOCK_USER: BASE_URL + "/admin/block-user",
  USER_UNBLOCK: BASE_URL + "/users/block-unblock-user",
  VERIFY_DOC: BASE_URL + "/users/verify-doc",
  DASHBOARD_COUNT: BASE_URL + "/dashboard/content-count",
  DASHBOARD_USER_COUNT: BASE_URL + "/dashboard/user-count",
  PROFILE_SUGG: BASE_URL + "/prof-suggestion/suggest-user",

  GET_ALL_SUBSCRIPTION_PLANS : BASE_URL + "/subscription/plans",
  ADD_SUBSCRIPTION_PLAN : BASE_URL + "/subscription/plan",
  DELETE_SUBSCRIPTION_PLAN : BASE_URL + "/subscription/plan",

  GET_SUBSCRIPTION_PLAN_MEMBERS: BASE_URL + "/admin/subscribe/get-users-by-plan",
  GET_UNIQUE_USER_BY_USERID: BASE_URL + "/admin/get-strangers",
  PERSIST_SUGGESTED_USERS_PREMIUM: BASE_URL + "/admin/subscribe/suggestion",

  //  ============= CONTENT_ MANEGMENT API =============================
  ADD_UPDATE_ARTICLES: BASE_URL  + "/articles/addUpdate-articles",
  UPDATE_ARTICLES: BASE_URL  + "/articles/update-article",
  ABOUT_ADDUPDATE: BASE_URL  + "/content/addUpdate-contents",
  QUERY_RESPONSE: BASE_URL  + "/query-mgmt/query-response",
  ARTICLE_DELTE: "/articles/delete-article",

  //  =============Subject API =============================
  ADD_SUBJECT: BASE_URL + "/subject/add-subject",
  SUBJECT_LIST: BASE_URL + "/subject/view-subjects",
  UPDATE_SUBJECT: BASE_URL + "/subject/update-subject",
  DELETE_SUBJECT: BASE_URL + "/subject/delete-subject",
  SUBJECT_ACTIVE: BASE_URL + "/subject/activeInactive-subject",

  USERS_LIST : BASE_URL + "/account/list-users",
  USER_DETAILS: BASE_URL+"/admin/users",

  ACTIVE_USER_COUNT : BASE_URL + "/user-activity/active-users",
  CAMPAIGN_LIST : BASE_URL + "/campaign/all",
  USER_RETENTION : BASE_URL + "/dashboard/retention-rate",

  UPDATE_AD_CAMPAIGN : BASE_URL + "/ad-campaign/update",
  DELETE_AD_CAMPAIGN : BASE_URL + "/ad-campaign/delete"
};

export default ApiConfig;
