import { Bar, BarChart, CartesianGrid, XAxis } from "recharts";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "./components/chart";

const chartData = [
  { plan: "Free", male: 186, female: 80 },
  { plan: "PREMIUM", male: 305, female: 200 },
  { plan: "PREMIUMPLUS", male: 237, female: 120 }
];

const chartConfig = {
  desktop: {
    label: "Desktop",
    color: "hsl(var(--chart-1))",
  },
  mobile: {
    label: "Mobile",
    color: "hsl(var(--chart-2))",
  },
};

export function MultipleBarChartComponent({data, xAxisKey, dataKey1, dataKey2, tickFormatter}) {
  return (
    <ChartContainer config={chartConfig}>
      <BarChart accessibilityLayer data={data}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey={xAxisKey}
          tickLine={false}
          tickMargin={10}
          axisLine={false}
        //   tickFormatter={(value) => value.slice(0, 3)}
        tickFormatter={tickFormatter}
        />
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent indicator="dashed" />}
        />
        <Bar dataKey={dataKey1} fill="var(--chart-2)" radius={8} barSize={30} />
        <Bar dataKey={dataKey2} fill="var(--chart-1)" radius={8} barSize={30} />
      </BarChart>
    </ChartContainer>
  );
}
