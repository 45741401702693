import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { BarChartComponent } from "../charts/BarChartComponent";
import { LineChartComponent } from "../charts/LineChartComponent";
import { SingleLineChartComponent } from "../charts/SingleLineChartComponent";

export function ActiveuserChart({activeUsers}) {
  const [filterTime, setFilterTime] = useState("Daily");
  const [filterGender, setFilterGender] = useState("All");
  const [chartData, setChartData] = useState(activeUsers.dailyActiveUsers);


  const timeFilters = ["Daily", "Weekly", "Monthly"];
  const genderFilter = ["All", "Male", "Female"];
  
  useEffect(() => {
    if(filterTime === "Daily"){
      setChartData(activeUsers.dailyActiveUsers);
    } else if (filterTime === "Weekly") {
      setChartData(activeUsers.weeklyActiveUsers);
    } else if (filterTime === "Monthly") {
      setChartData(activeUsers.monthlyActiveUsers);
    }
  }, [filterTime, activeUsers]);

  return (
    <>
      <div className="search-wrapper row" style={{ paddingLeft: "10px", marginBottom:'10px' }}>
        <Form.Group className=" col-md-4" controlId="formTimeFilter">
          <Form.Select
            aria-label="Default select example"
            value={filterTime}
            onChange={(e) => {
              setFilterTime(e.target.value);
            }}
          >
            {timeFilters.map((timeFilter) => {
              return <option key={timeFilter} value={timeFilter}>{timeFilter}</option>;
            })}
          </Form.Select>
        </Form.Group>

        {/* <Form.Group className=" col-md-4" controlId="formTimeFilter">
          <Form.Select
            aria-label="Default select example"
            value={filterGender}
            onChange={(e) => {
              setFilterGender(e.target.value);
            }}
          >
            {genderFilter.map((gender) => {
              return <option key={gender} value={gender}>{gender}</option>;
            })}
          </Form.Select>
        </Form.Group> */}
      </div>
      <SingleLineChartComponent chartData={chartData} filterTime={filterTime}/>
    </>
  );
}
