import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
//Datatable Modules
import { Await, Link, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import card from "../assets/images/card.svg";
import license from "../assets/images/license.png";
import { putWithAuthCall } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { ToastMsg } from "./ToastMsg";
import Loader from "./Loader";
const ViewDocuments = () => {
  const params = useParams();
  let id = params.id;
  var fromDocuments = localStorage.getItem("fromDocuments");
  const [loading, setLoading] = useState(false);
  const { sidebar } = useContext(AppContext);
  const userDataLoc = localStorage.getItem("IsUserDocVerify");

  const [docPayload, setDocPaylod] = useState({
    profileId: id,
    verify: false,
    docType: "",
    docTypeId: "",
  });
  const [viewModal, setView] = useState(false);
  const [dnyView, setDnyView] = useState(false);
  const handleViewClose = () => setView(false);
  const handleViewShow = () => setView(true);
  const handlednyViewClose = () => setDnyView(false);
  const handlednyView = () => setDnyView(true);
  const [CitizenShipDoc, setCitizenship] = useState([]);
  const [education, setEducation] = useState([]);
  const [employDoc, setEmployDoc] = useState([]);
  const [passView, setPassView] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [verifed, setVerifed] = useState(false);
  const [verifyDoc, setVerifyDoc] = useState("");
  const [rejectpt, setRejectpt] = useState(false);
  const [verifyLicen, setVerifyLicen] = useState(false);
  const [liceReject, setLiceReject] = useState(false);
  const [eduverify, setEduverify] = useState(false);
  const [edurej, setEdurej] = useState(false);
  const [empoly, setEmpoly] = useState(false);
  const [empRej, setEmpRej] = useState(false);
  const [prev, setPrev] = useState("");
  const [prevData, setPrevData] = useState([]);
  const [prId, setPrId] = useState();

  // showPreview /
  const showPreview = (prev) => {
    if (prev == "edu") {
      let FilterData = education.find(
        (item) => item.educational_document_type_Id == prId
      );
      setPrevData(FilterData);
    } else if (prev == "emd") {
      let FilterData = employDoc.find(
        (item) => item.employment_document_type_Id == prId
      );
      setPrevData(FilterData);
    } else {
    }
  };

  useEffect(() => {
    showPreview(prev);
  }, [prev, prId]);

  useEffect(() => {
    getUserListApi(id);
  }, []);

  const getUserListApi = () => {
    setLoading(true);
    // simpleGetCallWithErrorResponse(
    //   `https://api.marryem.com:3001/users/user-docs?userProfileId=${id}`
    // )
    //   .then((response) => {
    //     if (response.json.data) {
    //       let repon = response.json.data;
    //       setCitizenship(repon.citizenship_documents[0]);
    //       setEducation(repon.educational_documents);
    //       setEmployDoc(repon.employment_documents);

    //       setLoading(false);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  // VerifyDocApi

  const VerifyDocApi = (docPayload) => {
    putWithAuthCall(ApiConfig.VERIFY_DOC, JSON.stringify(docPayload)).then(
      (response) => {
        if (response.error) {
          ToastMsg("error", response.message);
        } else if (response.success == true) {
          ToastMsg("success", response.message);
          if (verifyDoc == "pt") {
            setVerifed(true);
            setRejectpt(false);
            getUserListApi(id);
          } else if (verifyDoc == "ptr") {
            setRejectpt(true);
            setVerifed(false);
            getUserListApi(id);
          } else if (verifyDoc == "dl") {
            setVerifyLicen(true);
            setLiceReject(false);
            getUserListApi(id);
          } else if (verifyDoc == "dlr") {
            setLiceReject(true);
            setVerifyLicen(false);
            getUserListApi(id);
          } else if (verifyDoc == "ed") {
            setEduverify(true);
            setEdurej(false);
            getUserListApi(id);
          } else if (verifyDoc == "edr") {
            setEdurej(true);
            setEduverify(false);
            getUserListApi(id);
          } else if (verifyDoc == "emd") {
            setEmpoly(true);
            setEmpRej(false);
            getUserListApi(id);
          } else if (verifyDoc == "emdr") {
            setEmpoly(false);
            setEmpRej(true);
            getUserListApi(id);
          } else {
            console.log(false);
          }
        }
      }
    );
  };

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <Link to="/DocumentsVarification" className="back-btns">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
            >
              <path
                fill="#E40288"
                d="M20 11v2H8l5.5 5.5l-1.42 1.42L4.16 12l7.92-7.92L13.5 5.5L8 11h12Z"
              />
            </svg>
          </Link>
          <label htmlFor="">
            {" "}
            {fromDocuments == "fromDocuments"
              ? "Documents"
              : "User Details"}{" "}
          </label>
        </div>
      </div>

      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">
              Are you sure you want to {passView ? "Verify" : "Reject"} this
              Document?
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              type="submit"
              onClick={() => {
                VerifyDocApi(docPayload);
                setDeleteModal(false);
              }}
            >
              Yes
            </button>
            <Link
              to="#"
              className=""
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              No
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
      {/* ======================= END DELETE ========================== */}

      {/* ======================= VIEW ========================== */}

      {/* ======================= END VIEW ========================== */}
      {/* ======================= VIEW ========================== */}

      <Modal
        show={viewModal}
        onHide={handleViewClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {passView == true ? "PassPort" : "Licence"}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="document-preview-wrapper">
              <img
                width={300}
                height={300}
                src={
                  passView == true
                    ? ApiConfig.BASE_URL_FOR_IMAGES_Profile +
                      CitizenShipDoc?.passport_path
                      ? ApiConfig.BASE_URL_FOR_IMAGES_Profile +
                        CitizenShipDoc?.passport_path
                      : card
                    : ApiConfig.BASE_URL_FOR_IMAGES_Profile +
                      CitizenShipDoc?.driving_licence_path
                    ? ApiConfig.BASE_URL_FOR_IMAGES_Profile +
                      CitizenShipDoc?.driving_licence_path
                    : license
                }
              />
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <Link to="#" className="bg-danger" onClick={handleViewClose}>
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= Dynamic VIEW ========================== */}
      <Modal
        show={dnyView}
        onHide={handlednyViewClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Modal.Title className="mb-2">
            {prevData && prevData?.educational_document_type
              ? prevData?.educational_document_type
              : prevData.employment_document_type}
          </Modal.Title>
          <Form>
            <div className="document-preview-wrapper">
              <img
                width={300}
                height={300}
                src={
                  passView == true
                    ? ApiConfig.BASE_URL_FOR_IMAGES_Profile +
                      prevData.user_employment_document_path
                      ? ApiConfig.BASE_URL_FOR_IMAGES_Profile +
                        prevData.user_employment_document_path
                      : card
                    : ApiConfig.BASE_URL_FOR_IMAGES_Profile +
                      prevData.user_educational_document_path
                    ? ApiConfig.BASE_URL_FOR_IMAGES_Profile +
                      prevData.user_educational_document_path
                    : card
                }
              />
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <Link to="#" className="bg-danger" onClick={handlednyViewClose}>
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END VIEW ========================== */}

      <div className="documents-wrapper user-details-main-card">
        {loading ? (
          <div style={{ marginTop: "150px" }}>
            <Loader />
          </div>
        ) : (
          <div className="  user-detail-card">
            <div className="u-details-inner-card row mx-2 my-3">
              <h3>Citizenship Documents</h3>

              <div className="row g-3">
                <div className="col-md-3">
                  <div className="document-cards">
                    <h5>Passport</h5>
                    <div className="img">
                      <img
                        className="doc_img"
                        src={
                          !CitizenShipDoc?.passport_path
                            ? card
                            : CitizenShipDoc?.passport_path
                            ? ApiConfig.BASE_URL_FOR_IMAGES_Profile +
                              CitizenShipDoc?.passport_path
                            : CitizenShipDoc?.passport_path &&
                              URL.createObjectURL(CitizenShipDoc?.passport_path)
                        }
                        alt=""
                      />
                    </div>

                    <div className="buttons-wrapper mt-3">
                      {userDataLoc === "true" && (
                        <div className="buttons-wrapper mt-3">
                          {CitizenShipDoc?.is_passport_verified ? (
                            <button
                              className="btn bg-success text-white me-2"
                              disabled={true}
                            >
                              verified
                            </button>
                          ) : (
                            <button
                              className="btn bg-success text-white me-2"
                              onClick={() => {
                                setDocPaylod({
                                  ...docPayload,
                                  profileId: Number(id),
                                  verify: true,
                                  docType: "cd",
                                  docTypeId: "pt",
                                });
                                setPassView(true);
                                setDeleteModal(true);
                                setVerifyDoc("pt");
                              }}
                            >
                              Verify
                            </button>
                          )}

                          {CitizenShipDoc?.is_passport_verified != true ? (
                            <button
                              className="btn bg-danger text-white me-2"
                              disabled={true}
                            >
                              Rejected
                            </button>
                          ) : (
                            <button
                              className="btn bg-danger text-white me-2"
                              onClick={() => {
                                setDocPaylod({
                                  ...docPayload,
                                  profileId: Number(id),
                                  verify: false,
                                  docType: "cd",
                                  docTypeId: "pt",
                                });
                                setPassView(false);
                                setVerifyDoc("ptr");
                                setDeleteModal(true);
                              }}
                            >
                              Reject
                            </button>
                          )}

                          <button
                            className="btn bg-secondary text-white"
                            onClick={() => {
                              handleViewShow();
                              setPassView(true);
                            }}
                          >
                            Preview
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="document-cards">
                    <h5>License</h5>
                    <div className="img">
                      <img
                        src={
                          !CitizenShipDoc?.driving_licence_path
                            ? card
                            : CitizenShipDoc?.driving_licence_path
                            ? ApiConfig.BASE_URL_FOR_IMAGES_Profile +
                              CitizenShipDoc?.driving_licence_path
                            : CitizenShipDoc?.driving_licence_path &&
                              URL.createObjectURL(
                                CitizenShipDoc?.driving_licence_path
                              )
                        }
                        alt=""
                      />
                    </div>

                    <div className="buttons-wrapper mt-3">
                      {userDataLoc === "true" && (
                        <div className="buttons-wrapper mt-3">
                          {CitizenShipDoc?.is_driving_licence_verified ? (
                            <button
                              className="btn bg-success text-white me-2"
                              disabled={true}
                            >
                              verified
                            </button>
                          ) : (
                            <button
                              className="btn bg-success text-white me-2"
                              onClick={() => {
                                setDocPaylod({
                                  ...docPayload,
                                  profileId: Number(id),
                                  verify: true,
                                  docType: "cd",
                                  docTypeId: "dl",
                                });
                                setPassView(true);
                                setDeleteModal(true);
                                setVerifyDoc("dl");
                              }}
                            >
                              Verify
                            </button>
                          )}

                          {CitizenShipDoc?.is_driving_licence_verified !=
                          true ? (
                            <button
                              className="btn bg-danger text-white me-2"
                              disabled={true}
                            >
                              Rejected
                            </button>
                          ) : (
                            <button
                              className="btn bg-danger text-white me-2"
                              onClick={() => {
                                setDocPaylod({
                                  ...docPayload,
                                  profileId: Number(id),
                                  verify: false,
                                  docType: "cd",
                                  docTypeId: "dl",
                                });
                                setPassView(false);
                                setDeleteModal(true);
                                setVerifyDoc("dlr");
                              }}
                            >
                              Reject
                            </button>
                          )}

                          <button
                            className="btn bg-secondary text-white"
                            onClick={() => {
                              handleViewShow();
                              setPassView(false);
                            }}
                          >
                            Preview
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="u-details-inner-card row mx-2 my-3">
              <h3>Educational Documents</h3>

              <div>
                <div className="row g-3">
                  {education &&
                    education.map((edu) => {
                      return (
                        <div className="col-md-3">
                          <div className="document-cards">
                            <h5>{edu.educational_document_type}</h5>
                            <div className="img">
                              <img
                                className="doc_img"
                                src={
                                  !edu?.user_educational_document_path
                                    ? card
                                    : edu?.user_educational_document_path
                                    ? ApiConfig.BASE_URL_FOR_IMAGES_Profile +
                                      edu?.user_educational_document_path
                                    : edu?.user_educational_document_path &&
                                      URL.createObjectURL(
                                        edu?.user_educational_document_path
                                      )
                                }
                                alt=""
                              />
                            </div>

                            {userDataLoc === "true" && (
                              <div className="buttons-wrapper mt-3">
                                {edu?.is_document_verified ? (
                                  <button
                                    className="btn bg-success text-white me-2"
                                    disabled={true}
                                  >
                                    verified
                                  </button>
                                ) : (
                                  <button
                                    className="btn bg-success text-white me-2"
                                    onClick={() => {
                                      setDocPaylod({
                                        ...docPayload,
                                        profileId: Number(id),
                                        verify: true,
                                        docType: "ed",
                                        docTypeId:
                                          edu.educational_document_type_Id,
                                      });
                                      setPassView(true);
                                      setDeleteModal(true);
                                      setVerifyDoc("ed");
                                    }}
                                  >
                                    Verify
                                  </button>
                                )}

                                {edu?.is_document_verified == false ? (
                                  <button
                                    className="btn bg-danger text-white me-2"
                                    disabled={true}
                                  >
                                    Rejected
                                  </button>
                                ) : (
                                  <button
                                    className="btn bg-danger text-white me-2"
                                    onClick={() => {
                                      setDocPaylod({
                                        ...docPayload,
                                        profileId: Number(id),
                                        verify: false,
                                        docType: "ed",
                                        docTypeId:
                                          edu.educational_document_type_Id,
                                      });
                                      setPassView(false);
                                      setVerifyDoc("edr");
                                      setDeleteModal(true);
                                    }}
                                  >
                                    Reject
                                  </button>
                                )}

                                <button
                                  className="btn bg-secondary text-white"
                                  onClick={() => {
                                    setPrId(edu.educational_document_type_Id);
                                    handlednyView();
                                    setPrev("edu");
                                    showPreview(prev);
                                  }}
                                >
                                  Preview
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="u-details-inner-card row mx-2 my-3">
              <h3>Employment Documents</h3>

              <div>
                <div className="row g-3">
                  {employDoc &&
                    employDoc.map((emply) => {
                      return (
                        <div className="col-md-3">
                          <div className="document-cards">
                            <h5>{emply.employment_document_type}</h5>
                            <div className="img">
                              <img
                                className="doc_img"
                                src={
                                  !emply?.user_employment_document_path
                                    ? card
                                    : emply?.user_employment_document_path
                                    ? ApiConfig.BASE_URL_FOR_IMAGES_Profile +
                                      emply?.user_employment_document_path
                                    : emply?.user_employment_document_path &&
                                      URL.createObjectURL(
                                        emply?.user_employment_document_path
                                      )
                                }
                                alt=""
                              />
                            </div>

                            {userDataLoc === "true" && (
                              <div className="buttons-wrapper mt-3">
                                {emply?.is_document_verified ? (
                                  <button
                                    className="btn bg-success text-white me-2"
                                    disabled={true}
                                  >
                                    verified
                                  </button>
                                ) : (
                                  <button
                                    className="btn bg-success text-white me-2"
                                    onClick={() => {
                                      setDocPaylod({
                                        ...docPayload,
                                        docTypeId:
                                          emply.employment_document_type_Id,
                                        profileId: Number(id),
                                        verify: true,
                                        docType: "emd",
                                      });
                                      setPassView(true);
                                      setDeleteModal(true);
                                      setVerifyDoc("emd");
                                    }}
                                  >
                                    Verify
                                  </button>
                                )}

                                {emply?.is_document_verified != true ? (
                                  <button
                                    className="btn bg-danger text-white me-2"
                                    disabled={true}
                                  >
                                    Rejected
                                  </button>
                                ) : (
                                  <button
                                    className="btn bg-danger text-white me-2"
                                    onClick={() => {
                                      setDocPaylod({
                                        ...docPayload,
                                        docTypeId:
                                          emply.employment_document_type_Id,
                                        profileId: Number(id),
                                        verify: false,
                                        docType: "emd",
                                      });
                                      setPassView(false);
                                      setVerifyDoc("emdr");
                                      setDeleteModal(true);
                                    }}
                                  >
                                    Reject
                                  </button>
                                )}

                                <button
                                  className="btn bg-secondary text-white"
                                  onClick={() => {
                                    setPrId(emply.employment_document_type_Id);
                                    handlednyView();
                                    setPrev("emd");
                                    showPreview(prev);
                                    setPrev("emd");
                                    setPassView(true);
                                  }}
                                >
                                  Preview
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default ViewDocuments;
