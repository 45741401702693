import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
//Datatable Modules
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Article_Edit from "../assets/images/Article_Edit.svg";
import redDelete from "../assets/images/redDelete.svg";
import Modal from "react-bootstrap/Modal";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import {
  DeleteCallWithErrorResponse,
  PostCallWithErrorResponse,
  multipartPostCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../api/ApiServices";
import { BASE_URL } from "../api/ApiConfig";
import { ToastMsg } from "./ToastMsg";
import Loader from "./Loader";
import { DefaultEditor } from "react-simple-wysiwyg";
import apiConfig from "../api/ApiConfig";

const PushNotifications = () => {
  const { sidebar, userData } = useContext(AppContext);
  localStorage.setItem("IsUserCreateArt", "true");
  const userDataLoc = localStorage.getItem("IsUserCreateArt");
  const AdminID = localStorage.getItem("AdminID");
  const [validated, setValidated] = useState(false);
  const [articledata, setArticleData] = useState([]);
  console.log("articledata", articledata);
  const [deleteModal, setDeleteModal] = useState(false);
  const [add, setAdd] = useState(false);
  const [artId, setArticleId] = useState();
  const [isEdit, setIsEdit] = useState(false);
  console.log(isEdit);
  const [view, setView] = useState(false);
  const handleViewShow = () => setView(true);
  const [article, setArticle] = useState({
    articleId: "",
    adminId: AdminID,
    title: "",
    text: "",
    image: "",
    channel: "",
    notificationName: "",
    imageurl: "",
    id: "",
  });
  console.log(article);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  //   const [editorState, setEditorState] = useState(() =>
  //   EditorState.createWithContent(
  //     ContentState.createFromBlockArray(
  //       convertFromHTML(article.description)
  //     )
  //   )
  // );

  const [loading, setLoading] = useState(false);
  const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
  const value = blocks
    .map((block) => (!block.text.trim() && "") || block.text)
    .join("");

  const handleAddClose = () => {
    setArticle({
      articleId: "",
      adminId: "",
      title: "",
      text: "",
      image: "",
      imageurl: "",
      channel: "",
      notificationName: "",
      id: "",
    });
    setAdd(false);
  };
  const handleAddShow = () => {
    setIsEdit(true);
    setAdd(true);
  };

  const filterId = articledata.filter((art) => art._id == article.id);

  const UpdateArticleApi = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      let fromdata = new FormData();

      fromdata.append("image", article.image);
      fromdata.append("title", article.title);
      fromdata.append("articleId", article.articleId);
      fromdata.append("description", article.description);
      fromdata.append("adminId", AdminID);
      multipartPostCallWithErrorResponse(
        BASE_URL + "/articles/addUpdate-articles",
        fromdata
      )
        .then((res) => {
          if (res.error) {
            ToastMsg("error", res.message);
          } else if (res.success === true) {
            ToastMsg("success", res.message);
          }
          getArticalApi();
          handleAddClose();
        })
        .catch((err) => {});
    }
    setValidated(true);
  };

  useEffect(() => {
    getArticalApi();
  }, []);

  // ADD articleDelte API
  const adminDeleteApi = () => {
    DeleteCallWithErrorResponse(
      BASE_URL + apiConfig.ARTICLE_DELTE,
      JSON.stringify({ articleId: artId })
    ).then((response) => {
      console.log(response);
      if (response.error) {
        ToastMsg("error", response.json.message);
      } else if (response.response.ok == true) {
        ToastMsg("success", response.json.message);
        handleAddClose();
        getArticalApi();
      }
    });
  };

  const getArticalApi = () => {
    // setLoading(true);
    simpleGetCallWithErrorResponse(`${BASE_URL}/pushNotification/all`)
      .then((response) => {
        console.log("pushNotification/all", response);
        if (response.json.data) setArticleData(response.json?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addNotificationImageApi = (event) => {
    event.preventDefault();
    console.log("file", article.image);

    if (article.image) {
      // If an image is selected, upload it using multipartPostCallWithErrorResponse
      const formData = new FormData();
      formData.append("file", article.image);

      multipartPostCallWithErrorResponse(
        `${BASE_URL}/utils/image-upload`,
        formData
      )
        .then((res) => {
          console.log("addNotificationImageApi response", res);
          if (res.error) {
            ToastMsg("error", res.message);
          } else if (res.error === false) {
            ToastMsg("success", res.message);
            console.log("image url", res.json.data.file_url);
            const imageurl = res.json.data.file_url;
            getEditArticaleApi(imageurl);
          }
        })
        .catch((error) => console.error("Error:", error));
    } else {
      // If no image is selected, directly call PostCallWithErrorResponse
      getEditArticaleApi("");
    }
  };

  const getEditArticaleApi = (imageurl) => {
    console.log("aaddimagge", article);
    // event.preventDefault();
    PostCallWithErrorResponse(
      `${BASE_URL}/pushNotification/create`,
      JSON.stringify({
        title: article.title,
        notificationName: article.notificationName,
        channel: article.channel,
        text: article.text,
        imageurl: imageurl,
      })
    )
      .then((res) => {
        if (res.error) {
          ToastMsg("error", res.json.message);
        } else if (res.success === true) {
          ToastMsg("success", res.json.message);
        }
        getArticalApi();

        handleAddClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    setArticle({
      ...article,
      image: file,
    });
  };

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Push Notifications</label>
        </div>

        <div className="add-btn">
          <button
            onClick={handleAddShow}

            // disabled={ userData && userData.UserCreateArt === "true" ? true : false}
          >
            Add Push Notification
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path fill="white" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
            </svg>
          </button>
        </div>
      </div>

      {/* ======================= Add Push Notifications ========================== */}
      <Modal
        show={add}
        onHide={handleAddClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {article.articleId
              ? "Update Push Notifications"
              : "Add Push Notifications"}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={addNotificationImageApi}
          >
            <div className="row">
              <div className="col-md-12 mb-3">
                <Form.Label>Upload Push Notification Image</Form.Label>

                <Form.Control type="file" onChange={handleImage} />

                <Form.Control.Feedback type="invalid">
                  Please Upload Push Notification Image
                </Form.Control.Feedback>
              </div>
              <div className="col-md-12 mb-3">
                <Form.Label>Push Notification Title</Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={article.title}
                  onChange={(e) => {
                    setArticle({
                      ...article,
                      title: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Push Notification Title
                </Form.Control.Feedback>
              </div>
              <div className="col-md-12 mb-3">
                <Form.Label>Push Notification Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={article.notificationName}
                  onChange={(e) => {
                    setArticle({
                      ...article,
                      notificationName: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Push Notification Name
                </Form.Control.Feedback>
              </div>
              <div className="col-md-12 mb-3">
                <Form.Label>Push Notification Text</Form.Label>

                <DefaultEditor
                  value={article.text}
                  onChange={(e) => {
                    setArticle({
                      ...article,
                      text: e.target.value,
                    });
                  }}
                  placeholder="Type text here"
                />
              </div>
              <div className="col-md-12 mb-3">
                <Form.Label>Push Notification Channel</Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={article.channel}
                  onChange={(e) => {
                    setArticle({
                      ...article,
                      channel: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Push Notification Channel
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="buttons-modal border-top pt-3 text-end ">
              <Link to="#" className=" bg-danger" onClick={handleAddClose}>
                Cancel
              </Link>
              <button className="ms-2" type="submit">
                Save
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <div className="documents-wrapper">
        {loading ? (
          <div style={{ marginTop: "150px" }}>
            <Loader />
          </div>
        ) : (
          <div className="row g-3 inner-wrapper article-wrapper">
            {articledata &&
              articledata.map((arti) => {
                return (
                  <div className="col-lg-3" key={arti._id}>
                    <div
                      style={{ cursor: "pointer" }}
                      className="document-cards article-card "
                    >
                      <div className="row ">
                        <div className="col text-end justify-content-end mb-2">
                          {userDataLoc === "true" && (
                            <>
                              <Link
                                to="#"
                                className="icon edit text-end ms-2"
                                onClick={() => {
                                  setDeleteModal(true);
                                  setArticleId(arti._id);
                                }}
                              >
                                <img
                                  src={redDelete}
                                  className="text-end"
                                  alt=""
                                />
                              </Link>
                            </>
                          )}
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          handleViewShow();

                          setArticle({
                            ...article,
                            id: arti._id,
                          });
                        }}
                      >
                        <div className="img">
                          <img
                            src={!arti.imageurl ? Article_Edit : arti.imageurl}
                            alt="push_notification_image"
                          />
                        </div>
                        <div className="profile-suggest-card-wrapper">
                          <label htmlFor="" className="name">
                            Title : {arti.title}
                          </label>

                          <div>
                            <label htmlFor="" className="name">
                              Name : {arti.notificationName}
                            </label>
                          </div>

                          <div>
                            <label htmlFor="" className="name">
                              Text : {arti.text}
                            </label>
                          </div>

                          <div>
                            <label htmlFor="" className="name">
                              Channel : {arti.channel}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </main>
  );
};

export default PushNotifications;
